import { AssassinsPlayer, AssassinsState } from '../State/Slices/assassinsSlice';

export enum SIO {
  Connect = 'Connect',
  Connected = 'Connected',
  Disconnect = 'Disconnect',
  CreateGame = 'CreateGame',
  CreateTutorial = 'CreateTutorial',
  GameCreated = 'GameCreated',
  TutorialCreated = 'TutorialCreated',
  JoinGame = 'JoinGame',
  GameJoined = 'GameJoined',
  GameJoinFailed = 'GameJoinFailed',
  NewPlayerJoined = 'NewPlayerJoined',
  StartGame = 'StartGame',
  SyncState = 'SyncState',
  SyncPlayer = 'SyncPlayer',
  PlayerDisconnected = 'PlayerDisconnected',
  FetchRoom = 'FetchRoom',
  FetchRoomResult = 'FetchRoomResult',
  Ping = 'Ping',
  Kick = 'Kick',
  Kicked = 'Kicked',
  RegisterPlayer = 'RegisterPlayer',
  IsRoomJoinableQuery = 'IsRoomJoinableQuery',
  IsRoomJoinableResponse = 'IsRoomJoinableResponse',
}

export interface GameCreatedEvent {
  roomID: string;
}

export const NewGameCreatedEvent = (roomId: string): GameCreatedEvent => {
  return { roomID: roomId };
};

export interface JoinGameEvent {
  roomId: string;
  playerName: string;
  playerId: string;
}

export const NewJoinGameEvent = (roomId: string, playerName: string, playerId: string): JoinGameEvent => {
  return {
    roomId: roomId,
    playerName: playerName,
    playerId: playerId,
  };
};

export interface NewPlayerJoinedEvent {
  roomId: string;
  playerId: string;
  playerName: string;
}

export const NewNewPlayerJoinedEvent = (roomId: string, playerId: string, playerName: string): NewPlayerJoinedEvent => {
  return {
    roomId: roomId,
    playerId: playerId,
    playerName: playerName,
  };
};

export interface GameJoinedEvent {
  roomID: string;
}

export const NewGameJoinedEvent = (roomId: string): GameJoinedEvent => {
  return { roomID: roomId };
};

export interface StartGameEvent {
  roomId: string;
}

export const NewStartGameEvent = (roomId: string): StartGameEvent => {
  return { roomId: roomId };
};

export interface SyncStateEvent {
  roomId: string;
  state: AssassinsState;
  source: string;
}

export interface SyncPlayerEvent {
  roomId: string;
  player: AssassinsPlayer;
}

export type PlayerDisconnectedEvent = {
  playerId: string;
};

export type DisconnectEvent = {
  playerId: string;
};

export type FetchRoomEvent = {
  roomId: string;
};

export type FetchRoomResultEvent = {
  result: boolean;
};

export type PingEvent = {
  roomId: string;
  playerId: string;
  timestamp: number;
};

export type KickEvent = {
  roomId: string;
  playerId: string;
};

export type RegisterPlayerEvent = {
  playerId: string;
};

export type IsRoomJoinableQueryEvent = {
  joinerSocketId: string;
  joinerPlayerId: string;
  joinerPlayerName: string;
  roomId: string;
};

export type IsRoomJoinableResponseEvent = {
  queryEvt: IsRoomJoinableQueryEvent;
  response: boolean;
  message: string;
};
