import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ComplexIcon.scss';

export type ComplexIcon = {
  icon: IconDefinition;
  primary?: string;
  background?: string;
};

export type ComplexIconLayer = {
  complexicons: (ComplexIcon | null)[];
  zIndex: number;
};

interface Props {
  layers: ComplexIconLayer[];
}

const ComplexIconView = (props: Props): JSX.Element => {
  const RenderCell = (ci: ComplexIcon | null, i: number) => {
    const style: React.CSSProperties = {};
    if (ci !== null) {
      if (ci.primary !== undefined) {
        style.color = ci.primary;
      }
      if (ci.background !== undefined) {
        style.backgroundColor = ci.background;
      }
    }
    return (
      <div className="cell" key={i} style={style}>
        {ci === null ? '' : <FontAwesomeIcon icon={ci.icon} />}
      </div>
    );
  };

  const RenderSolo = (layer: ComplexIconLayer, i: number) => {
    return (
      <div className="solo layer" style={{ zIndex: `${layer.zIndex}` }} key={i}>
        {RenderCell(layer.complexicons[0]!, 0)}
      </div>
    );
  };

  const Render4x4 = (layer: ComplexIconLayer, i: number) => {
    const complexicons = layer.complexicons;
    while (complexicons.length < 4) {
      complexicons.push(null);
    }
    const row1 = complexicons.slice(0, 2);
    const row2 = complexicons.slice(2, undefined);
    const rows = [row1, row2];
    return (
      <div className="four-by-four layer" style={{ zIndex: `${layer.zIndex}` }} key={i}>
        {rows.map((row, i) => {
          return (
            <div className="row" key={i}>
              {row.map((ci, j) => {
                return RenderCell(ci, j);
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const Render9x9 = (layer: ComplexIconLayer, i: number) => {
    const complexicons = layer.complexicons;
    while (complexicons.length < 9) {
      complexicons.push(null);
    }
    const row1 = complexicons.slice(0, 3);
    const row2 = complexicons.slice(3, 6);
    const row3 = complexicons.slice(6, undefined);
    const rows = [row1, row2, row3];
    return (
      <div className="nine-by-nine layer" style={{ zIndex: `${layer.zIndex}` }} key={i}>
        {rows.map((row, i) => {
          return (
            <div className="row" key={i}>
              {row.map((ci, j) => {
                return RenderCell(ci, j);
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="complex-icon">
      {props.layers.map((layer: ComplexIconLayer, i) => {
        if (layer.complexicons.length === 1) {
          return RenderSolo(layer, i);
        } else if (layer.complexicons.length < 9) {
          return Render4x4(layer, i);
        } else {
          return Render9x9(layer, i);
        }
      })}
    </div>
  );
};

export default ComplexIconView;
