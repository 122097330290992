import cloneDeep from 'lodash.clonedeep';
import { v4 as uuid } from 'uuid';
import { PlayCardAudio, PlayFanAudio, PlayOpenAudio } from '../audio';
import { ArmourAction, AssassinationCheckResult, Card, PlayAction, RevealAction, SideEffect, UserData } from '../types';
import {
  CreateStandardDeck,
  DrawRandomCardFromDeck,
  DrawTopCardFromDeck,
  GetCardString,
  SplitDeckByCardType,
} from '../utility';
import { AppState } from './Slices/appSlice';
import { AssassinsPlayer, AssassinsState } from './Slices/assassinsSlice';
import { TutorialDeck } from './Slices/tutorialSlice';
import { RootState } from './rootReducer';

export const EliminatePlayer = (state: AssassinsState, player: AssassinsPlayer) => {
  player.alive = false;
};

export const GetPlayerByID = (state: AssassinsState, id: string): AssassinsPlayer | null => {
  for (const player of state.players) {
    if (player.id === id) {
      return player;
    }
  }
  return null;
};

export const GetThreatVal = (state: AssassinsState): number => {
  let threat = 0;
  for (const player of state.players) {
    if (!player.alive) continue;
    for (const card of player.played) {
      if (!card.flipped) {
        threat += card.rank;
      }
    }
  }
  return threat;
};

export const PlayerHasArmedCards = (state: AssassinsState, playerId: string): boolean => {
  const player = GetPlayerByID(state, playerId);
  if (player === null) return false;
  for (const card of player.played) {
    if (card.flipped) return true;
  }
  return false;
};

export const GetTargetVal = (state: AssassinsState): number => {
  let target = 0;
  for (const card of state.hp) {
    target += card.rank;
  }
  for (const card of state.protection) {
    target += card.rank;
  }
  return target;
};

export const GetCanAct = (
  gameState: AssassinsState,
  appState: AppState,
  playerId: string,
  isHost: boolean,
): boolean => {
  return gameState.activePlayer === null
    ? isHost && !appState.actionLockout
    : gameState.activePlayer.id === playerId && !appState.actionLockout;
};

export const PlayerOwnsCard = (state: AssassinsState, playerId: string, card: Card): boolean => {
  const player = GetPlayerByID(state, playerId);
  const owner = GetPlayerWithCard(state, card);
  if (owner === undefined || player === null) return false;
  return owner.id === player.id;
};

export const GetPlayerWithCard = (state: AssassinsState, card: Card): AssassinsPlayer | undefined => {
  for (const player of state.players) {
    for (const playerCard of player.played) {
      if (playerCard.id === card.id) {
        return player;
      }
    }
    for (const playerCard of player.hand) {
      if (playerCard.id === card.id) {
        return player;
      }
    }
  }
};

export const GetOpponents = (state: AssassinsState, playerId: string): AssassinsPlayer[] => {
  const opps = [];
  for (const player of state.players) {
    if (player.id !== playerId) {
      opps.push(player);
    }
  }
  return opps;
};

export const PlayCard = (state: AssassinsState, action: PlayAction) => {
  const player = state.players.find((player) => player.id === action.playerId);
  if (player === undefined) {
    console.error('PlayCard: Player not found');
    return;
  }
  const card = player.hand.find((card) => card.id === action.card.id);
  if (card === undefined) {
    console.error('PlayCard: Card not found');
    return;
  }
  card.flipped = true;
  player.hand = player.hand.filter((card) => card.id !== action.card.id);
  const new_played = [];
  // I think this ordering here is unnecessary given the View re-orders anyhow
  let synthetic_index = 0;
  let new_card_added = false;
  for (let i = 0; i < player.played.length; i++) {
    if (synthetic_index >= action.index && !new_card_added) {
      new_played.push(card);
      new_card_added = true;
    }
    new_played.push(player.played[i]);
    synthetic_index += 2;
  }
  if (!new_card_added) {
    new_played.push(card);
  }
  player.played = new_played;
  AddLogItem(state, `${player.name} armed a card.`);
  player.actions.push({
    text: `ARMED`,
    id: uuid(),
  });
  PlayCardAudio();
};

export const RevealCard = (state: AssassinsState, action: RevealAction): AssassinationCheckResult | undefined => {
  console.log('Revealing Card');
  const player = state.players.find((player) => player.id === action.playerId);
  if (player === undefined) {
    console.error('RevealCard: Player not found');
    return;
  }
  const card = player.played.find((card) => card.id === action.card.id);
  if (card === undefined) {
    console.error('RevealCard: Card not found');
    return;
  }
  card.flipped = false;
  AddLogItem(state, `${player.name} revealed ${GetCardString(card)}.`);
  PlayCardAudio();
  return CheckAssassination(state, player);
};

export const ArmourCard = (state: AssassinsState, action: ArmourAction) => {
  const player = state.players.find((player) => player.id === action.playerId);
  if (player === undefined) {
    console.error('ArmourCard: Player not found');
    return;
  }
  const card = player.hand.find((card) => card.id === action.card.id);
  if (card === undefined) {
    console.error('ArmourCard: Card not found');
    return;
  }
  AddLogItem(state, `${player.name} fortified the mark with ${GetCardString(card)}`);
  player.actions.push({
    text: `ARMOURED`,
    id: uuid(),
  });
  PlayCardAudio();
  state.protection.push(card);
  player.hand = player.hand.filter((c) => c.id !== card.id);
  CheckAssassination(state, player);
};

export const ReloadPlayer = (state: AssassinsState, playerId: string) => {
  const player = state.players.find((player) => player.id === playerId);
  if (player === undefined) {
    console.error('Reload: Player not found');
    return;
  }
  let i = 0;
  while (i < 3 && state.numDeck.length > 0) {
    player.hand.push(DrawTopCardFromDeck(state.numDeck));
    i++;
  }
  AddLogItem(state, `${player.name} fully reloaded their cache.`);
  PlayOpenAudio();
  player.actions.push({
    text: `RELOADED`,
    id: uuid(),
  });
};

export const ReloadPlayerOnce = (state: AssassinsState, playerId: string) => {
  const player = state.players.find((player) => player.id === playerId);
  if (player === undefined) {
    console.error('Reload: Player not found');
    return;
  }
  player.hand.push(DrawTopCardFromDeck(state.numDeck));
  AddLogItem(state, `${player.name} reloaded.`);
  PlayOpenAudio();
  player.actions.push({
    text: `RELOADED`,
    id: uuid(),
  });
};

export const HandleTurnEnd = (state: AssassinsState) => {
  const survivor = CheckForLoneSurvivor(state);
  if (survivor !== null) {
    survivor.won = true;
    survivor.winCount++;
    state.winMessage = 'Survivor';
  }
  for (const player of state.players) {
    if (player.won) {
      state.roundEndFlag = true;
      // Check if the winner has won enough rounds to win the game
      if (player.winCount >= state.roundsToWin) {
        state.gameEndFlag = true;
      }
    }
  }
  // Set the next active player
  const prevPlayer = state.activePlayer as AssassinsPlayer;
  let nextPlayer = null;
  let i = 1;
  while (nextPlayer === null && i <= state.players.length) {
    let nextOrdinal = (prevPlayer.ordinal + i) % state.players.length;
    let potentialPlayer = state.players.find((player) => player.ordinal === nextOrdinal);
    if (potentialPlayer !== undefined && potentialPlayer.alive) {
      nextPlayer = potentialPlayer;
    }
    i++;
  }
  state.activePlayer = nextPlayer;
  state.turn++;
  state.updateFlag = true;
  state.currSideEffects = state.currSideEffects.filter((effect) => effect !== SideEffect.ExposeUnder);
  // Maybe should remove *all* side effects? ^
};

export const CheckForLoneSurvivor = (state: AssassinsState): AssassinsPlayer | null => {
  let out: AssassinsPlayer | null | undefined = undefined;
  for (const player of state.players) {
    if (player.alive) {
      if (out === undefined) {
        out = player;
      } else {
        out = null;
      }
    }
  }
  if (out === undefined) out = null;
  return out;
};

export const CheckAssassination = (state: AssassinsState, player: AssassinsPlayer): AssassinationCheckResult => {
  const threat = GetThreatVal(state);
  const target = GetTargetVal(state);
  let result: AssassinationCheckResult = AssassinationCheckResult.Under;
  if (threat === target) {
    result = AssassinationCheckResult.Equal;
  } else if (target >= state.protectionBound) {
    result = AssassinationCheckResult.Protect;
  } else if (threat > target) {
    result = AssassinationCheckResult.Over;
  }
  switch (result) {
    case AssassinationCheckResult.Equal:
      player.won = true;
      player.winCount++;
      state.winMessage = 'Assassin';
      break;
    case AssassinationCheckResult.Over:
      player.alive = false;
      break;
    case AssassinationCheckResult.Under:
      break;
    case AssassinationCheckResult.Protect:
      player.won = true;
      player.winCount++;
      state.winMessage = 'Protector';
      break;
  }
  return result;
};

export const GetGameWinner = (state: AssassinsState): AssassinsPlayer | null => {
  for (const player of state.players) {
    if (player.winCount >= state.roundsToWin) {
      return player;
    }
  }
  return null;
};

export const RestartGame = (state: AssassinsState) => {
  state.gameActive = true;
  const deck = CreateStandardDeck();
  const { faceCards, numberCards } = SplitDeckByCardType(deck);
  state.faceDeck = faceCards;
  state.numDeck = numberCards;
  state.mark = DrawRandomCardFromDeck(state.faceDeck);
  state.hp = [DrawRandomCardFromDeck(state.numDeck), DrawRandomCardFromDeck(state.numDeck)];
  state.protection = [];
  for (const player of state.players) {
    player.hand = [
      DrawRandomCardFromDeck(state.numDeck),
      DrawRandomCardFromDeck(state.numDeck),
      DrawRandomCardFromDeck(state.numDeck),
    ];
    if (player.ordinal === 0) {
      state.activePlayer = player;
    }
    player.alive = true;
    player.played = [];
    player.winCount = 0;
    player.won = false;
    player.actions = [];
    player.canPurloin = true;
  }
  state.round = 0;
  state.turn = 0;
  state.playersCanJoin = false;
  state.roundEndFlag = false;
  state.gameEndFlag = false;
  state.updateFlag = true;
  AddLogItem(state, 'Game started');
  PlayFanAudio();
};

export const StartTutorial = (state: AssassinsState) => {
  state.gameActive = true;
  const deck = cloneDeep(TutorialDeck);
  state.faceDeck = [DrawTopCardFromDeck(deck)];
  state.numDeck = deck;
  state.mark = DrawTopCardFromDeck(state.faceDeck);
  state.hp = [DrawTopCardFromDeck(state.numDeck), DrawTopCardFromDeck(state.numDeck)];
  state.protection = [];
  state.players.sort((a, b) => a.ordinal - b.ordinal);
  for (const player of state.players) {
    player.hand = [
      DrawTopCardFromDeck(state.numDeck),
      DrawTopCardFromDeck(state.numDeck),
      DrawTopCardFromDeck(state.numDeck),
    ];
    if (player.ordinal === 0) {
      state.activePlayer = player;
    }
    player.alive = true;
    player.played = [];
    player.winCount = 0;
    player.won = false;
    player.actions = [];
  }
  state.round = 0;
  state.turn = 0;
  state.playersCanJoin = false;
  state.roundEndFlag = false;
  state.gameEndFlag = false;
  // state.updateFlag = true;
  AddLogItem(state, 'Tutorial started');
};

export const GetHost = (state: AssassinsState): AssassinsPlayer | undefined => {
  for (const player of state.players) {
    if (player.isHost) {
      return player;
    }
  }
};

export const GetUserData = (state: RootState): UserData[] => {
  const partialData: Partial<UserData>[] = [];
  for (const conn of state.app.connections) {
    let datum: Partial<UserData> = {
      connection: conn,
    };
    for (const player of state.assassins.players) {
      if (player.id === conn.id) {
        datum.player = player;
      }
    }
    partialData.push(datum);
  }
  const userData: UserData[] = [];
  for (const partial of partialData) {
    if (partial.hasOwnProperty('connection') && partial.hasOwnProperty('player')) {
      userData.push(partial as UserData);
    }
  }
  return userData;
};

export const AddLogItem = (state: AssassinsState, content: string) => {
  state.gameLog.push({
    id: uuid(),
    content: content,
    timestamp: Date.now(),
    source: 'Game',
  });
};
