import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPreventComputerMoves } from '../State/Slices/appSlice';
import { computerMove, tutorialComputerMove } from '../State/Slices/assassinsSlice';
import { incrementTutorial } from '../State/Slices/tutorialSlice';
import { useAppSelector } from '../State/hooks';
import { RootState } from '../State/rootReducer';
import { ComputerLevel, ComputerPlayer } from '../types';

export const ComputerController = (): JSX.Element => {
  const dispatch = useDispatch();
  const isHost = useSelector((state: RootState) => state.app.isOwner);
  const activePlayer = useSelector((state: RootState) => state.assassins.activePlayer);
  const turn = useSelector((state: RootState) => state.assassins.turn);
  const roundOver = useSelector((state: RootState) => state.assassins.roundEndFlag);
  const gameOver = useSelector((state: RootState) => state.assassins.gameEndFlag);
  const preventComputerMoves = useAppSelector((state) => state.app.preventComputerMoves);
  const tutorialStep = useAppSelector((state) => state.tutorial.tutorialStep);

  const prevComputerTurn = useRef(0);

  const PC_TURN_DELAY = 2500;
  // const PC_TURN_DELAY = 500;

  // Computer Players only run on host
  if (!isHost || preventComputerMoves) {
    return <div id="computer-controller" className="nodisplay"></div>;
  }

  if (!roundOver && !gameOver && activePlayer !== null && !activePlayer.sentient && turn !== prevComputerTurn.current) {
    prevComputerTurn.current = turn;
    setTimeout(() => {
      const cpu = activePlayer as ComputerPlayer;
      if (cpu.level === ComputerLevel.Tutorial) {
        dispatch(setPreventComputerMoves(true));
        dispatch(incrementTutorial());
        dispatch(tutorialComputerMove(tutorialStep));
      } else {
        dispatch(computerMove());
      }
    }, PC_TURN_DELAY);
  }

  return <div id="computer-controller" className="nodisplay"></div>;
};
