import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PlayFanAudio } from '../../audio';
import {
  ActionText,
  ArmourAction,
  AssassinationCheckResult,
  Card,
  LogItem,
  PlayAction,
  RevealAction,
  SideEffect,
} from '../../types';
import { CreateStandardDeck, DrawRandomCardFromDeck, SplitDeckByCardType } from '../../utility';
import { StandardComputerBehaviour, TutorialComputerBehaviour } from '../computerBehaviour';
import {
  AddLogItem,
  ArmourCard,
  GetPlayerWithCard,
  HandleTurnEnd,
  PlayCard,
  ReloadPlayer,
  ReloadPlayerOnce,
  RestartGame,
  RevealCard,
  StartTutorial,
} from '../stateHelpers';

export interface AssassinsPlayer {
  id: string;
  name: string;
  played: Card[];
  hand: Card[];
  canPurloin: boolean;
  ordinal: number;
  alive: boolean;
  won: boolean;
  winCount: number;
  isHost: boolean;
  sentient: boolean;
  actions: ActionText[];
}

export interface AssassinsState {
  gameActive: boolean;
  faceDeck: Card[];
  numDeck: Card[];
  protection: Card[];
  hp: Card[];
  players: AssassinsPlayer[];
  maxPlayers: number;
  mark: Card | null;
  turn: number;
  round: number;
  activePlayer: AssassinsPlayer | null;
  playedLimit: number;
  roundsToWin: number;
  updateFlag: boolean;
  roundEndFlag: boolean;
  gameEndFlag: boolean;
  winMessage: string;
  playersCanJoin: boolean;
  currSideEffects: SideEffect[];
  gameLog: LogItem[];
  protectionBound: number;
}

const initialState: AssassinsState = {
  gameActive: false,
  faceDeck: [],
  numDeck: [],
  protection: [],
  players: [],
  maxPlayers: 8,
  mark: null,
  hp: [],
  turn: 0,
  round: 0,
  activePlayer: null,
  playedLimit: 5,
  roundsToWin: 3,
  updateFlag: false,
  roundEndFlag: false,
  gameEndFlag: false,
  winMessage: '',
  playersCanJoin: false,
  currSideEffects: [],
  gameLog: [],
  protectionBound: 40,
};

const assassinsSlice = createSlice({
  name: 'assassins',
  initialState: initialState,
  reducers: {
    resetAssassinsState: (state: AssassinsState) => {
      return initialState;
    },
    setInitialDealer: (state: AssassinsState, action: PayloadAction<string>) => {
      let i = 1;
      for (const player of state.players) {
        if (player.id === action.payload) {
          player.ordinal = 0;
        } else {
          player.ordinal = i;
          i++;
        }
      }
      state.updateFlag = true;
    },
    updateGameState: (state: AssassinsState, action: PayloadAction<AssassinsState>) => {
      action.payload.updateFlag = false;
      return action.payload;
    },
    addNewPlayer: (state: AssassinsState, action: PayloadAction<AssassinsPlayer>) => {
      state.players.push(action.payload);
      state.updateFlag = true;
      AddLogItem(state, 'New player added: ' + action.payload.name);
    },
    addNewPlayerTutorial: (state: AssassinsState, action: PayloadAction<AssassinsPlayer>) => {
      state.players.push(action.payload);
      AddLogItem(state, 'New player added: ' + action.payload.name);
    },
    removePlayerByID: (state: AssassinsState, action: PayloadAction<string>) => {
      state.players = state.players.filter((player) => player.id !== action.payload);
      state.updateFlag = true;
    },
    resetUpdateFlag: (state: AssassinsState) => {
      state.updateFlag = false;
    },
    startGame: (state: AssassinsState) => {
      RestartGame(state);
    },
    startTutorial: (state: AssassinsState) => {
      StartTutorial(state);
    },
    playCard: (state: AssassinsState, action: PayloadAction<PlayAction>) => {
      PlayCard(state, action.payload);
      HandleTurnEnd(state);
    },
    revealCard: (state: AssassinsState, action: PayloadAction<RevealAction>) => {
      const player = state.players.find((player) => player.id === action.payload.playerId);
      if (player === undefined) {
        console.error('RevealCard: Player not found');
        return;
      }
      player.actions.push({
        text: `REVEALED`,
        id: uuid(),
      });
      RevealCard(state, action.payload);
      HandleTurnEnd(state);
    },
    armourCard: (state: AssassinsState, action: PayloadAction<ArmourAction>) => {
      ArmourCard(state, action.payload);
      HandleTurnEnd(state);
    },
    exposeCard: (state: AssassinsState, action: PayloadAction<RevealAction>) => {
      const sourcePlayer = GetPlayerWithCard(state, action.payload.card);
      if (sourcePlayer == null) {
        console.error('Source player not found');
        return;
      }
      if (state.activePlayer == null) {
        console.error('No active player');
        return;
      }
      AddLogItem(state, `${state.activePlayer.name} exposed ${sourcePlayer.name}.`);

      const exposeResult = RevealCard(state, action.payload);
      if (exposeResult === AssassinationCheckResult.Under) {
        // If exposing causes < target, active player must reveal an armed card
        sourcePlayer.actions.push({
          text: 'RICOCHETED',
          id: uuid(),
        });
        state.currSideEffects.push(SideEffect.ExposeUnder);
        state.updateFlag = true;
      } else {
        sourcePlayer.actions.push({
          text: 'EXPOSED',
          id: uuid(),
        });
        HandleTurnEnd(state);
      }
    },
    purloinCard: (state: AssassinsState, action: PayloadAction<RevealAction>) => {
      const sourcePlayer = state.players.find((player) =>
        player.played.some((card) => card.id === action.payload.card.id),
      );
      if (sourcePlayer === undefined) {
        console.error('Purloin: Source player not found.');
        return;
      }
      const card = sourcePlayer.played.find((card) => card.id === action.payload.card.id);
      if (card === undefined) {
        console.error('Purloin: Card not found');
        return;
      }
      const targetPlayer = state.players.find((player) => player.id === action.payload.playerId);
      if (targetPlayer === undefined) {
        console.error('Purloin: Target player not found.');
        return;
      }
      sourcePlayer.played = sourcePlayer.played.filter((card) => card.id !== action.payload.card.id);
      targetPlayer.played.push(card);
      AddLogItem(state, `${targetPlayer.name} purloined from ${sourcePlayer.name}.`);
      sourcePlayer.actions.push({
        text: 'PURLOINED',
        id: uuid(),
      });
      targetPlayer.canPurloin = false;

      RevealCard(state, { card: card, playerId: targetPlayer.id });
      HandleTurnEnd(state);
    },
    reload: (state: AssassinsState, action: PayloadAction<string>) => {
      ReloadPlayer(state, action.payload);
      HandleTurnEnd(state);
    },
    reloadOnce: (state: AssassinsState, action: PayloadAction<string>) => {
      ReloadPlayerOnce(state, action.payload);
      HandleTurnEnd(state);
    },
    setPlayersCanJoin: (state: AssassinsState, action: PayloadAction<boolean>) => {
      state.playersCanJoin = action.payload;
    },
    startNewRound: (state: AssassinsState) => {
      state.round++;
      state.turn = 0;
      state.gameActive = true;
      const deck = CreateStandardDeck();
      const { faceCards, numberCards } = SplitDeckByCardType(deck);
      state.faceDeck = faceCards;
      state.numDeck = numberCards;
      state.mark = DrawRandomCardFromDeck(state.faceDeck);
      state.hp = [DrawRandomCardFromDeck(state.numDeck), DrawRandomCardFromDeck(state.numDeck)];
      state.protection = [];
      for (const player of state.players) {
        player.hand = [
          DrawRandomCardFromDeck(state.numDeck),
          DrawRandomCardFromDeck(state.numDeck),
          DrawRandomCardFromDeck(state.numDeck),
        ];
        player.played = [];
        player.alive = true;
        player.won = false;
        player.canPurloin = true;

        if (player.ordinal === state.round % state.players.length) {
          state.activePlayer = player;
        }
      }
      state.roundEndFlag = false;
      state.updateFlag = true;
      PlayFanAudio();
      AddLogItem(state, `Round ${state.round} started`);
    },
    playAgain: (state: AssassinsState) => {
      RestartGame(state);
    },
    goBackToLobby: (state: AssassinsState) => {
      state.gameActive = false;
      state.updateFlag = true;
    },
    updatePlayer: (state: AssassinsState, action: PayloadAction<AssassinsPlayer>) => {
      state.players = state.players.filter((player) => player.id !== action.payload.id);
      state.players.push(action.payload);
    },
    forceStateSync: (state: AssassinsState) => {
      state.updateFlag = true;
    },
    computerMove: (state: AssassinsState) => {
      StandardComputerBehaviour(state);
    },
    tutorialComputerMove: (state: AssassinsState, action: PayloadAction<number>) => {
      TutorialComputerBehaviour(state, action.payload);
    },
    pushLogContent: (state: AssassinsState, action: PayloadAction<LogItem>) => {
      state.gameLog.push(action.payload);
    },
  },
});

export default assassinsSlice.reducer;
export const {
  setInitialDealer,
  resetAssassinsState,
  updateGameState,
  addNewPlayer,
  resetUpdateFlag,
  startGame,
  playCard,
  revealCard,
  armourCard,
  exposeCard,
  purloinCard,
  reload,
  setPlayersCanJoin,
  startNewRound,
  playAgain,
  goBackToLobby,
  updatePlayer,
  forceStateSync,
  computerMove,
  removePlayerByID,
  pushLogContent,
  startTutorial,
  addNewPlayerTutorial,
  reloadOnce,
  tutorialComputerMove,
} = assassinsSlice.actions;
