import { faRightToBracket, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialogue } from '../../State/Slices/appSlice';
import { RootState } from '../../State/rootReducer';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, ZIndices } from '../../types';
import { wsJoinGame } from '../../websockets/actions';

interface Props {}

export const ReconnectDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const activeDialogue = useSelector((state: RootState) => state.app.activeDialogue);
  const previousRoomId = useSelector((state: RootState) => state.app.previousRoomId) as string;
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const playerName = useSelector((state: RootState) => state.app.playerName);

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;
  const inDelay = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (activeDialogue === Dialogue.Reconnect) {
      setTimeout(() => {
        setShown(true);
      }, inDelay);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
      }, fadeOutDuration);
    }
  }, [activeDialogue]);

  const closeDialogue = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
  };

  const reconnect = () => {
    PlaySubtleClick();
    dispatch(wsJoinGame(previousRoomId, playerName, playerId));
  };

  return (
    <div
      id="reconnect-dialogue"
      className={`dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${shown ? '' : 'nodisplay'}`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Dialogues}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="dialogue-section">
            It looks like you recently disconnected from an active game. Would you like to reconnect?
          </div>
          <div className="dialogue-section">
            <div className="dialogue-controls">
              <button className="popout-button rounded-square" onClick={closeDialogue}>
                <FontAwesomeIcon icon={faXmark} />
                <div>No</div>
              </button>
              <button className="popout-button rounded-square" onClick={reconnect}>
                <FontAwesomeIcon icon={faRightToBracket} />
                <div>Yes</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
