import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { pushAlert, setTooltipState } from '../../State/Slices/appSlice';
import { AssassinsPlayer, reload, reloadOnce } from '../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { GetCanAct, GetPlayerByID } from '../../State/stateHelpers';
import { AlertSeverity, Card, SideEffect } from '../../types';
import { CreateTooltip, DefaultMouseLeave, createAlert } from '../../utility';
import CardView from '../Card/CardView';

interface Props {}

const DeckView = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const deck = useSelector((state: RootState) => state.assassins.numDeck);
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const player = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, state.app.playerID);
  }) as AssassinsPlayer;
  const canAct = useSelector((state: RootState) =>
    GetCanAct(state.assassins, state.app, state.app.playerID, state.app.isOwner),
  );
  const sideEffects = useSelector((state: RootState) => state.assassins.currSideEffects);
  const mustReveal = canAct && sideEffects.includes(SideEffect.ExposeUnder);
  const reloadActionEnabled = useAppSelector((state) => state.app.reloadEnabled);

  const deckHover = (htmlid: string) => {
    dispatch(
      setTooltipState(
        CreateTooltip(
          `<p><b>Reload</b> (Draw a card, up to 4)</p>
          <div class="tt-separator"></div>
          <p>If you have 0 cards in your cache, draw 3.</p> 
          `,
          htmlid,
        ),
      ),
    );
  };

  const deckNameHover = (htmlid: string) => {
    dispatch(
      setTooltipState(CreateTooltip('<p>The <b>numeral deck</b> contains cards with ranks 1(A) to 10.</p>', htmlid)),
    );
  };

  const deckClick = () => {
    if (!canAct) {
      dispatch(pushAlert(createAlert('It is not your turn.', AlertSeverity.error)));
      return;
    }
    if (!reloadActionEnabled) {
      dispatch(pushAlert(createAlert('You cannot do that right now.', AlertSeverity.warning)));
      return;
    }
    if (mustReveal) {
      dispatch(pushAlert(createAlert('You must reveal an armed card.', AlertSeverity.info)));
      return;
    }
    if (player.hand.length === 0) {
      dispatch(reload(playerId));
    } else if (player.hand.length < 4) {
      dispatch(reloadOnce(playerId));
    } else {
      dispatch(pushAlert(createAlert('You already have the maximum cache size.', AlertSeverity.error)));
    }
  };

  return (
    <div className="deck" id="deck">
      <div
        className="zone-name"
        id="deck-name"
        onMouseEnter={() => deckNameHover('deck-name')}
        onMouseLeave={() => DefaultMouseLeave(dispatch)}
      >
        Deck
      </div>
      <div className="card-zone" style={{ '--ncards': `${deck.length}` } as React.CSSProperties}>
        {deck.map((card: Card, i) => {
          const htmlid = uuid();
          return (
            <div
              id={htmlid}
              key={i}
              className="card-location"
              onMouseEnter={() => deckHover(htmlid)}
              onMouseLeave={() => DefaultMouseLeave(dispatch)}
              onClick={deckClick}
            >
              <div className="card-container" key={i} style={{ fontSize: `0.25rem` }}>
                <CardView card={card} flipped={true} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeckView;
