import {
  IconDefinition,
  faBullseye,
  faCrosshairs,
  faStepBackward,
  faStepForward,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  setActivePage,
  setArmEnabled,
  setExposeEnabled,
  setFortifyEnabled,
  setGlitchIntensity,
  setPreventComputerMoves,
  setPurloinEnabled,
  setReloadEnabled,
  setRevealEnabled,
  setSelectEnabled,
  setTableTremble,
} from '../../State/Slices/appSlice';
import { AssassinsPlayer, resetAssassinsState } from '../../State/Slices/assassinsSlice';
import {
  decrementTutorial,
  incrementTutorial,
  setTutorialActive,
  setTutorialStep,
} from '../../State/Slices/tutorialSlice';
import { useAppDispatch, useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { GetPlayerByID } from '../../State/stateHelpers';
import { PlaySubtleClick } from '../../audio';
import { Card, Page, Rank, ZIndices } from '../../types';
import { SequentialText } from '../SequentialText/SequentialText';
import './Tutorial.scss';

enum LensMessageDirection {
  Right,
  Left,
  Above,
  Below,
}

export const TutorialMask = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const active = useAppSelector((state) => state.tutorial.tutorialActive);
  const currStep = useAppSelector((state) => state.tutorial.tutorialStep);
  const selectedCard = useAppSelector((state) => state.app.selectedCard);
  const player = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, state.app.playerID);
  }) as AssassinsPlayer;
  const cpu1 = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, 'tut-cpu-1');
  }) as AssassinsPlayer;
  const cpu2 = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, 'tut-cpu-2');
  }) as AssassinsPlayer;
  const cpu3 = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, 'tut-cpu-3');
  }) as AssassinsPlayer;
  const armourState = useAppSelector((state) => state.assassins.protection);
  const armourBound = useAppSelector((state) => state.assassins.protectionBound);

  let blockTablePointerEvents = true;

  let showMask = false;
  let showLens = false;
  let showSeekers = false;
  let hidePrevSeek = false;
  let hideNextSeek = false;

  let padding = 20;
  let hl_lens_border = 8;
  let lens_target_rect = null;

  let hl_lens_width = 0;
  let hl_lens_height = 0;
  let hl_lens_left = 0;
  let hl_lens_top = 0;
  let lens_opacity = 1;

  let hl_left_width = 0;
  let hl_right_width = 0;
  let hl_top_height = 0;
  let hl_bot_height = 0;

  let lens_message_left = 0;
  let lens_message_top = 0;
  let lens_msg_direction = LensMessageDirection.Right;

  let redden = false;

  let messageRenderer = (): JSX.Element => {
    return <div className="nodisplay"></div>;
  };

  let lensMessageRenderer = (): JSX.Element => {
    return <div className="nodisplay"></div>;
  };

  switch (currStep) {
    case 0:
      showMask = true;
      showSeekers = true;
      hidePrevSeek = true;
      messageRenderer = () => {
        return (
          <div className="message" key={currStep}>
            <b>Assassins</b> is a competitive bluffing card game for 2-8 players, played with a standard 52-card deck.
          </div>
        );
      };
      break;
    case 1:
      showMask = true;
      showSeekers = true;
      messageRenderer = () => {
        return (
          <div className="message" key={currStep}>
            The goal of the game is to <b>assassinate the mark</b>, by causing the{' '}
            <FontAwesomeIcon icon={faCrosshairs} />
            <b> threat value </b>
            to equal the&nbsp;
            <FontAwesomeIcon icon={faBullseye} />
            <b> target value</b>.
          </div>
        );
      };
      break;
    case 2:
      showSeekers = true;
      showLens = true;
      let mark = document.getElementById('mark');
      if (mark === null) {
        console.error('No element');
      } else {
        lens_target_rect = mark.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `This is the mark. It represents the high-value target that all of the players are trying to assassinate. In advanced play, different marks can change the game in different ways, but for now only the SUIT of the mark matters. In this case, HEARTS.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 3:
      showSeekers = true;
      showLens = true;
      let hp = document.getElementById('hit-points');
      if (hp === null) {
        console.error('No element');
      } else {
        lens_target_rect = hp.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `These are the mark's hit points. The sum of their ranks is the initial TARGET VALUE. Their suit does not matter.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 4:
      showSeekers = true;
      showLens = true;
      lens_msg_direction = LensMessageDirection.Left;
      let gameVales = document.getElementById('game-values');
      if (gameVales === null) {
        console.error('No element');
      } else {
        lens_target_rect = gameVales.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `The TARGET VALUE is currently 16 (6 + 10). The THREAT VALUE is currently 0.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 5:
      showSeekers = true;
      showLens = true;
      let armour = document.getElementById('protection');
      if (armour === null) {
        console.error('No element');
      } else {
        lens_target_rect = armour.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Cards that share the mark's suit can be played as ARMOUR. Doing so FORTIFIES the target, adding the rank of the armour to the target value.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 6:
      showSeekers = true;
      showLens = true;
      let arsenal = document.getElementById('arsenal');
      if (arsenal === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `This is your ARSENAL. The threat value is the total of all of the FACE-UP cards in each player's arsenal.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 7:
      showSeekers = true;
      showLens = true;
      let arsenal2 = document.getElementById('arsenal');
      if (arsenal2 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal2.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Remember, you win by making the threat value EXACTLY EQUAL the target value.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 8:
      showSeekers = true;
      showLens = true;
      blockTablePointerEvents = false;
      hideNextSeek = true;
      lens_opacity = 0;
      let cache0 = document.getElementById('player-cache-0');
      if (cache0 === null) {
        console.error('No element');
      } else {
        lens_target_rect = cache0.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `The larger cards on the bottom of your screen are your CACHE. Click the eight of diamonds in your cache to select it.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setSelectEnabled(true));
      }, 10);
      // Listener code for the next step:
      if (selectedCard != null && selectedCard.rank === Rank.Eight) {
        setTimeout(() => {
          dispatch(setTutorialStep(9));
          dispatch(setPreventComputerMoves(true));
        }, 10);
      }
      break;
    case 9:
      showSeekers = true;
      showLens = true;
      blockTablePointerEvents = false;
      hideNextSeek = true;
      let arsenal3 = document.getElementById('arsenal');
      if (arsenal3 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal3.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Now click on the open space in your ARSENAL to ARM the eight of diamonds.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setSelectEnabled(false));
        dispatch(setArmEnabled(true));
      }, 10);
      // Listener for next step:
      if (player.played.length > 0) {
        setTimeout(() => {
          dispatch(setTutorialStep(10));
        }, 10);
      }
      break;
    case 10:
      showSeekers = true;
      showLens = true;
      let arsenal4 = document.getElementById('arsenal');
      if (arsenal4 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal4.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Arming a card is an ACTION. Once you perform an action, your turn is over.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => dispatch(setArmEnabled(false)), 10);
      break;
    case 11:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent0 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent0 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent0.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 12:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent1 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent1 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent1.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 13:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent2 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent2 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent2.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 14:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent3 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent3 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent3.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 15:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent4 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent4 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent4.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 16:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent5 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent5 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent5.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 17:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent6 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent6 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent6.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 18:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent7 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent7 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent7.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 19:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent8 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent8 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent8.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 20:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      blockTablePointerEvents = false;
      let arsenal5 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Left;
      if (arsenal5 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal5.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It is now your turn. Click on your armed card and REVEAL it.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setRevealEnabled(true));
        dispatch(setSelectEnabled(true));
      }, 1);
      // Listener for next step
      if (!player.played[0].flipped) {
        setTimeout(() => {
          dispatch(setTutorialStep(21));
        }, 10);
      }
      break;
    case 21:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let gameInfo = document.getElementById('game-values');
      lens_msg_direction = LensMessageDirection.Left;
      if (gameInfo === null) {
        console.error('No element');
      } else {
        lens_target_rect = gameInfo.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Because you revealed a card with a rank of 8, the threat value has increased to 8.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setRevealEnabled(false));
        dispatch(setSelectEnabled(false));
      }, 10);
      break;
    case 22:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent00 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent00 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent00.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 23:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent9 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent9 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent9.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 24:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent10 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent10 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent10.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur REVEALED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 25:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent11 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent11 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent11.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 26:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent12 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent12 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent12.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 27:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent13 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent13 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent13.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue REVEALED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 28:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent14 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent14 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent14.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 29:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent15 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent15 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent15.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 30:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent16 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent16 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent16.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher REVEALED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 31:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent17 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent17 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent17.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher DIED because they revealed a card that would have caused the threat value to EXCEED the target value. They are now out of the game.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 32:
      showSeekers = true;
      showLens = true;

      let gameVals = document.getElementById('game-values');
      if (gameVals === null) {
        console.error('No element');
      } else {
        lens_target_rect = gameVals.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It's your turn. The threat value is now dangerously close to the target value, so arming a high-rank card would be very risky.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };

      break;
    case 33:
      showSeekers = true;
      showLens = true;
      blockTablePointerEvents = false;
      hideNextSeek = true;
      lens_opacity = 0;
      let cache00 = document.getElementById('player-cache-0');
      if (cache00 === null) {
        console.error('No element');
      } else {
        lens_target_rect = cache00.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Click on the ace of spades in your cache to select it.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => dispatch(setSelectEnabled(true)), 10);
      // Listener code for the next step:
      if (selectedCard != null && selectedCard.rank === Rank.Ace) {
        setTimeout(() => {
          dispatch(setTutorialStep(34));
          dispatch(setPreventComputerMoves(true));
        }, 10);
      }
      break;
    case 34:
      showSeekers = true;
      showLens = true;
      blockTablePointerEvents = false;
      hideNextSeek = true;
      let arsenal6 = document.getElementById('arsenal');
      if (arsenal6 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal6.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Arm the ace of spades.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // prevent user from accidentally arming a different card
      setTimeout(() => {
        dispatch(setSelectEnabled(false));
        dispatch(setArmEnabled(true));
      });
      // Listener for next step:
      if (player.played.length > 1) {
        setTimeout(() => {
          dispatch(setTutorialStep(35));
        }, 10);
      }
      break;
    case 35:
      showSeekers = true;
      showLens = true;
      let arsenal7 = document.getElementById('arsenal');
      if (arsenal7 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal7.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Cards in your arsenal must always be played in ASCENDING order from left to right, whether they are face up or down. The game will do this for you automatically.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => dispatch(setArmEnabled(false)), 10);
      break;
    case 36:
      showSeekers = true;
      showLens = true;
      let arsenal8 = document.getElementById('arsenal');
      if (arsenal8 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal8.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `This is also true of the cards in your opponents' arsenals. You can use this fact to deduce what cards they might have armed.`;
        return (
          <div className="lens-message" key={currStep}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 37:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent02 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent02 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent02.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 38:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent18 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent18 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent18.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 39:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent19 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent19 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent19.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 40:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent20 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent20 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent20.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 41:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent21 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent21 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent21.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 42:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent22 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent22 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent22.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 43:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent23 = document.getElementById('opponent-2');
      lens_msg_direction = LensMessageDirection.Left;
      if (opponent23 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent23.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reckless Ambusher is still dead.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 44:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let arsenal9 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Right;
      if (arsenal9 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal9.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It is your turn again, and you COULD reveal the ace you have armed, HOWEVER...`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 45:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      blockTablePointerEvents = false;
      let opp2 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp2 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp2.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Intriguing Saboteur has an armed card that MUST be higher than a 4, and the threat value is currently only 2 away from the target value. If a player reveals a card that causes the threat value to EXCEED the target value, they are eliminated. Click the armed card to select it.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => dispatch(setSelectEnabled(true)), 10);
      // Listener for next step
      if (selectedCard != null) {
        setTimeout(() => {
          dispatch(setTutorialStep(46));
          dispatch(setPreventComputerMoves(true));
        }, 10);
      }
      break;
    case 46:
      showLens = true;
      blockTablePointerEvents = false;
      let opp1 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp1 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp1.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Since you have a card armed yourself, you are able to EXPOSE other players' armed cards using your action. Click EXPOSE to force Intriguing Saboteur to reveal their card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };

      setTimeout(() => {
        dispatch(setExposeEnabled(true));
      }, 10);

      // Listener for next step
      if (cpu1.played.every((card: Card) => !card.flipped)) {
        setTimeout(() => {
          dispatch(setTutorialStep(47));
          dispatch(setExposeEnabled(false));
        }, 10);
      }
      break;
    case 47:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;

      let opp3 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp3 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp3.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Your EXPOSE was successful. Intriguing Saboteur has been eliminated from the game.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 48:
      showSeekers = true;
      showLens = true;

      let opp4 = document.getElementById('opponent-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp4 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp4.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `An EXPOSE is successful if it causes the threat value to exceed the target value. If an EXPOSE is not successful, it will RICOCHET and force you to reveal one of your own cards, potentially killing you.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 49:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent24 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent24 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent24.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 50:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent25 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent25 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent25.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 51:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent26 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent26 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent26.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 52:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let arsenal10 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Right;
      if (arsenal10 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal10.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It's your turn again. You could still reveal your ace, however it won't cause the threat value to reach exactly 16, and it is good to keep a low-rank card armed.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 53:
      showLens = true;
      blockTablePointerEvents = false;
      let cache01 = document.getElementById('player-cache-0');
      lens_msg_direction = LensMessageDirection.Right;
      lens_opacity = 0;
      if (cache01 === null) {
        console.error('No element');
      } else {
        lens_target_rect = cache01.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Instead, select the five of hearts.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setSelectEnabled(true));
        dispatch(setFortifyEnabled(true));
        dispatch(setPreventComputerMoves(true));
      }, 10);
      // Listener
      if (selectedCard != null && selectedCard.rank === Rank.Five) {
        dispatch(setTutorialStep(54));
      }
      break;
    case 54:
      showLens = true;
      blockTablePointerEvents = false;
      let armour2 = document.getElementById('protection');
      lens_msg_direction = LensMessageDirection.Right;
      if (armour2 === null) {
        console.error('No element');
      } else {
        lens_target_rect = armour2.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Since this card shares the mark's suit, it can be used to FORTIFY the mark. Click the armour space.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setFortifyEnabled(true));
      }, 10);
      // Listener
      if (armourState.length > 0) {
        dispatch(setTutorialStep(55));
      }
      break;
    case 55:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      lens_msg_direction = LensMessageDirection.Left;
      let gameVals1 = document.getElementById('game-values');
      lens_opacity = 0;
      if (gameVals1 === null) {
        console.error('No element');
      } else {
        lens_target_rect = gameVals1.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Fortifying the mark adds the rank of a card to the target value. You can also win the round by raising the target value to ${armourBound} or above.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 56:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent27 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent27 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent27.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 57:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent28 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent28 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent28.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 58:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent29 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent29 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent29.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue RELOADED.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 59:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent30 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent30 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent30.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Reloading adds additional cards to your cache. You draw 3 cards when reloading if your cache is empty, otherwise you draw 1 card, up to a maximum of 4.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 60:
      showLens = true;
      blockTablePointerEvents = false;
      let deck1 = document.getElementById('deck');
      lens_msg_direction = LensMessageDirection.Right;
      if (deck1 === null) {
        console.error('No element');
      } else {
        lens_target_rect = deck1.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It is your turn. Click the deck to reload.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setReloadEnabled(true));
      }, 1);
      // listener
      if (player.hand.length > 2) {
        setTimeout(() => {
          dispatch(setReloadEnabled(false));
          dispatch(setTutorialStep(61));
        }, 10);
      }
      break;
    case 61:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent31 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent31 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent31.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 62:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent32 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent32 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent32.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 63:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent33 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent33 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent33.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue PURLOINED your ace of spades.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 64:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent34 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent34 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent34.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `The PURLOIN action lets you take an opponent's face-down card and immediately reveal it. Each player can only purloin once, so Silent Rogue will no longer be able to PURLOIN in this round.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setSelectEnabled(true));
      });
      break;
    case 65:
      showLens = true;
      blockTablePointerEvents = false;
      lens_opacity = 0;
      let cache02 = document.getElementById('player-cache-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (cache02 === null) {
        console.error('No element');
      } else {
        lens_target_rect = cache02.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It is your turn, and you have no more armed cards in your arsenal. Select this card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // Listener
      if (selectedCard != null && selectedCard.rank === Rank.Two) {
        setTimeout(() => {
          dispatch(setTutorialStep(66));
          dispatch(setSelectEnabled(false));
          dispatch(setArmEnabled(true));
        }, 10);
      }
      break;
    case 66:
      showLens = true;
      blockTablePointerEvents = false;
      let arsenal11 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Right;
      if (arsenal11 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal11.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Arm your card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // Listener
      if (player.played.length > 1) {
        setTimeout(() => {
          dispatch(setTutorialStep(67));
          dispatch(setArmEnabled(false));
        }, 10);
      }
      break;
    case 67:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent35 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent35 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent35.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 68:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent36 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent36 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent36.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 69:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent37 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent37 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent37.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue REVEALED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 70:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let arsenal12 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Right;
      if (arsenal12 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal12.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It's your turn. . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 71:
      showLens = true;
      blockTablePointerEvents = false;
      let opponent39 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent39 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent39.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Expose Silent Rogue's remaining card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setSelectEnabled(true));
        dispatch(setExposeEnabled(true));
      });
      // Listener
      if (cpu2.played.every((card: Card) => !card.flipped)) {
        setTimeout(() => {
          dispatch(setTutorialStep(72));
          dispatch(setExposeEnabled(false));
          dispatch(setRevealEnabled(true));
        });
      }
      break;
    case 72:
      showLens = true;
      blockTablePointerEvents = false;
      let arsenal14 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Right;
      if (arsenal14 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal14.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Your expose RICOCHETED (the threat value is still below the target value). You must now reveal one of your own cards.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // Listener
      if (player.played.every((card: Card) => !card.flipped)) {
        setTimeout(() => {
          dispatch(setTutorialStep(73));
          dispatch(setRevealEnabled(false));
        });
      }
      break;
    case 73:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let gamevals = document.getElementById('game-values');
      lens_msg_direction = LensMessageDirection.Right;
      if (gamevals === null) {
        console.error('No element');
      } else {
        lens_target_rect = gamevals.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Luckily, the threat value is still below the target value.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 74:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent38 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent38 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent38.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue will now take their turn.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 75:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      hideNextSeek = true;
      let opponent392 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent392 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent392.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue is thinking.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(false));
      }, 10);
      break;
    case 76:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let opponent40 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opponent40 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opponent40.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Silent Rogue ARMED a card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setPreventComputerMoves(true));
      }, 10);
      break;
    case 77:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      let arsenal13 = document.getElementById('arsenal');
      lens_msg_direction = LensMessageDirection.Right;
      if (arsenal13 === null) {
        console.error('No element');
      } else {
        lens_target_rect = arsenal13.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `It's your turn. . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 78:
      showSeekers = true;
      showLens = true;
      let cache03 = document.getElementById('player-cache-0');
      lens_msg_direction = LensMessageDirection.Right;
      if (cache03 === null) {
        console.error('No element');
      } else {
        lens_target_rect = cache03.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      break;
    case 79:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp79 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp79 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp79.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `You should purloin Silent Rogue's armed card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setSelectEnabled(true));
        dispatch(setPurloinEnabled(true));
      }, 10);
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 80:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp80 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp80 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp80.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 81:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp81 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp81 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp81.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Purloin Silent Rogue's card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 82:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp82 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp82 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp82.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 83:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp83 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp83 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp83.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `Take. My. Card.`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setGlitchIntensity(0.05));
      });
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 84:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp84 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp84 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp84.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setGlitchIntensity(0.07));
      });
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 85:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp85 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp85 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp85.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setGlitchIntensity(0.1));
      });
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 86:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      let opp86 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp86 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp86.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . . . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setGlitchIntensity(0.12));
      });
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 87:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      redden = true;
      let opp87 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp87 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp87.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . . . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setGlitchIntensity(0.15));
      });
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 88:
      showSeekers = true;
      showLens = true;
      hidePrevSeek = true;
      blockTablePointerEvents = false;
      redden = true;
      let opp88 = document.getElementById('opponent-1');
      lens_msg_direction = LensMessageDirection.Right;
      if (opp88 === null) {
        console.error('No element');
      } else {
        lens_target_rect = opp88.getBoundingClientRect();
      }
      lensMessageRenderer = () => {
        const text = `. . . . .`;
        return (
          <div className="lens-message" key={currStep} style={{ translate: `0px 3rem` }}>
            <SequentialText text={text} wordDelay={100} phraseDelay={500} />
          </div>
        );
      };
      setTimeout(() => {
        dispatch(setGlitchIntensity(0.25));
        dispatch(setTableTremble(true));
      });
      // Listener
      if (player.played.length >= 3) {
        setTimeout(() => {
          dispatch(setTutorialStep(90));
          dispatch(setSelectEnabled(false));
          dispatch(setPurloinEnabled(false));
        });
      }
      break;
    case 89:
      throw new Error('YOU STUBBORN PLEBIAN');
    case 90:
      showLens = true;
      blockTablePointerEvents = false;
      let app = document.getElementById('App');
      lens_msg_direction = LensMessageDirection.Right;
      if (app === null) {
        console.error('No element');
      } else {
        lens_target_rect = app.getBoundingClientRect();
      }
      break;
    default:
      messageRenderer = () => {
        return <div className="nodisplay">TUTORIAL END</div>;
      };
  }

  if (showLens && lens_target_rect != null) {
    // Lens box
    hl_lens_height = lens_target_rect.bottom - lens_target_rect.top + 2 * padding;
    hl_lens_width = lens_target_rect.right - lens_target_rect.left + 2 * padding;
    hl_lens_left = lens_target_rect.left - padding;
    hl_lens_top = lens_target_rect.top - padding;

    // Horizontal backdrop elements
    hl_left_width = lens_target_rect.left - padding + hl_lens_border / 2;
    hl_right_width = window.innerWidth - lens_target_rect.right - padding + hl_lens_border / 2;

    // Message
    if (lens_msg_direction === LensMessageDirection.Right) {
      lens_message_left = lens_target_rect.right + padding;
      lens_message_top = lens_target_rect.top;
    } else if (lens_msg_direction === LensMessageDirection.Left) {
      lens_message_left = lens_target_rect.left - 300;
      lens_message_top = lens_target_rect.top;
    }
  }

  const OnTutorialKeydown = (e: React.KeyboardEvent) => {
    if ((e.key === 'Enter' || e.key === 'ArrowRight' || e.key === 'd') && !hideNextSeek) {
      dispatch(incrementTutorial());
    }
  };

  const exitTutorial = () => {
    PlaySubtleClick();
    dispatch(setActivePage(Page.Home));
    dispatch(setTutorialActive(false));
    setTimeout(() => {
      dispatch(resetAssassinsState());
    }, 2000);
  };

  return (
    <div
      className={`tutorial-container ${active ? '' : 'nodisplay'}`}
      style={{
        zIndex: `${ZIndices.TutorialMask}`,
        pointerEvents: blockTablePointerEvents ? 'all' : 'none',
      }}
      tabIndex={0}
      onKeyDown={OnTutorialKeydown}
    >
      <div className={`tutorial-mask ${showMask ? '' : 'nodisplay'}`}>
        <div className="tutorial-message-container">
          <div className="big-a fancy">A</div>
          <div className="messages">{messageRenderer()}</div>
        </div>
      </div>
      <div className="exit-group">
        <button onClick={exitTutorial}>
          <FontAwesomeIcon icon={faX as IconDefinition} />
        </button>
      </div>
      <div className={`control-group ${showSeekers ? '' : 'nodisplay'}`}>
        <div
          className={`next tutorial-control ${hideNextSeek ? 'nodisplay' : ''}`}
          onClick={() => dispatch(incrementTutorial())}
        >
          <FontAwesomeIcon icon={faStepForward} />
        </div>
        <div
          className={`prev tutorial-control ${hidePrevSeek ? 'nodisplay' : ''}`}
          onClick={() => {
            if (currStep === 0) {
              dispatch(setActivePage(Page.Home));
              // TODO: Thunk this
              setTimeout(() => {
                dispatch(resetAssassinsState());
                dispatch(setTutorialActive(false));
              }, 1000);
            } else {
              dispatch(decrementTutorial());
            }
          }}
        >
          <FontAwesomeIcon icon={faStepBackward} />
        </div>
      </div>
      <div className={`lens-group ${showLens ? '' : 'nodisplay'} red-${redden}`}>
        <div
          id="highlighting-lens"
          style={{
            width: `${hl_lens_width}px`,
            height: `${hl_lens_height}px`,
            left: `${hl_lens_left}px`,
            top: `${hl_lens_top}px`,
            borderWidth: `${hl_lens_border}px`,
            opacity: `${lens_opacity}`,
          }}
        ></div>
        <div
          id="hl-left"
          style={{
            width: `${hl_left_width}px`,
            height: `100%`,
            left: `${0}px`,
            top: `${0}px`,
          }}
        ></div>
        <div
          id="hl-right"
          style={{
            width: `${hl_right_width}px`,
            height: `100%`,
            top: `${0}px`,
            right: `${0}px`,
          }}
        ></div>
        <div
          id="hl-top"
          style={{
            width: `100%`,
            height: `${hl_top_height}px`,
            top: `${0}px`,
            right: `${0}px`,
          }}
        ></div>
        <div
          id="hl-bot"
          style={{
            width: `100%`,
            height: `${hl_bot_height}px`,
            top: `${0}px`,
            right: `${0}px`,
          }}
        ></div>
        <div
          className="lens-message-container"
          style={{
            top: `${lens_message_top}px`,
            left: `${lens_message_left}px`,
          }}
        >
          {lensMessageRenderer()}
        </div>
      </div>
    </div>
  );
};
