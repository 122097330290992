const CardPlayAudioIDs = [
  'card-place-1',
  'card-place-2',
  'card-place-3',
  'card-place-4',
  'card-slide-1',
  'card-slide-2',
  'card-slide-3',
  'card-slide-4',
  'card-slide-5',
  'card-slide-6',
  'card-slide-7',
  'card-slide-8',
];

const CardFanAudioIDs = ['card-fan-1', 'card-fan-2'];

const CardOpenAudioIDs = ['card-open-package'];

export const SFX_IDs = [CardFanAudioIDs, CardPlayAudioIDs, CardOpenAudioIDs, ['click-1', 'click-2', 'click-3']];

export const PlayCardAudio = () => {
  const i = Math.floor(Math.random() * CardPlayAudioIDs.length);
  const el: HTMLAudioElement = document.getElementById(CardPlayAudioIDs[i]) as HTMLAudioElement;
  if (el != null) {
    el.play();
  }
};

export const PlayFanAudio = () => {
  const i = Math.floor(Math.random() * CardFanAudioIDs.length);
  const el: HTMLAudioElement = document.getElementById(CardFanAudioIDs[i]) as HTMLAudioElement;
  if (el != null) {
    el.play();
  }
};

export const PlayOpenAudio = () => {
  const i = Math.floor(Math.random() * CardOpenAudioIDs.length);
  const el: HTMLAudioElement = document.getElementById(CardOpenAudioIDs[i]) as HTMLAudioElement;
  if (el != null) {
    el.play();
  }
};

export const PlaySubtleClick = () => {
  const el = document.getElementById('click-2') as HTMLAudioElement;
  el.play();
};

export const PlaySideClick = () => {
  const el = document.getElementById('click-1') as HTMLAudioElement;
  el.play();
};

export const PlayBigClick = () => {
  const el = document.getElementById('click-3') as HTMLAudioElement;
  el.play();
};
