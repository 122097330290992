import { combineReducers } from 'redux';
import appReducer from './Slices/appSlice';
import assassinsReducer from './Slices/assassinsSlice';
import tutorialReducer from './Slices/tutorialSlice';

const rootReducer = combineReducers({
  app: appReducer,
  assassins: assassinsReducer,
  tutorial: tutorialReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
