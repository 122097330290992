import { AssassinsPlayer, AssassinsState } from '../State/Slices/assassinsSlice';
import { IsRoomJoinableResponseEvent } from './events';

export const wsConnect = () => ({ type: 'WS_CONNECT' });
export const wsDisconnect = (playerId: string) => ({ type: 'WS_DISCONNECT', playerId });
export const wsCreateGame = () => ({ type: 'WS_CREATE_GAME' });
export const wsStartGame = (roomId: string) => ({ type: 'WS_START_GAME', roomId });
export const wsJoinGame = (roomId: string, playerName: string, playerId: string) => ({
  type: 'WS_JOIN_GAME',
  roomId,
  playerName,
  playerId,
});
export const wsSyncState = (roomId: string, state: AssassinsState, sourceId: string) => ({
  type: 'WS_SYNC_STATE',
  state,
  roomId,
  sourceId,
});
export const wsSyncPlayer = (roomId: string, player: AssassinsPlayer) => ({
  type: 'WS_SYNC_PLAYER',
  roomId,
  player,
});
export const wsFetchRoom = (roomId: string) => ({
  type: 'WS_FETCH_ROOM',
  roomId,
});
export const wsPing = (roomId: string, playerId: string, timestamp: number) => ({
  type: 'WS_PING',
  roomId,
  playerId,
  timestamp,
});
export const wsKick = (roomId: string, playerId: string) => ({
  type: 'WS_KICK',
  roomId,
  playerId,
});
export const wsRegisterPlayer = (playerId: string) => ({
  type: 'WS_REGISTER_PLAYER',
  playerId,
});
export const wsIsRoomJoinableRespond = (evt: IsRoomJoinableResponseEvent) => ({
  type: 'WS_IS_ROOM_JOINABLE_RESPOND',
  evt: evt,
});
export const wsCreateTutorial = () => ({
  type: 'WS_CREATE_TUTORIAL',
});
