import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { Card, Rank, Suit } from '../../types';

export interface TutorialState {
  tutorialActive: boolean;
  tutorialStep: number;
}

const initialTutorialState: TutorialState = {
  tutorialActive: false,
  tutorialStep: 0,
};

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState: initialTutorialState,
  reducers: {
    setTutorialActive: (state: TutorialState, action: PayloadAction<boolean>) => {
      state.tutorialActive = action.payload;
    },
    incrementTutorial: (state: TutorialState) => {
      state.tutorialStep++;
    },
    decrementTutorial: (state: TutorialState) => {
      state.tutorialStep--;
    },
    setTutorialStep: (state: TutorialState, action: PayloadAction<number>) => {
      state.tutorialStep = action.payload;
    },
  },
});

export default tutorialSlice.reducer;
export const { setTutorialActive, incrementTutorial, decrementTutorial, setTutorialStep } = tutorialSlice.actions;

export const TutorialDeck: Card[] = [
  // Mark
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Queen,
  },
  // HP
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Clubs,
    rank: Rank.Six,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Ten,
  },
  // Player Cache
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Eight,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Spades,
    rank: Rank.Ace,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Five,
  },
  // PC 1 Cache
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Four,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Clubs,
    rank: Rank.Six,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Nine,
  },
  // PC 2 Cache
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Two,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Four,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Ace,
  },

  // PC 3 Cache
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Ten,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Ace,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Clubs,
    rank: Rank.Five,
  },
  // Other
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Five,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Hearts,
    rank: Rank.Six,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Clubs,
    rank: Rank.Two,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Diamonds,
    rank: Rank.Two,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Clubs,
    rank: Rank.Three,
  },
  {
    id: uuid(),
    flipped: false,
    suit: Suit.Spades,
    rank: Rank.Nine,
  },
];
