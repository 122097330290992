import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setActiveDialogue,
  setCanEnterLanding,
  setFunctionalCookiesEnabled,
  setMusicVolume,
  setSFXVolume,
} from '../../State/Slices/appSlice';
import { useAppSelector } from '../../State/hooks';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, ZIndices } from '../../types';

interface Props {}

export const LandingDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const canEnterLanding = useAppSelector((state) => state.app.canEnterLanding);
  const musicVolume = useAppSelector((state) => state.app.musicVolume);
  const sfxVolume = useAppSelector((state) => state.app.sfxVolume);
  const functionalCookiesEnabled = useAppSelector((state) => state.app.functionalCookiesEnabled);
  const [closed, setClosed] = useState(false);

  const volumeMin = 0;
  const volumeMax = 0.9;

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (!canEnterLanding) {
      setShown(true);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
        dispatch(setActiveDialogue(Dialogue.none));
        setClosed(true);
      }, fadeOutDuration);
    }
  }, [canEnterLanding]);

  const closeButtonOnClick = () => {
    PlaySubtleClick();
    dispatch(setCanEnterLanding(true));
  };

  return (
    <div
      id="landing-dialogue"
      className={`dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${shown ? '' : 'nodisplay'} ${
        closed ? 'nodisplay' : ''
      }`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Alerts}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="dialogue-section title">Permissions</div>
          <div className="dialogue-section permission-settings">
            <div className="permission-setting">
              <label htmlFor="music-volume-input">Music Volume</label>
              <input
                name="music-volume-input"
                type="range"
                min={volumeMin}
                max={volumeMax}
                step={0.05}
                value={musicVolume}
                onChange={(e) => dispatch(setMusicVolume(parseFloat(e.target.value)))}
              ></input>
              <div className="tracker-bg" style={{ width: `${(musicVolume / volumeMax) * 100}%` }}></div>
            </div>
            <div className="permission-setting">
              <label htmlFor="sfx-volume-input">SFX Volume</label>
              <input
                name="sfx-volume-input"
                type="range"
                min={volumeMin}
                max={volumeMax}
                step={0.05}
                value={sfxVolume}
                onChange={(e) => dispatch(setSFXVolume(parseFloat(e.target.value)))}
              ></input>
              <div className="tracker-bg" style={{ width: `${(sfxVolume / volumeMax) * 100}%` }}></div>
            </div>
            <div className="permission-setting">
              <label htmlFor="functional-cookies-allowed">Allow functional cookies</label>
              <input
                name="functional-cookies-allowed"
                type="checkbox"
                checked={functionalCookiesEnabled}
                onChange={(e) => dispatch(setFunctionalCookiesEnabled(e.target.checked))}
              ></input>
            </div>
            {!functionalCookiesEnabled ? (
              <div className="permission-setting cookie-disabled-warning">
                WARNING: Disabling functional cookies will prevent you from re-joining games and tracking your
                preferences on this browser. This software does not use any advertising or tracking cookies.
              </div>
            ) : null}
          </div>
          <div className="dialogue-section">
            <div className="dialogue-controls">
              <button className="dialogue-close popout-button rounded-square" onClick={closeButtonOnClick}>
                ENTER ASSASSINS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
