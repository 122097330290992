import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetAppState } from '../State/Slices/appSlice';
import { resetAssassinsState } from '../State/Slices/assassinsSlice';
import { RootState } from '../State/rootReducer';
import { Page } from '../types';
import { AddClassSafe, RemoveClassSafe } from '../utility';

export const StateNavigator = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activePage = useSelector((state: RootState) => state.app.activePage);
  const lobbyID = useSelector((state: RootState) => state.app.lobbyID);
  const prevPage = useRef(Page.Home);

  // Properly reset the application on unexpected browser navigation
  // TODO: Alert dialogue
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigate('/');
      dispatch(resetAppState());
      dispatch(resetAssassinsState());
    });
  }, []);

  if (activePage !== prevPage.current) {
    const appElement = document.getElementById('App') as HTMLElement;

    switch (activePage) {
      case Page.Table:
        RemoveClassSafe(appElement, 'enter-up');
        AddClassSafe(appElement, 'exit-up');
        setTimeout(() => {
          navigate(`/play/${lobbyID}`);
          RemoveClassSafe(appElement, 'exit-up');
          AddClassSafe(appElement, 'enter-up');
        }, 500);
        break;
      case Page.Lobby:
        RemoveClassSafe(appElement, 'enter-up');
        AddClassSafe(appElement, 'exit-up');
        setTimeout(() => {
          navigate(`/lobby/${lobbyID}`);
          RemoveClassSafe(appElement, 'exit-up');
          AddClassSafe(appElement, 'enter-up');
        }, 500);
        break;
      case Page.Home:
        RemoveClassSafe(appElement, 'enter-up');
        AddClassSafe(appElement, 'exit-up');
        setTimeout(() => {
          navigate(`/`);
          RemoveClassSafe(appElement, 'exit-up');
          AddClassSafe(appElement, 'enter-up');
        }, 500);
        break;
    }

    prevPage.current = activePage;
  }

  return <div className="nodisplay"></div>;
};
