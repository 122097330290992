import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { setTooltipState } from '../../State/Slices/appSlice';
import { RootState } from '../../State/rootReducer';
import { AddClassSafe, CreateTooltip, DefaultMouseLeave, RemoveClassSafe } from '../../utility';
import CardView from '../Card/CardView';

let markClickBlock = false;

interface Props {}

const MarkView = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const htmlid = uuid();
  const mark = useSelector((state: RootState) => state.assassins.mark);

  const targetHover = (htmlid: string) => {
    dispatch(
      setTooltipState(
        CreateTooltip(
          '<p>Make the <b>threat value</b> equal to the <b>target value</b></p><p>to <b>assassinate the mark</b> and win the round.</p>',
          htmlid,
        ),
      ),
    );
  };

  const cardFidget = (htmlid: string) => {
    if (markClickBlock) {
      return;
    }
    markClickBlock = true;
    const el = document.getElementById(htmlid) as HTMLElement;
    const second_half = el.classList.contains('card-trick-1');
    if (second_half) {
      AddClassSafe(el, 'card-trick-2');
    } else {
      AddClassSafe(el, 'card-trick-1');
    }

    setTimeout(() => {
      if (second_half) {
        RemoveClassSafe(el, 'card-trick-1');
        RemoveClassSafe(el, 'card-trick-2');
      }
      markClickBlock = false;
    }, 500);
  };

  return (
    <div className="target" id="mark">
      <div
        className="zone-name"
        id={htmlid}
        onMouseEnter={() => targetHover(htmlid)}
        onMouseLeave={() => DefaultMouseLeave(dispatch)}
      >
        Mark
      </div>
      <div className="card-zone">
        <div className="card-location" id="mark-location" onClick={() => cardFidget('mark-location')}>
          <div className="card-outline"></div>
          {mark !== null && mark !== undefined ? (
            <div className="card-container" style={{ fontSize: `0.25rem` }}>
              <CardView card={mark} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MarkView;
