import { v4 as uuid } from 'uuid';
import { RNG } from '../randomizer';
import { ActionViability, AssassinationCheckResult, Card, GameAction } from '../types';
import { AssassinsPlayer, AssassinsState } from './Slices/assassinsSlice';
import {
  AddLogItem,
  ArmourCard,
  GetPlayerByID,
  GetPlayerWithCard,
  GetTargetVal,
  GetThreatVal,
  HandleTurnEnd,
  PlayCard,
  ReloadPlayer,
  ReloadPlayerOnce,
  RevealCard,
} from './stateHelpers';

export const StandardComputerBehaviour = (state: AssassinsState) => {
  console.log('Performing Computer Behaviour.');
  if (state.activePlayer == null) {
    console.error('No active player');
    return;
  }

  // Reveal own card if it would assassinate, or protect
  const threat = GetThreatVal(state);
  const target = GetTargetVal(state);
  let player = state.activePlayer;
  for (const card of player.played) {
    if (card.flipped) {
      if (threat + card.rank === target) {
        RevealCard(state, { card: card, playerId: player.id });
        HandleTurnEnd(state);
        return;
      }
      if (target + card.rank >= state.protectionBound) {
        RevealCard(state, { card: card, playerId: player.id });
        HandleTurnEnd(state);
        return;
      }
    }
  }

  const hasArmedCard = player.played.some((card) => card.flipped);

  // Expose opponent's card if it would eliminate them
  if (hasArmedCard) {
    for (const opponent of state.players) {
      if (opponent.id === player.id || !opponent.alive) continue;
      let known = 0;
      for (const card of opponent.played) {
        if (!card.flipped) {
          known = card.rank;
        } else {
          if (threat + known > target) {
            const revealResult = RevealCard(state, { card: card, playerId: opponent.id });
            if (revealResult !== AssassinationCheckResult.Over) {
              console.error("Computer expected this card to kill player. This shouldn't happen.");
            }
            HandleTurnEnd(state);
            return;
          }
        }
      }
    }
  }

  // Choose a random action, of current viable actions
  let canArmour = false;
  let canReveal = false;
  let canDraw = false;
  let canArm = false;
  let canExpose = false;
  let canPurloin = false;

  for (const card of player.hand) {
    if (card.suit === state.mark?.suit) {
      canArmour = true;
    }
  }

  for (const card of player.played) {
    if (card.flipped && card.rank + threat < target) {
      canReveal = true;
    }
  }

  if (player.hand.length < 3) {
    canDraw = true;
  } else {
    canArm = true;
  }

  let oppCardAvailable = false;
  for (const opponent of state.players) {
    if (opponent.id === player.id || !opponent.alive) continue;
    for (const card of opponent.played) {
      if (card.flipped) {
        oppCardAvailable = true;
        if (hasArmedCard) {
          canExpose = true;
        }
      }
    }
  }

  if (oppCardAvailable) canPurloin = player.canPurloin;

  let allActions: ActionViability[] = [
    {
      action: GameAction.Arm,
      viable: canArm,
    },
    {
      action: GameAction.Armour,
      viable: canArmour,
    },
    {
      action: GameAction.Draw,
      viable: canDraw,
    },
    {
      action: GameAction.Expose,
      viable: canExpose,
    },
    {
      action: GameAction.Purloin,
      viable: canPurloin,
    },
    {
      action: GameAction.Reveal,
      viable: canReveal,
    },
  ];

  let viableActions: GameAction[] = [];
  for (const actionViability of allActions) {
    if (actionViability.viable) {
      viableActions.push(actionViability.action);
    }
  }

  // let _rng = seedrandom();
  let move_i = Math.floor(RNG() * viableActions.length);
  let move = viableActions[move_i];
  switch (move) {
    case GameAction.Arm:
      console.log('ARMING');
      ComputerArm(state, player);
      return;
    case GameAction.Armour:
      console.log('ARMOURING');
      ComputerArmour(state, player);
      return;
    case GameAction.Draw:
      console.log('DRAWING');
      ComputerDraw(state, player);
      return;
    case GameAction.Expose:
      console.log('EXPOSING');
      ComputerExpose(state, player.id);
      return;
    case GameAction.Purloin:
      console.log('PURLOINING');
      ComputerPurloin(state, player.id);
      return;
    case GameAction.Reveal:
      console.log('REVEALING');
      ComputerReveal(state, player.id);
      return;
  }
};

export const TutorialComputerBehaviour = (state: AssassinsState, tutorialStep: number) => {
  console.log('Performing Tutorial Computer Behaviour.');
  if (state.activePlayer == null) {
    console.error('No active player');
    return;
  }
  let player = state.activePlayer;

  if (tutorialStep < 20) {
    let card = player.hand[0];
    let playIndex = 0;
    let i = 0;
    while (i < player.played.length && player.played[i].rank < card.rank) {
      playIndex += 2;
      i++;
    }
    PlayCard(state, { playerId: player.id, index: playIndex, card: card });
    HandleTurnEnd(state);
  } else if (tutorialStep < 31) {
    let card = player.played[0];
    RevealCard(state, { card: card, playerId: player.id });
    player.actions.push({
      text: `REVEALED`,
      id: uuid(),
    });
    HandleTurnEnd(state);
  } else if (tutorialStep < 43) {
    let card = player.hand[0];
    let playIndex = 0;
    let i = 0;
    while (i < player.played.length && player.played[i].rank < card.rank) {
      playIndex += 2;
      i++;
    }
    PlayCard(state, { playerId: player.id, index: playIndex, card: card });
    HandleTurnEnd(state);
  } else if (tutorialStep < 52) {
    let card = player.hand[0];
    let playIndex = 0;
    let i = 0;
    while (i < player.played.length && player.played[i].rank < card.rank) {
      playIndex += 2;
      i++;
    }
    PlayCard(state, { playerId: player.id, index: playIndex, card: card });
    HandleTurnEnd(state);
  } else if (tutorialStep < 60) {
    ComputerDraw(state, player);
  } else if (tutorialStep < 65) {
    ComputerPurloin(state, player.id);
  } else if (tutorialStep < 70) {
    let card = player.played[2];
    RevealCard(state, { card: card, playerId: player.id });
    player.actions.push({
      text: `REVEALED`,
      id: uuid(),
    });
    HandleTurnEnd(state);
  } else if (tutorialStep < 78) {
    let card = player.hand[0];
    let playIndex = 0;
    let i = 0;
    while (i < player.played.length && player.played[i].rank < card.rank) {
      playIndex += 2;
      i++;
    }
    PlayCard(state, { playerId: player.id, index: playIndex, card: card });
    HandleTurnEnd(state);
  }
};

export const ComputerArm = (state: AssassinsState, player: AssassinsPlayer) => {
  let potentialCards: Card[] = [];
  for (const card of player.hand) {
    potentialCards.push(card);
  }
  let card = potentialCards[Math.floor(RNG() * potentialCards.length)];
  // TODO: this index stuff might be unnecessary
  let playIndex = 0;
  let i = 0;
  while (i < player.played.length && player.played[i].rank < card.rank) {
    playIndex += 2;
    i++;
  }
  PlayCard(state, { playerId: player.id, index: playIndex, card: card });
  HandleTurnEnd(state);
};

export const ComputerArmour = (state: AssassinsState, player: AssassinsPlayer) => {
  let potentialCards = [];
  for (const card of player.hand) {
    if (card.suit === state.mark?.suit) {
      potentialCards.push(card);
    }
  }
  let card = potentialCards[Math.floor(RNG() * potentialCards.length)];
  ArmourCard(state, { playerId: player.id, card: card });
  HandleTurnEnd(state);
};

export const ComputerDraw = (state: AssassinsState, player: AssassinsPlayer) => {
  if (player.hand.length > 1) {
    ReloadPlayerOnce(state, player.id);
  } else {
    ReloadPlayer(state, player.id);
  }
  HandleTurnEnd(state);
};

export const ComputerExpose = (state: AssassinsState, playerId: string) => {
  const player = GetPlayerByID(state, playerId);
  if (player === null) {
    console.error('Could not find player.');
    return;
  }
  let potentialCards = [];
  for (const opponent of state.players) {
    if (opponent.id === player.id || !opponent.alive) continue;
    for (const card of opponent.played) {
      if (card.flipped) {
        potentialCards.push(card);
      }
    }
  }
  let card = potentialCards[Math.floor(RNG() * potentialCards.length)];
  let opponent = GetPlayerWithCard(state, card);
  if (opponent === undefined) {
    console.error('Could not find card owner.');
    return;
  } else {
    AddLogItem(state, `${player.name} exposed ${opponent.name}.`);
    const exposeResult = RevealCard(state, { card: card, playerId: opponent.id });
    if (exposeResult === AssassinationCheckResult.Under) {
      // Ricochet
      player.actions.push({
        text: 'RICOCHETED',
        id: uuid(),
      });
      let potentialCards: Card[] = [];
      for (const card of player.played) {
        if (card.flipped) {
          potentialCards.push(card);
        }
      }
      let card = potentialCards[Math.floor(RNG() * potentialCards.length)];
      RevealCard(state, { card: card, playerId: player.id });
    } else {
      player.actions.push({
        text: 'EXPOSED',
        id: uuid(),
      });
    }
    HandleTurnEnd(state);
    return;
  }
};

export const ComputerPurloin = (state: AssassinsState, playerId: string) => {
  const player = GetPlayerByID(state, playerId);
  if (player === null) {
    console.error('Could not find player.');
    return;
  }
  let potentialCards = [];
  for (const opponent of state.players) {
    if (opponent.id === player.id || !opponent.alive) continue;
    for (const card of opponent.played) {
      if (card.flipped) {
        potentialCards.push(card);
      }
    }
  }
  let card = potentialCards[Math.floor(RNG() * potentialCards.length)];
  let opponent = GetPlayerWithCard(state, card);
  if (opponent === undefined) {
    console.error('Could not find card owner.');
    return;
  } else {
    opponent.played = opponent.played.filter((c) => c.id !== card.id);
    const target = state.players.find((p) => p.id === player.id);
    if (target === undefined) {
      console.error('PC Purloin Target not found');
      return;
    }
    target.played.push(card);
    AddLogItem(state, `${player.name} purloined from ${opponent.name}.`);
    player.actions.push({
      text: 'PURLOINED',
      id: uuid(),
    });
    player.canPurloin = false;
    RevealCard(state, { card: card, playerId: player.id });
    HandleTurnEnd(state);
    return;
  }
};

export const ComputerReveal = (state: AssassinsState, playerId: string) => {
  const player = GetPlayerByID(state, playerId);
  if (player === null) {
    console.error('Could not find player.');
    return;
  }
  let potentialCards: Card[] = [];
  for (const card of player.played) {
    if (card.flipped) {
      potentialCards.push(card);
    }
  }
  let card = potentialCards[Math.floor(RNG() * potentialCards.length)];
  RevealCard(state, { card: card, playerId: player.id });
  player.actions.push({
    text: `REVEALED`,
    id: uuid(),
  });
  HandleTurnEnd(state);
  return;
};
