import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { IS_PROD } from '../../client_env';
import {
  Alert,
  AppWindow,
  Card,
  Context,
  ContextType,
  Dialogue,
  Page,
  TooltipDirection,
  TooltipState,
} from '../../types';
import { GetName } from '../../utility';
import { PingEvent } from '../../websockets/events';

export type PeerConnection = {
  id: string;
  connected: boolean;
  latency: number | null;
};

export interface AppState {
  alerts: Alert[];
  activeDialogue: Dialogue;
  activeContext: Context;
  playerID: string;
  playerName: string;
  activePage: Page;
  pageTransitionDurationMS: number;
  tooltip: TooltipState;
  mostRecentWindow: AppWindow;
  actionLockout: boolean; // Boolean used to prevent actions during animations
  preventComputerMoves: boolean;
  canEnterLanding: boolean;
  functionalCookiesEnabled: boolean;

  // In-Game
  selectedCard: Card | null;
  selectEnabled: boolean;
  armEnabled: boolean;
  reloadEnabled: boolean;
  exposeEnabled: boolean;
  revealEnabled: boolean;
  purloinEnabled: boolean;
  fortifyEnabled: boolean;

  // Windows
  showHelpCard: boolean;
  showConnectionsWindow: boolean;
  showLogWindow: boolean;

  // Animations
  yourTurnAnim: boolean;
  selectedCardCSS: string;
  playedCardOutAnimationDuration: number;
  playedCardInAnimationDuration: number;
  actionTextDuration: number;

  // LOBBY
  previousRoomId: string | null;
  lobbyID: string | null;
  isOwner: boolean;
  connections: PeerConnection[];

  // Rendering
  glitchIntensity: number;
  tableTremble: boolean;

  // Audio
  musicVolume: number;
  sfxVolume: number;
}

const savedPlayerId = localStorage.getItem('player_id');
const savedPlayerName = localStorage.getItem('player_name');
const previousRoomId = localStorage.getItem('previous_room_id');
let playerId, playerName;
let USE_CACHE_DEV = false; // If TRUE, browser-cached player info (ID, name, etc.) will be used in Dev env

if (IS_PROD || USE_CACHE_DEV) {
  if (savedPlayerId === null) {
    playerId = uuid();
    localStorage.setItem('player_id', playerId);
  } else {
    playerId = savedPlayerId;
  }
  if (savedPlayerName === null) {
    playerName = GetName();
  } else {
    playerName = savedPlayerName;
  }
} else {
  playerId = uuid();
  playerName = GetName();
}

let selfConnection: PeerConnection = {
  id: playerId,
  connected: true,
  latency: 0,
};

const initialAppState: AppState = {
  alerts: [],
  activeDialogue: Dialogue.AudioLanding,
  activeContext: {
    type: ContextType.None,
    location: { x: 0, y: 0 },
  },
  tooltip: {
    active: false,
    text: '',
    direction: TooltipDirection.above,
    targetID: '',
  },
  playerID: playerId,
  playerName: playerName,
  activePage: Page.Home,
  lobbyID: null,
  pageTransitionDurationMS: 1000,
  isOwner: true,
  selectedCard: null,
  yourTurnAnim: false,
  showHelpCard: true,
  showConnectionsWindow: false,
  showLogWindow: false,
  mostRecentWindow: AppWindow.None,
  previousRoomId: previousRoomId,
  connections: [selfConnection],
  actionLockout: false,
  selectedCardCSS: '',
  playedCardOutAnimationDuration: 500,
  playedCardInAnimationDuration: 500,
  actionTextDuration: 3000,
  preventComputerMoves: false,
  selectEnabled: true,
  armEnabled: true,
  reloadEnabled: true,
  exposeEnabled: true,
  revealEnabled: true,
  purloinEnabled: true,
  fortifyEnabled: true,
  glitchIntensity: 0.03,
  tableTremble: false,
  canEnterLanding: false,
  musicVolume: 0.25,
  sfxVolume: 0.15,
  functionalCookiesEnabled: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    resetAppState: (state: AppState) => {
      return initialAppState;
    },
    pushAlert: (state: AppState, action: PayloadAction<Alert>) => {
      state.alerts.push(action.payload);
    },
    killAlertByID: (state: AppState, action: PayloadAction<string>) => {
      const alert = state.alerts.find((a) => a.id === action.payload);
      if (alert !== undefined) {
        alert.alive = false;
      }
    },
    removeAlertByID: (state: AppState, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
    },
    setActiveDialogue: (state: AppState, action: PayloadAction<Dialogue>) => {
      state.activeDialogue = action.payload;
    },
    setActivePage: (state: AppState, action: PayloadAction<Page>) => {
      state.activePage = action.payload;
    },
    setLobbyID: (state: AppState, action: PayloadAction<string | null>) => {
      state.lobbyID = action.payload;
    },
    setPlayerName: (state: AppState, action: PayloadAction<string>) => {
      state.playerName = action.payload;
    },
    setIsOwner: (state: AppState, action: PayloadAction<boolean>) => {
      state.isOwner = action.payload;
    },
    createNewContext: (state: AppState, action: PayloadAction<Context>) => {
      state.activeContext = action.payload;
    },
    clearContext: (state: AppState) => {
      state.activeContext.type = ContextType.None;
    },
    setTooltipState: (state: AppState, action: PayloadAction<TooltipState>) => {
      state.tooltip = action.payload;
    },
    clearTooltip: (state: AppState) => {
      state.tooltip = {
        active: false,
        text: '',
        direction: TooltipDirection.above,
        targetID: '',
      };
    },
    setSelectedCard: (state: AppState, action: PayloadAction<Card | null>) => {
      state.selectedCard = action.payload;
    },
    setYourTurnAnimation: (state: AppState, action: PayloadAction<boolean>) => {
      state.yourTurnAnim = action.payload;
    },
    setShowHelpCard: (state: AppState, action: PayloadAction<boolean>) => {
      state.showHelpCard = action.payload;
    },
    toggleShowHelpCard: (state: AppState) => {
      state.showHelpCard = !state.showHelpCard;
    },
    setShowConnectionsWindow: (state: AppState, action: PayloadAction<boolean>) => {
      state.showConnectionsWindow = action.payload;
    },
    toggleShowConnectionsWindow: (state: AppState) => {
      state.showConnectionsWindow = !state.showConnectionsWindow;
    },
    setMostRecentWindow: (state: AppState, action: PayloadAction<AppWindow>) => {
      state.mostRecentWindow = action.payload;
    },
    addConnection: (state: AppState, action: PayloadAction<PeerConnection>) => {
      state.connections.push(action.payload);
    },
    removeConnectionByID: (state: AppState, action: PayloadAction<string>) => {
      state.connections = state.connections.filter((conn) => conn.id !== action.payload);
    },
    updateConnectionLatency: (state: AppState, action: PayloadAction<PingEvent>) => {
      for (const connection of state.connections) {
        if (connection.id === action.payload.playerId) {
          let latency = Date.now() - action.payload.timestamp;
          connection.latency = latency;
        }
      }
    },
    setConnectionStatus: (state: AppState, action: PayloadAction<{ id: string; status: boolean }>) => {
      for (const connection of state.connections) {
        if (connection.id === action.payload.id) {
          connection.connected = action.payload.status;
        }
      }
    },
    setLogWindow: (state: AppState, action: PayloadAction<boolean>) => {
      state.showLogWindow = action.payload;
    },
    toggleLogWindow: (state: AppState) => {
      state.showLogWindow = !state.showLogWindow;
    },
    setActionLockout: (state: AppState, action: PayloadAction<boolean>) => {
      state.actionLockout = action.payload;
    },
    setSelectedCardCSS: (state: AppState, action: PayloadAction<string>) => {
      state.selectedCardCSS = action.payload;
    },
    setPreventComputerMoves: (state: AppState, action: PayloadAction<boolean>) => {
      state.preventComputerMoves = action.payload;
    },
    setSelectEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.selectEnabled = action.payload;
    },
    setArmEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.armEnabled = action.payload;
    },
    setFortifyEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.fortifyEnabled = action.payload;
    },
    setRevealEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.revealEnabled = action.payload;
    },
    setExposeEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.exposeEnabled = action.payload;
    },
    setPurloinEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.purloinEnabled = action.payload;
    },
    setReloadEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.reloadEnabled = action.payload;
    },
    setGlitchIntensity: (state: AppState, action: PayloadAction<number>) => {
      state.glitchIntensity = action.payload;
    },
    setTableTremble: (state: AppState, action: PayloadAction<boolean>) => {
      state.tableTremble = action.payload;
    },
    setCanEnterLanding: (state: AppState, action: PayloadAction<boolean>) => {
      state.canEnterLanding = action.payload;
    },
    setMusicVolume: (state: AppState, action: PayloadAction<number>) => {
      state.musicVolume = action.payload;
    },
    setSFXVolume: (state: AppState, action: PayloadAction<number>) => {
      state.sfxVolume = action.payload;
    },
    setFunctionalCookiesEnabled: (state: AppState, action: PayloadAction<boolean>) => {
      state.functionalCookiesEnabled = action.payload;
    },
  },
});

export default appSlice.reducer;
export const {
  pushAlert,
  killAlertByID,
  removeAlertByID,
  setActiveDialogue,
  setActivePage,
  setLobbyID,
  setPlayerName,
  setIsOwner,
  createNewContext,
  clearContext,
  setTooltipState,
  clearTooltip,
  resetAppState,
  setSelectedCard,
  setYourTurnAnimation,
  setShowHelpCard,
  toggleShowHelpCard,
  setShowConnectionsWindow,
  toggleShowConnectionsWindow,
  setMostRecentWindow,
  addConnection,
  updateConnectionLatency,
  setConnectionStatus,
  removeConnectionByID,
  setLogWindow,
  setActionLockout,
  toggleLogWindow,
  setSelectedCardCSS,
  setPreventComputerMoves,
  setArmEnabled,
  setExposeEnabled,
  setFortifyEnabled,
  setPurloinEnabled,
  setReloadEnabled,
  setRevealEnabled,
  setSelectEnabled,
  setGlitchIntensity,
  setTableTremble,
  setCanEnterLanding,
  setMusicVolume,
  setSFXVolume,
  setFunctionalCookiesEnabled,
} = appSlice.actions;
