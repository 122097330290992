import { PeerConnection } from './State/Slices/appSlice';
import { AssassinsPlayer } from './State/Slices/assassinsSlice';

export enum AlertSeverity {
  success = 'green',
  warning = 'orange',
  info = 'blue',
  error = 'red',
}

export type Alert = {
  id: string;
  content: string;
  severity: AlertSeverity;
  alive: boolean;
};

export enum ZIndices {
  Windows = 10,
  Menu = 20,
  Dialogues = 100,
  TutorialMask = 125,
  FilmGrain = 9999,
  Contexts = 200,
  Tooltips = 500,
  GameGraphics = 700,
  Alerts = 1000,
  RenderEffects = 9999,
}

export enum Dialogue {
  none,
  JoinGame,
  MobileWarning,
  Rules,
  EndRound,
  GameEnd,
  Reconnect,
  AudioLanding,
  Settings,
}

export enum Colour {
  red = 'red',
  black = 'black',
}

export enum Suit {
  Hearts,
  Spades,
  Diamonds,
  Clubs,
}

export const SUITS = [Suit.Clubs, Suit.Diamonds, Suit.Hearts, Suit.Spades];

export enum Rank {
  'Ace' = 1,
  'Two' = 2,
  'Three' = 3,
  'Four' = 4,
  'Five' = 5,
  'Six' = 6,
  'Seven' = 7,
  'Eight' = 8,
  'Nine' = 9,
  'Ten' = 10,
  'Jack' = 11,
  'Queen' = 12,
  'King' = 13,
}

export const RANKS = [
  Rank.Ace,
  Rank.Two,
  Rank.Three,
  Rank.Four,
  Rank.Five,
  Rank.Six,
  Rank.Seven,
  Rank.Eight,
  Rank.Nine,
  Rank.Ten,
  Rank.Jack,
  Rank.Queen,
  Rank.King,
];

export enum CardType {
  Face,
  Numeral,
}

export enum PipStyle {
  Character,
  SVG,
}

export type CardPip = {};

export type Card = {
  suit: Suit;
  rank: number;
  flipped: boolean; // TRUE = Face DOWN
  id: string;
};

export enum Page {
  Home,
  Lobby,
  Table,
}

export enum AssassinationCheckResult {
  Over,
  Equal,
  Under,
  Protect,
}

export type RevealAction = {
  card: Card;
  playerId: string;
};

export type ArmourAction = {
  card: Card;
  playerId: string;
};

export type PlayAction = {
  card: Card;
  playerId: string;
  index: number;
};

export enum ContextType {
  None,
  OpponentCard,
  OwnCard,
}

export type Context = {
  type: ContextType;
  location: { x: number; y: number };
};

export enum TooltipDirection {
  above,
  below,
  left,
  right,
}

export type TooltipState = {
  active: boolean;
  text?: string;
  direction: TooltipDirection;
  targetID: string;
  renderer?: () => JSX.Element;
};

export enum AnimationStates {
  Asleep = 'anim-asleep',
  Waking = 'anim-waking',
  Idle = 'anim-idle',
  Snoozing = 'anim-snoozing',
}

export enum AppWindow {
  None,
  ReferenceCard,
  ConnectionStatus,
  Log,
}

export type UserData = {
  connection: PeerConnection;
  player: AssassinsPlayer;
};

export enum GameAction {
  Reveal,
  Arm,
  Armour,
  Expose,
  Purloin,
  Draw,
}

export type ActionViability = {
  action: GameAction;
  viable: boolean;
};

export enum SideEffect {
  ExposeUnder,
}

export type LogItem = {
  id: string;
  content?: string;
  renderer?: () => JSX.Element;
  timestamp: number;
  source: string;
};

export enum CardFreshness {
  None = '',
  Fresh = 'fresh', // This card has not been intro-animated yet
  Stale = 'stale', // This card has already been intro-animated
}

export type CardSpaceRenderData = {
  content: Card | null;
  freshness: CardFreshness;
};

export type ActionText = {
  text: string;
  id: string;
};

export enum ComputerLevel {
  Standard,
  Tutorial,
}

export interface ComputerPlayer extends AssassinsPlayer {
  level: ComputerLevel;
}
