import { Card, Suit } from '../../types';

interface Props {
  card: Card;
}

const SuitIcon = (props: Props): JSX.Element => {
  const card = props.card;
  return (
    <div className="suit-icon">
      {card.suit === Suit.Clubs ? (
        <div className="suit">&clubs;</div>
      ) : card.suit === Suit.Diamonds ? (
        <div className="suit">&diams;</div>
      ) : card.suit === Suit.Hearts ? (
        <div className="suit">&hearts;</div>
      ) : card.suit === Suit.Spades ? (
        <div className="suit">&spades;</div>
      ) : (
        <div>Invalid Card Suit</div>
      )}
    </div>
  );
};

export default SuitIcon;
