import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialogue } from '../../State/Slices/appSlice';
import { RootState } from '../../State/rootReducer';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, ZIndices } from '../../types';

interface Props {}

const RulesDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const activeDialogue = useSelector((state: RootState) => state.app.activeDialogue);

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (activeDialogue === Dialogue.Rules) {
      setShown(true);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
      }, fadeOutDuration);
    }
  }, [activeDialogue]);

  const closeButtonOnClick = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
  };

  return (
    <div
      id="rules-dialogue"
      className={`dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${shown ? '' : 'nodisplay'}`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Dialogues}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="body">
            <div className="dialogue-section">
              <h1>Assassins</h1>
            </div>
            <div className="dialogue-section">
              <div className="title">Summary</div>
              <div className="blurb">
                <p>
                  <b>Assassins</b> is a competitive card game for 2-8 players, played with a standard 52-card deck. A
                  single game consists of multiple rounds. Typically, the first player to win 3 rounds is declared the
                  victor.
                </p>
                <p>
                  You can download a PDF of these rules for offline play{' '}
                  <a href="assassins_rules.pdf" rel="noreferrer" target="_blank">
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="dialogue-section">
              <div className="title">Setup</div>
              <div className="blurb">
                <p>
                  First, separate the deck into a <b>face deck</b> containing all of the Jacks, Queens, and Kings, and a
                  <b> numeral deck</b> containing all of the other cards.
                </p>
                <p>Shuffle both decks.</p>
                <p>
                  Draw a card from the face deck and place it face-up in the center of the table. This card is the{' '}
                  <b>mark</b>. Draw two cards from the numeral deck and place them face-up next to the mark, these
                  represent the mark's <b>hit points</b>.
                </p>
                <p>
                  Deal a hand (<b>cache</b>) of three cards from the numeral deck to each player.
                </p>
                <p>
                  At the end of each round, collect all of the numeral cards and shuffle them into the numeral deck
                  before starting the next round.
                </p>
              </div>
            </div>
            <div className="dialogue-section">
              <div className="title">Objective</div>
              <div className="blurb">
                <p>
                  The most common way to win a round is by <i>assassinating the mark</i>. This is done by causing the{' '}
                  <b>threat value</b> to <i>exactly equal</i> the <b>target value</b>.
                </p>
                <p>
                  The threat value is the sum of all face-up cards in every player’s <b>arsenal</b>.
                </p>
                <p>
                  The target value is the sum of the mark’s hit points, plus any <b>armour</b>.
                </p>
              </div>
            </div>
            <div className="dialogue-section">
              <div className="title">Gameplay</div>
              <div className="blurb">
                <p>
                  Play starts with the player to the dealer’s left and continues clockwise. On their turn, a player can
                  choose <i>one</i> of the following actions:{' '}
                  <b>
                    <i>Arm</i>
                  </b>
                  ,{' '}
                  <b>
                    <i>Reveal</i>
                  </b>
                  ,{' '}
                  <b>
                    <i>Expose</i>
                  </b>
                  ,{' '}
                  <b>
                    <i>Purloin</i>
                  </b>
                  ,{' '}
                  <b>
                    <i>Fortify</i>
                  </b>
                  , or{' '}
                  <b>
                    <i>Reload</i>
                  </b>
                  .
                </p>
                <h2>Arm Action</h2>
                <p>
                  Place a card from your cache face-down in your arsenal. Cards in your arsenal must be in ascending
                  order of rank from left to right, regardless of if they have been revealed yet. For example, if you
                  currently have a 5 of clubs in your arsenal and want to arm a 7 of hearts, you must place it to the
                  right of the 5 of clubs, whether that 5 of clubs is face-down or face-up. The suit does not affect the
                  arming order of cards, and cards of equal rank can be played on either side.
                </p>
                <h2>Reveal Action</h2>
                <p>
                  Turn a card in your arsenal face-up. The threat value increases by the rank of the revealed card. If
                  the new threat value <i>equals</i> the target value, you win the round. If the new threat value{' '}
                  <i>exceeds</i> the target value, you <b>die</b>.
                </p>
                <h2>Expose Action</h2>
                <p>You can only perform this action if you have at least one face-down card in your arsenal.</p>
                <p>
                  Turn a card in <i>another player’s arsenal</i> face-up. The threat value increases by the rank of the
                  revealed card. If the new threat value <i>equals</i> the target value, that player wins the round. If
                  the new threat value <i>exceeds</i> the target value, that player dies. If the new threat value is{' '}
                  <i>less than</i> the target value, the action <i>ricochets</i> and you must immediately <b>reveal</b>{' '}
                  a face-down card of your choice in your own arsenal.
                </p>
                <h2>Purloin Action</h2>
                <p>
                  Take a card from <i>another player’s arsenal</i> and immediately turn it face-up. The threat value
                  increases by the rank of the revealed card. If the new threat value equals the target value, you win
                  the round. If the new threat value exceeds the target value, you <b>die</b>. You may only take the
                  purloin action <i>once</i> per round.
                </p>
                <h2>Fortify Action</h2>
                <p>
                  Place a card from your cache face-up next to the mark’s hit points. This card is now considered{' '}
                  <b>armour</b>, and the target value increases by the rank of the card. You may only fortify using
                  cards that share the mark’s suit.
                </p>
                <p>
                  If you cause the target value to <i>exceed 40</i>, you <i>protect the mark</i> and win the round.
                </p>
                <h2>Reload Action</h2>
                <p>
                  Draw a card from the numeral deck. If your cache is empty, draw 3 cards instead. You may only reload
                  if you have 3 or fewer cards in your hand.
                </p>
              </div>
            </div>
            <div className="dialogue-section">
              <div className="title">Death</div>
              <div className="blurb">
                <p>
                  When a player <b>dies</b>, they are eliminated from the round. Their arsenal is discarded and no
                  longer counts towards the threat value. If all but one player is dead, the living player{' '}
                  <i>survives</i> and wins the round.
                </p>
              </div>
            </div>
            <div className="dialogue-controls">
              <button className="popout-button rounded-square" onClick={closeButtonOnClick}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesDialogue;
