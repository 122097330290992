import { useEffect, useState } from 'react';
import { useAppSelector } from '../../State/hooks';
import { AnimationStates, ZIndices } from '../../types';
import './SiteIntro.scss';

export const SiteIntro = (): JSX.Element => {
  const [animState, setAnimState] = useState(AnimationStates.Idle);
  const canEnterLanding = useAppSelector((state) => state.app.canEnterLanding);
  const wakeDelay = 0;
  const wakeDuration = 0;
  const idleDuration = 1000;
  const snoozeDuration = 2000;

  useEffect(() => {
    if (canEnterLanding) {
      playAnim();
    }
  }, [canEnterLanding]);

  const playAnim = () => {
    // These have to be wrapped in timeouts because React won't properly update the state during a render call
    setTimeout(() => {
      setAnimState(AnimationStates.Snoozing);
    }, wakeDuration + idleDuration);
    setTimeout(
      () => {
        setAnimState(AnimationStates.Asleep);
      },
      wakeDuration + idleDuration + snoozeDuration,
    );
  };

  return (
    <div
      id="site-intro-anim"
      className={`nomouse hero-anim-container ${animState}`}
      style={
        {
          zIndex: `${ZIndices.GameGraphics}`,
          '--wake-delay': `${wakeDelay}ms`,
          '--wake-duration': `${wakeDuration}ms`,
          '--idle-duration': `${idleDuration}ms`,
          '--snooze-duration': `${snoozeDuration}ms`,
        } as React.CSSProperties
      }
    >
      <div className="relative">
        <div className={`hero-anim ${animState}`}>
          <div className="anim-layer" style={{ zIndex: 1 }}>
            <div className={`screen ${animState}`}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
