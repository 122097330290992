import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setYourTurnAnimation } from '../../State/Slices/appSlice';
import { RootState } from '../../State/rootReducer';
import { AnimationStates, ZIndices } from '../../types';
import './HeroAnimation.scss';

const YourTurnGraphic = (): JSX.Element => {
  const dispatch = useDispatch();
  const display = useSelector((state: RootState) => state.app.yourTurnAnim);
  const awaiting = useRef(true);
  const [animState, setAnimState] = useState(AnimationStates.Asleep);
  const wakeDelay = 0;
  const wakeDuration = 500;
  const idleDuration = 500;
  const snoozeDuration = 500;

  const playAnim = () => {
    // These have to be wrapped in timeouts because React won't properly update the state during a render call
    setTimeout(() => {
      setAnimState(AnimationStates.Waking);
    }, 0);
    setTimeout(() => {
      setAnimState(AnimationStates.Idle);
    }, wakeDuration);
    setTimeout(() => {
      setAnimState(AnimationStates.Snoozing);
    }, wakeDuration + idleDuration);
    setTimeout(
      () => {
        setAnimState(AnimationStates.Asleep);
        dispatch(setYourTurnAnimation(false));
        awaiting.current = true;
      },
      wakeDuration + idleDuration + snoozeDuration,
    );
  };

  if (display && awaiting.current) {
    playAnim();
    awaiting.current = false;
  }

  return (
    <div
      className={`${display ? '' : 'nodisplay'} hero-anim-container ${animState}`}
      style={
        {
          zIndex: `${ZIndices.GameGraphics}`,
          '--wake-delay': `${wakeDelay}ms`,
          '--wake-duration': `${wakeDuration}ms`,
          '--idle-duration': `${idleDuration}ms`,
          '--snooze-duration': `${snoozeDuration}ms`,
        } as React.CSSProperties
      }
    >
      <div className="relative">
        <div className={`hero-anim ${animState}`}>
          <div className="anim-layer" style={{ zIndex: 1 }}>
            <div className={`box ${animState}`}></div>
          </div>
          <div className="anim-layer" style={{ zIndex: 2 }}>
            <div className={`text ${animState}`}>Your Turn</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourTurnGraphic;
