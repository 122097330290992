import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearContext, pushAlert } from '../../State/Slices/appSlice';
import { AssassinsPlayer, exposeCard, purloinCard } from '../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { GetCanAct, GetPlayerByID, PlayerHasArmedCards } from '../../State/stateHelpers';
import { AlertSeverity, Card, ContextType, SideEffect, ZIndices } from '../../types';
import { createAlert } from '../../utility';

const OpponentCardContext = (): JSX.Element => {
  const dispatch = useDispatch();
  const activeContext = useSelector((state: RootState) => state.app.activeContext);
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const selectedCard = useSelector((state: RootState) => state.app.selectedCard);
  const playedLimit = useSelector((state: RootState) => state.assassins.playedLimit);
  const canAct = useSelector((state: RootState) =>
    GetCanAct(state.assassins, state.app, state.app.playerID, state.app.isOwner),
  );
  const sideEffects = useSelector((state: RootState) => state.assassins.currSideEffects);
  const mustReveal = canAct && sideEffects.includes(SideEffect.ExposeUnder);
  const player = useSelector((state: RootState) =>
    GetPlayerByID(state.assassins, state.app.playerID),
  ) as AssassinsPlayer;
  const opponents = useSelector((state: RootState) => {
    const opps = [];
    for (const player of state.assassins.players) {
      if (player.id !== state.app.playerID) {
        opps.push(player);
      }
    }
    return opps;
  });

  const canExpose = useSelector((state: RootState) => PlayerHasArmedCards(state.assassins, state.app.playerID));
  const canPurloin = player != null && player.canPurloin;
  const exposeActionEnabled = useAppSelector((state) => state.app.exposeEnabled);
  const purloinActionEnabled = useAppSelector((state) => state.app.purloinEnabled);

  // Transition state
  const [shown, setShown] = useState(false);
  const transDuration = 100;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (activeContext.type === ContextType.OpponentCard) {
      setShown(true);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setShown(false);
      fadingIn.current = false;
    }
  }, [activeContext]);

  const exposeOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!canAct) {
      dispatch(pushAlert(createAlert(`Cannot act on another player's turn.`, AlertSeverity.error)));
      return;
    }
    if (!exposeActionEnabled) {
      dispatch(pushAlert(createAlert(`You cannot do that right now.`, AlertSeverity.warning)));
      return;
    }
    let cardOwner = opponents.find((opp) => opp.played.some((card) => card.id === selectedCard?.id));
    if (!cardOwner?.alive) {
      dispatch(pushAlert(createAlert(`Cannot expose a dead player's card.`, AlertSeverity.error)));
      return;
    }
    if (!canExpose) {
      dispatch(pushAlert(createAlert(`You need an armed card to expose another player's card.`, AlertSeverity.error)));
      return;
    }
    if (mustReveal) {
      dispatch(pushAlert(createAlert('You must reveal an armed card.', AlertSeverity.info)));
      return;
    }
    dispatch(exposeCard({ card: selectedCard as Card, playerId: cardOwner.id }));
    dispatch(clearContext());
  };

  const stealOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!canAct) {
      dispatch(pushAlert(createAlert(`Cannot act on another player's turn.`, AlertSeverity.error)));
      return;
    }
    if (!canPurloin) {
      dispatch(pushAlert(createAlert(`You have already purloined this round.`, AlertSeverity.warning)));
      return;
    }
    if (!purloinActionEnabled) {
      dispatch(pushAlert(createAlert(`You cannot do that right now.`, AlertSeverity.warning)));
      return;
    }
    if (player.played.length >= playedLimit) {
      dispatch(pushAlert(createAlert(`You already have the maximum number of armed cards.`, AlertSeverity.error)));
      return;
    }
    if (mustReveal) {
      dispatch(pushAlert(createAlert('You must reveal an armed card.', AlertSeverity.info)));
      return;
    }
    dispatch(purloinCard({ card: selectedCard as Card, playerId: playerId }));
    dispatch(clearContext());
  };

  return (
    <div
      className={`context-container ${fadingIn ? 'context-fade-in' : ''} ${shown ? '' : 'nodisplay'}`}
      style={
        {
          '--fade-duration': `${transDuration}ms`,
          zIndex: `${ZIndices.Contexts}`,
          top: `${activeContext.location.y}px`,
          left: `${activeContext.location.x}px`,
        } as React.CSSProperties
      }
    >
      <div className={`context-option ${canExpose ? '' : 'disabled'}`} onClick={exposeOnClick}>
        <p>Expose</p>
      </div>
      <div className={`context-option ${canPurloin ? '' : 'disabled'}`} onClick={stealOnClick}>
        <p>Purloin</p>
      </div>
    </div>
  );
};

export default OpponentCardContext;
