import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialogue } from '../../State/Slices/appSlice';
import { RootState } from '../../State/rootReducer';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, ZIndices } from '../../types';
import { useWindowDimension } from '../../useWindow';

interface Props {}

const MobileWarningDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const activeDialogue = useSelector((state: RootState) => state.app.activeDialogue);

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  const [width, height] = useWindowDimension();

  const displayWarning = (width: number) => {
    if (width < 1000) {
      dispatch(setActiveDialogue(Dialogue.MobileWarning));
    } else {
      if (activeDialogue === Dialogue.MobileWarning) {
        dispatch(setActiveDialogue(Dialogue.none));
      }
    }
  };

  useEffect(() => {
    displayWarning(width);
  }, []);

  useEffect(() => {
    displayWarning(width);
  }, [width]);

  useEffect(() => {
    if (activeDialogue === Dialogue.MobileWarning) {
      setShown(true);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
      }, fadeOutDuration);
    }
  }, [activeDialogue]);

  const closeButtonOnClick = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
  };

  return (
    <div
      className={`mobile-warning-dialogue dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${
        shown ? '' : 'nodisplay'
      }`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Alerts + 1}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="dialogue-section">Hi there!</div>
          <div className="dialogue-section">
            It looks like you're trying to use this application on a device with a small screen, such as a smartphone.
            This application is currently only developed for desktop layouts, and as a result certain things may appear
            broken when viewed on this device. Some bugs might be avoided by enabling "desktop view" on your device,
            though I still cannot guarantee that everything will work. I am but one often sleepy person developing this
            application :(
          </div>
          <div className="dialogue-section">
            If you'd really like a mobile version and want to bug me about it (or if you're interested in contributing
            to one!), hmu at era.sundry@gmail.com
          </div>
          <div className="dialogue-section">
            <div className="dialogue-controls">
              <div className="dialogue-close ui-button round" onClick={closeButtonOnClick}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileWarningDialogue;
