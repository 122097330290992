import React from 'react';
import './SequentialText.scss';

interface Props {
  text: string;
  wordDelay: number;
  phraseDelay: number;
}

export const PHRASE_DELIMITERS = ['.', ',', '!', '?', ':', ';'];

export const SequentialText = (props: Props): JSX.Element => {
  const { text, wordDelay, phraseDelay } = props;
  const words = text.split(' ');

  let delay_sum = 0;

  return (
    <p className="sequential-text-wrapper">
      {words.map((word, j) => {
        let delay = delay_sum;
        if (PHRASE_DELIMITERS.includes(word[word.length - 1])) {
          delay_sum += phraseDelay;
        }
        delay_sum += wordDelay;
        return (
          <span className="word" key={j} style={{ '--seq-word-delay': `${delay}ms` } as React.CSSProperties}>
            {word}&nbsp;
          </span>
        );
      })}
    </p>
  );
};
