import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { RootState } from '../../State/rootReducer';
import { Card } from '../../types';
import { AddClassSafe, RemoveClassSafe } from '../../utility';
import CardView from '../Card/CardView';

interface Props {}

const HitPointView = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const HP = useSelector((state: RootState) => state.assassins.hp);

  const cardRainbow = (htmlid: string) => {
    const el = document.getElementById(htmlid) as HTMLElement;
    AddClassSafe(el, 'card-trick-rainbow');
    setTimeout(() => {
      RemoveClassSafe(el, 'card-trick-rainbow');
    }, 1000);
  };
  return (
    <div className="hit-points" id="hit-points">
      <div className="zone-name">Hit Points</div>
      <div className="card-zone">
        {HP.map((card: Card, i) => {
          const htmlid = uuid();
          return (
            <div className="card-location" key={i} id={htmlid} onClick={() => cardRainbow(htmlid)}>
              <div className="card-outline"></div>
              <div className="card-container" style={{ fontSize: `0.25rem` }}>
                <CardView card={card} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HitPointView;
