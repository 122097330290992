import { Card, CardType, Colour, Rank } from '../../types';
import { GetCardCharacter, GetCardColour, GetCardType } from '../../utility';
import './Card.scss';
import SuitIcon from './SuitIcon';

interface Props {
  card: Card;
  clickCallback?: Function;
  flipped?: boolean;
}

const CardView = (props: Props): JSX.Element => {
  const card: Card = props.card;
  const cardType: CardType = GetCardType(card);
  const colour: Colour = GetCardColour(card);
  let flipped = props.flipped ? true : false;

  const numerals: JSX.Element[] = [];
  for (let i = 0; i < card.rank; i++) {
    numerals.push(
      <div className="numeral" key={i}>
        <SuitIcon card={card} />
      </div>,
    );
  }

  const cardOnClick = () => {
    if (props.clickCallback !== undefined) {
      props.clickCallback();
    }
  };

  return (
    <div className={`card ${colour} ${flipped ? 'flipped' : ''}`} onClick={cardOnClick}>
      <div className="card-content">
        <div className="card-material"></div>
        <div className="card-face">
          <div className="card-layer glyph-layer" style={{ zIndex: '10' }}>
            <div className="card-glyphs">
              <div className="numeral-glyph">
                <div className="card-typography">{GetCardCharacter(card)}</div>
              </div>
              <div className="glyph-icon">
                <SuitIcon card={card} />
              </div>
            </div>
            <div className="card-glyphs inverse">
              <div className="numeral-glyph">
                <div className="card-typography">{GetCardCharacter(card)}</div>
              </div>
              <div className="glyph-icon">
                <SuitIcon card={card} />
              </div>
            </div>
          </div>
          <div className="card-layer" style={{ zIndex: '1' }}>
            {cardType === CardType.Face ? (
              <div className="card-portrait">
                <div className="portrait-container card-typography">{GetCardCharacter(card)}</div>
                <div className="portrait-suit">
                  <SuitIcon card={card} />
                </div>
              </div>
            ) : (
              <div className="card-numerals">
                {card.rank === Rank.Ace ? (
                  <div className="numerals-container numerals-container-ace">{numerals}</div>
                ) : card.rank === Rank.Two ? (
                  <div className="numerals-container numerals-container-two">{numerals}</div>
                ) : card.rank === Rank.Three ? (
                  <div className="numerals-container numerals-container-three">{numerals}</div>
                ) : card.rank === Rank.Four ? (
                  <div className="numerals-container numerals-container-four">{numerals}</div>
                ) : card.rank === Rank.Five ? (
                  <div className="numerals-container numerals-container-five">{numerals}</div>
                ) : card.rank === Rank.Six ? (
                  <div className="numerals-container numerals-container-six">{numerals}</div>
                ) : card.rank === Rank.Seven ? (
                  <div className="numerals-container numerals-container-seven">{numerals}</div>
                ) : card.rank === Rank.Eight ? (
                  <div className="numerals-container numerals-container-eight">{numerals}</div>
                ) : card.rank === Rank.Nine ? (
                  <div className="numerals-container numerals-container-nine">{numerals}</div>
                ) : card.rank === Rank.Ten ? (
                  <div className="numerals-container numerals-container-ten">{numerals}</div>
                ) : (
                  <div>Invalid Card Rank</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="card-back">
          <div className="card-layer">
            <div className="back-design">
              <img src="/Carta_Francese_retro_Rosso.jpg" alt="Card back" />
              {/* <div className="artwork"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardView;
