import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { setLogWindow, setMostRecentWindow } from '../../State/Slices/appSlice';
import { pushLogContent } from '../../State/Slices/assassinsSlice';
import { RootState } from '../../State/rootReducer';
import { AppWindow, ZIndices } from '../../types';
import './LogWindow.scss';

export const LogWindow = (): JSX.Element => {
  const dispatch = useDispatch();
  const gameLog = useSelector((state: RootState) => state.assassins.gameLog);
  const shown = useSelector((state: RootState) => state.app.showLogWindow);
  const mostRecentWindow = useSelector((state: RootState) => state.app.mostRecentWindow);
  const playerName = useSelector((state: RootState) => state.app.playerName);
  const [chatText, setChatText] = useState('');
  const [pressed, setPressed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onMouseMove = (e: React.MouseEvent) => {
    if (pressed) {
      setPosition({
        x: position.x + e.movementX,
        y: position.y + e.movementY,
      });
    }
  };

  const zIndex = mostRecentWindow === AppWindow.Log ? ZIndices.Windows + 1 : ZIndices.Windows;

  const chatOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatText(e.target.value);
  };

  const chatKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      dispatch(pushLogContent({ timestamp: Date.now(), content: chatText, source: playerName, id: uuid() }));
      setChatText('');
    }
  };

  const closeOnClick = () => {
    dispatch(setLogWindow(false));
  };

  return (
    <div
      className={`draggable-container log-window-container ${shown ? '' : 'nodisplay'}`}
      onMouseMove={onMouseMove}
      onMouseDown={() => {
        setPressed(true);
        dispatch(setMostRecentWindow(AppWindow.Log));
      }}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
      style={{ translate: `${position.x}px ${position.y}px`, zIndex: `${zIndex}` }}
    >
      <div className="relative-parent">
        <div className="draggable-window log-window">
          <div className="header">
            <div className="title">Chat</div>
            <div className="close" onClick={closeOnClick}>
              <FontAwesomeIcon icon={faXmarkCircle} />
            </div>
          </div>
          <div className="body">
            <div className="log-list">
              {gameLog.map((item, i) => {
                if (item.hasOwnProperty('renderer') && item.renderer !== undefined) {
                  return (
                    <div className="log-item" key={item.id}>
                      {item.renderer()}
                    </div>
                  );
                } else {
                  const date = new Date(item.timestamp);
                  const hrs = '0' + date.getHours();
                  const mins = '0' + date.getMinutes();
                  const secs = '0' + date.getSeconds();
                  const time_str = hrs.slice(-2) + ':' + mins.slice(-2) + ':' + secs.slice(-2);
                  return (
                    <div className="log-item" key={item.id}>
                      <div className="sourcetime">
                        <div className={`source ${item.source}`}>{item.source}</div>
                        <div className="time"> ({time_str}):</div>
                      </div>
                      <div className="content" dangerouslySetInnerHTML={{ __html: item.content as string }}></div>
                    </div>
                  );
                }
              })}
              <div className="scroll-anchor">&nbsp;</div>
            </div>
            <div className="chat-input">
              <input value={chatText} onChange={chatOnChange} placeholder="Type to Chat" onKeyDown={chatKeyDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
