import { faCrosshairs, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMostRecentWindow, setShowHelpCard } from '../../State/Slices/appSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { AppWindow, ZIndices } from '../../types';
import './ReferenceCard.scss';

const ReferenceCard = (): JSX.Element => {
  const dispatch = useDispatch();
  const shown = useSelector((state: RootState) => state.app.showHelpCard);
  const mostRecentWindow = useSelector((state: RootState) => state.app.mostRecentWindow);
  const tutorialActive = useAppSelector((state) => state.tutorial.tutorialActive);
  const [pressed, setPressed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onMouseMove = (e: React.MouseEvent) => {
    if (pressed) {
      setPosition({
        x: position.x + e.movementX,
        y: position.y + e.movementY,
      });
    }
  };

  const closeOnClick = () => {
    dispatch(setShowHelpCard(false));
  };

  const zIndex = mostRecentWindow === AppWindow.ReferenceCard ? ZIndices.Windows + 1 : ZIndices.Windows;

  return (
    <div
      className={`draggable-container reference-card-container ${shown ? '' : 'nodisplay'} ${
        tutorialActive ? 'nodisplay' : ''
      }`}
      onMouseMove={onMouseMove}
      onMouseDown={() => {
        setPressed(true);
        dispatch(setMostRecentWindow(AppWindow.ReferenceCard));
      }}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
      style={{ translate: `${position.x}px ${position.y}px`, zIndex: `${zIndex}` }}
    >
      <div className="relative-parent">
        <div className="draggable-window reference-card">
          <div className="title">
            <div className="text">On your turn ...</div>
            <div className="controls">
              <FontAwesomeIcon icon={faXmarkCircle} onClick={closeOnClick} />
            </div>
          </div>
          <div className="turn-options">
            <div className="op">
              <FontAwesomeIcon icon={faCrosshairs} />
              <p>
                <b>Arm</b> a card from your cache.
              </p>
            </div>
            <div className="op">
              <FontAwesomeIcon icon={faCrosshairs} />
              <p>
                <b>Reveal</b> a card in your arsenal.
              </p>
            </div>
            <div className="op">
              <FontAwesomeIcon icon={faCrosshairs} />
              <p>
                <b>Expose</b> a card in another player's arsenal.
              </p>
            </div>
            <div className="op">
              <FontAwesomeIcon icon={faCrosshairs} />
              <p>
                <b>Purloin</b> a card from another player's arsenal.
              </p>
            </div>
            <div className="op">
              <FontAwesomeIcon icon={faCrosshairs} />
              <p>
                <b>Fortify</b> the target with a card that shares the mark's suit.
              </p>
            </div>
            <div className="op">
              <FontAwesomeIcon icon={faCrosshairs} />
              <p>
                <b>Reload</b> your cache with a new card, or 3 if your cache is empty.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceCard;
