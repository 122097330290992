import { useEffect, useRef } from 'react';
import { useAppSelector } from '../State/hooks';
import { SFX_IDs } from '../audio';

export const SoundManager = (): JSX.Element => {
  const audioContextRef = useRef(new AudioContext());
  const musicGainRef = useRef<null | GainNode>(null);
  const sfxGainRef = useRef<null | GainNode>(null);
  const mainThemeSourceRef = useRef<null | MediaElementAudioSourceNode>(null);
  const mainThemeGainRef = useRef<null | GainNode>(null);
  // const lowpassThemeSourceRef = useRef<null | MediaElementAudioSourceNode>(null);
  // const lowpassThemeGainRef = useRef<null | GainNode>(null);
  const canEnterLanding = useAppSelector((state) => state.app.canEnterLanding);
  const musicVolume = useAppSelector((state) => state.app.musicVolume);
  const sfxVolume = useAppSelector((state) => state.app.sfxVolume);
  const activeDialogue = useAppSelector((state) => state.app.activeDialogue);
  const crossfadeTime = 5; // seconds

  useEffect(() => {
    for (const arr of SFX_IDs) {
      for (const id of arr) {
        const el = document.getElementById(id) as HTMLAudioElement;
        if (el != null) {
          el.volume = sfxVolume;
        }
      }
    }
  }, [sfxVolume]);

  useEffect(() => {
    // Initial audio node setup
    if (musicGainRef.current == null) {
      musicGainRef.current = audioContextRef.current.createGain();
    }
    if (sfxGainRef.current == null) {
      sfxGainRef.current = audioContextRef.current.createGain();
    }
    const mainThemeElement = document.getElementById('audio-assassins-theme') as HTMLAudioElement;
    if (mainThemeGainRef.current == null) {
      mainThemeGainRef.current = audioContextRef.current.createGain();
    }
    if (mainThemeSourceRef.current == null) {
      mainThemeSourceRef.current = audioContextRef.current.createMediaElementSource(mainThemeElement);
      mainThemeSourceRef.current
        .connect(mainThemeGainRef.current)
        .connect(musicGainRef.current)
        .connect(audioContextRef.current.destination);
    }
    // const lowpassThemeElement = document.getElementById('audio-assassins-theme-lowpass') as HTMLAudioElement;
    // if (lowpassThemeGainRef.current == null) {
    //   lowpassThemeGainRef.current = audioContextRef.current.createGain();
    // }
    // if (lowpassThemeSourceRef.current == null) {
    //   lowpassThemeSourceRef.current = audioContextRef.current.createMediaElementSource(lowpassThemeElement);
    //   lowpassThemeSourceRef.current
    //     .connect(lowpassThemeGainRef.current)
    //     .connect(musicGainRef.current)
    //     .connect(audioContextRef.current.destination);
    // }
  }, []);

  useEffect(() => {
    if (canEnterLanding && musicGainRef.current != null) {
      musicGainRef.current.gain.value = musicVolume;
    }
  }, [musicVolume, canEnterLanding]);

  useEffect(() => {
    if (canEnterLanding && sfxGainRef.current != null) {
      sfxGainRef.current.gain.value = sfxVolume;
    }
  }, [sfxVolume, canEnterLanding]);

  useEffect(() => {
    if (canEnterLanding) {
      if (audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }
      if (mainThemeSourceRef.current != null) {
        if (mainThemeGainRef.current != null) {
          mainThemeGainRef.current.gain.value = musicVolume;
        }
        mainThemeSourceRef.current.mediaElement.loop = true;
        mainThemeSourceRef.current.mediaElement.play();
      }
      // if (lowpassThemeSourceRef.current != null) {
      //   if (lowpassThemeGainRef.current != null) {
      //     lowpassThemeGainRef.current.gain.value = 0;
      //   }
      //   lowpassThemeSourceRef.current.mediaElement.loop = true;
      //   lowpassThemeSourceRef.current.mediaElement.play();
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEnterLanding]);

  // useEffect(() => {
  //   if (activeDialogue !== Dialogue.none) {
  //     // if (lowpassThemeGainRef.current != null) {
  //     //   lowpassThemeGainRef.current.gain.linearRampToValueAtTime(
  //     //     musicVolume + 0.25,
  //     //     audioContextRef.current.currentTime + crossfadeTime,
  //     //   );
  //     // }
  //     if (mainThemeGainRef.current != null) {
  //       mainThemeGainRef.current.gain.linearRampToValueAtTime(
  //         musicVolume / 2,
  //         audioContextRef.current.currentTime + crossfadeTime,
  //       );
  //     }
  //   } else {
  //     // if (lowpassThemeGainRef.current != null) {
  //     //   lowpassThemeGainRef.current.gain.linearRampToValueAtTime(
  //     //     0,
  //     //     audioContextRef.current.currentTime + crossfadeTime,
  //     //   );
  //     // }
  //     if (mainThemeGainRef.current != null) {
  //       mainThemeGainRef.current.gain.linearRampToValueAtTime(
  //         musicVolume,
  //         audioContextRef.current.currentTime + crossfadeTime,
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeDialogue]);

  return (
    <div id="audio" className="nodisplay">
      <audio src="themev2.wav" id="audio-assassins-theme"></audio>
      <audio src="cardFan1.wav" id="card-fan-1"></audio>
      <audio src="cardFan2.wav" id="card-fan-2"></audio>
      <audio src="cardPlace1.wav" id="card-place-1"></audio>
      <audio src="cardPlace2.wav" id="card-place-2"></audio>
      <audio src="cardPlace3.wav" id="card-place-3"></audio>
      <audio src="cardPlace4.wav" id="card-place-4"></audio>
      <audio src="cardOpenPackage2.wav" id="card-open-package"></audio>
      <audio src="cardSlide1.wav" id="card-slide-1"></audio>
      <audio src="cardSlide2.wav" id="card-slide-2"></audio>
      <audio src="cardSlide3.wav" id="card-slide-3"></audio>
      <audio src="cardSlide4.wav" id="card-slide-4"></audio>
      <audio src="cardSlide5.wav" id="card-slide-5"></audio>
      <audio src="cardSlide6.wav" id="card-slide-6"></audio>
      <audio src="cardSlide7.wav" id="card-slide-7"></audio>
      <audio src="cardSlide8.wav" id="card-slide-8"></audio>
      <audio src="button06.mp3" id="click-1"></audio>
      <audio src="clic03.mp3" id="click-2"></audio>
      <audio src="clic09.mp3" id="click-3"></audio>
    </div>
  );
};
