import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPatreon } from '@fortawesome/free-brands-svg-icons';
import {
  faBook,
  faCircleInfo,
  faEyeSlash,
  faGear,
  faMessage,
  faQuestionCircle,
  faUsers,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTooltip,
  setActiveDialogue,
  setTooltipState,
  toggleLogWindow,
  toggleShowConnectionsWindow,
  toggleShowHelpCard,
} from '../../State/Slices/appSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, Page, ZIndices } from '../../types';
import { CreateTooltip } from '../../utility';
import ComplexIconView, { ComplexIconLayer } from '../ComplexIcon/ComplexIcon';
import './FloatingMenu.scss';

const FloatingMenu = (): JSX.Element => {
  const showHelpCard = useSelector((state: RootState) => state.app.showHelpCard);
  const showConnectionsWindow = useSelector((state: RootState) => state.app.showConnectionsWindow);
  const showLog = useSelector((state: RootState) => state.app.showLogWindow);
  const activePage = useSelector((state: RootState) => state.app.activePage);
  const tutorialActive = useAppSelector((state) => state.tutorial.tutorialActive);
  const dispatch = useDispatch();
  const mouseLeave = () => {
    dispatch(clearTooltip());
  };
  const rulebookIcons: ComplexIconLayer[] = [
    {
      complexicons: [{ icon: faBook }],
      zIndex: 1,
    },
    {
      complexicons: [
        null,
        {
          icon: faCircleInfo,
          primary: 'var(--contrast)',
        },
      ],
      zIndex: 2,
    },
  ];
  const helpIcons: ComplexIconLayer[] = [
    {
      complexicons: [{ icon: faQuestionCircle }],
      zIndex: 1,
    },
    {
      complexicons: [null, null],
      zIndex: 2,
    },
  ];
  if (showHelpCard) {
    helpIcons[1].complexicons[1]! = { icon: faEyeSlash, primary: 'red' };
  }
  const lobbyStatusIcons: ComplexIconLayer[] = [
    {
      complexicons: [{ icon: faUsers }],
      zIndex: 1,
    },
    {
      complexicons: [null, null, null, { icon: faWifi, primary: 'blue' }],
      zIndex: 2,
    },
  ];
  if (showConnectionsWindow) {
    lobbyStatusIcons[1].complexicons[1]! = { icon: faEyeSlash, primary: 'red' };
  }
  const logIcons: ComplexIconLayer[] = [
    {
      complexicons: [{ icon: faMessage }],
      zIndex: 1,
    },
    {
      complexicons: [null, null],
      zIndex: 2,
    },
  ];
  if (showLog) {
    logIcons[1].complexicons[1]! = { icon: faEyeSlash, primary: 'red' };
  }
  return (
    <div
      className={`floating-menu-container ${tutorialActive ? 'nodisplay' : ''}`}
      style={{ zIndex: `${ZIndices.Menu}` }}
    >
      <div className="floating-menu">
        <div
          className="op"
          id="fm-book-op"
          onMouseEnter={() => dispatch(setTooltipState(CreateTooltip('Rulebook', 'fm-book-op')))}
          onMouseLeave={mouseLeave}
          onClick={() => {
            dispatch(setActiveDialogue(Dialogue.Rules));
            PlaySubtleClick();
          }}
        >
          <ComplexIconView layers={rulebookIcons} />
        </div>
        <div
          className="op"
          id="fm-status-op"
          onMouseEnter={() => dispatch(setTooltipState(CreateTooltip('Connection Status', 'fm-status-op')))}
          onMouseLeave={mouseLeave}
          onClick={() => {
            dispatch(toggleShowConnectionsWindow());
            PlaySubtleClick();
          }}
        >
          <ComplexIconView layers={lobbyStatusIcons} />
        </div>
        <div
          className="op"
          id="fm-log-op"
          onMouseEnter={() => dispatch(setTooltipState(CreateTooltip('Game Log / Chat', 'fm-log-op')))}
          onMouseLeave={mouseLeave}
          onClick={() => {
            dispatch(toggleLogWindow());
            PlaySubtleClick();
          }}
        >
          <ComplexIconView layers={logIcons} />
        </div>
        <div
          className={`op ${activePage === Page.Table ? '' : 'nodisplay'}`}
          id="fm-help-card-op"
          onMouseEnter={() => dispatch(setTooltipState(CreateTooltip('Help Card', 'fm-help-card-op')))}
          onMouseLeave={mouseLeave}
          onClick={() => {
            dispatch(toggleShowHelpCard());
            PlaySubtleClick();
          }}
        >
          <ComplexIconView layers={helpIcons} />
        </div>
        <div
          className="op"
          id="fm-support-op"
          onMouseEnter={() => dispatch(setTooltipState(CreateTooltip('Support the developer', 'fm-support-op')))}
          onMouseLeave={mouseLeave}
          onClick={() => {
            window.open('https://www.patreon.com/eracodes', '__blank');
            PlaySubtleClick();
          }}
        >
          <FontAwesomeIcon icon={faPatreon as IconProp} />
        </div>
        <div
          className="op"
          id="fm-settings-op"
          onMouseEnter={() => dispatch(setTooltipState(CreateTooltip('Options', 'fm-settings-op')))}
          onMouseLeave={mouseLeave}
          onClick={() => {
            dispatch(setActiveDialogue(Dialogue.Settings));
            PlaySubtleClick();
          }}
        >
          <FontAwesomeIcon icon={faGear as IconProp} />
        </div>
      </div>
    </div>
  );
};

export default FloatingMenu;
