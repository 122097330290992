import { faCrown, faHand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash.clonedeep';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  clearTooltip,
  createNewContext,
  pushAlert,
  setSelectedCard,
  setTooltipState,
} from '../../State/Slices/appSlice';
import { AssassinsPlayer } from '../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import {
  ActionText,
  AlertSeverity,
  Card,
  CardFreshness,
  CardSpaceRenderData,
  ContextType,
  TooltipDirection,
} from '../../types';
import { CreateTooltip, DefaultMouseLeave, createAlert } from '../../utility';
import CardView from '../Card/CardView';

interface Props {
  opponent: AssassinsPlayer;
  index: number;
}

const OpponentView = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const tutorialActive = useAppSelector((state) => state.tutorial.tutorialActive);
  const selectActionEnabled = useAppSelector((state) => state.app.selectEnabled);

  // Game State
  const opp = props.opponent;
  const winMessage = useSelector((state: RootState) => state.assassins.winMessage);

  // Animation State
  const playedCardInAnimationDuration = useAppSelector((state) => state.app.playedCardInAnimationDuration);
  const actionTextDuration = useAppSelector((state) => state.app.actionTextDuration);

  // Rendering Data
  const staleCardIds = useRef<string[]>([]);
  const staleActionIds = useRef<string[]>([]);

  const emptySpaces = [];
  if (opp.played.length === 0) {
    emptySpaces.push(1);
  }

  const oppCardHover = (card: Card, htmlid: string) => {
    if (!card.flipped) return;
    dispatch(setTooltipState(CreateTooltip('Click for options', htmlid, TooltipDirection.below)));
  };

  const opponentCardClick = (card: Card, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!selectActionEnabled) {
      dispatch(pushAlert(createAlert('You cannot do that right now.', AlertSeverity.warning)));
    } else if (card.flipped) {
      dispatch(createNewContext({ type: ContextType.OpponentCard, location: { x: e.clientX, y: e.clientY } }));
      dispatch(setSelectedCard(card));
      dispatch(clearTooltip());
    } else {
      dispatch(pushAlert(createAlert('That card has already been revealed.', AlertSeverity.warning)));
    }
  };

  const orderedCards = cloneDeep(opp.played).sort((a, b) => a.rank - b.rank);

  const cardSpaces: CardSpaceRenderData[] = [];
  for (const card of orderedCards) {
    let freshness = CardFreshness.Stale;
    if (!staleCardIds.current.includes(card.id)) {
      freshness = CardFreshness.Fresh;
      setTimeout(() => {
        staleCardIds.current.push(card.id);
      }, playedCardInAnimationDuration);
    }
    cardSpaces.push({
      content: card,
      // Check which cards are "fresh" to do intro animations on
      freshness: freshness,
    });
  }

  return (
    <div className="opponent" id={`opponent-${props.index}`}>
      <div className="opponent-name">
        <div>{opp.name}</div>
        <div className={`opp-purloin ${tutorialActive ? 'nodisplay' : ''} purloin-${opp.canPurloin}`}>
          <FontAwesomeIcon icon={faHand} />
        </div>
        <div className={`opp-win-count ${tutorialActive ? 'nodisplay' : ''}`}>
          <FontAwesomeIcon icon={faCrown} />
          {opp.winCount}
        </div>
      </div>
      <div className="opponent-content">
        <div className="opponent-hand">
          {opp.hand.map((card: Card, i) => {
            // Calculate card position
            return (
              <div
                className="card-container"
                key={card.id}
                style={{ fontSize: `0.25rem`, zIndex: `${i}`, left: `${i * 10}px` }}
              >
                <CardView card={card} flipped={true} />
              </div>
            );
          })}
        </div>
        <div className="opponent-played">
          <div className="card-zone">
            {opp.alive ? null : (
              <div className={`death-overlay`}>
                <div className="death-message">Dead</div>
              </div>
            )}
            {opp.won ? (
              <div className={`win-overlay`}>
                <div className="win-message">{winMessage}</div>
              </div>
            ) : null}
            {cardSpaces.map((cardSpace: CardSpaceRenderData, i) => {
              const htmlid = uuid();
              const card = cardSpace.content;
              if (card == null) return null;
              return (
                <div
                  className={`card-location ${cardSpace.freshness}`}
                  id={htmlid}
                  key={i}
                  onClick={(e) => opponentCardClick(card, e)}
                  onMouseEnter={() => oppCardHover(card, htmlid)}
                  onMouseLeave={() => DefaultMouseLeave(dispatch)}
                >
                  <div className="card-outline"></div>
                  <div className="card-container" style={{ fontSize: `0.25rem` }}>
                    <CardView card={card} flipped={card.flipped} />
                  </div>
                </div>
              );
            })}
            {emptySpaces.map((num: any, i: any) => {
              return (
                <div className="card-location" key={i}>
                  <div className="card-outline"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {opp.actions.map((action: ActionText) => {
        if (staleActionIds.current.includes(action.id)) {
          return null;
        } else {
          setTimeout(() => {
            staleActionIds.current.push(action.id);
          }, actionTextDuration);
          return (
            <div className="action-text-container" key={action.id}>
              <div className="action-text">{action.text}</div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default OpponentView;
