import React from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../State/hooks';
import { RootState } from '../State/rootReducer';
import '../styles/tooltip.scss';
import { TooltipDirection, ZIndices } from '../types';

const TooltipView = (): JSX.Element => {
  const tooltip = useSelector((state: RootState) => state.app.tooltip);
  const tutorialActive = useAppSelector((state) => state.tutorial.tutorialActive);
  const tooltipMarginPx = 8;

  const shown = tooltip.active;

  let containerClass = '';
  let containerLeft = '';
  let containerTop = '';
  let containerWidth = '';
  let containerHeight = '';

  const prepareTooltip = () => {
    const target = document.getElementById(tooltip.targetID);
    if (target === null) return;
    const targetRect = target.getBoundingClientRect();

    switch (tooltip.direction) {
      case TooltipDirection.above:
        containerLeft = `${targetRect.left}px`;
        containerTop = `${targetRect.top - tooltipMarginPx}px`;
        containerWidth = `${targetRect.width}px`;
        containerClass = 'container-above';
        break;
      case TooltipDirection.below:
        containerLeft = `${targetRect.left}px`;
        containerTop = `${targetRect.bottom + tooltipMarginPx}px`;
        containerWidth = `${targetRect.width}px`;
        containerClass = 'container-below';
        break;
      case TooltipDirection.left:
        containerLeft = `${targetRect.left - tooltipMarginPx}px`;
        containerTop = `${targetRect.top}px`;
        containerHeight = `${targetRect.height}px`;
        containerClass = 'container-left';
        break;
      case TooltipDirection.right:
        containerLeft = `${targetRect.right + tooltipMarginPx}px`;
        containerTop = `${targetRect.top}px`;
        containerHeight = `${targetRect.height}px`;
        containerClass = 'container-right';
        break;
    }
  };

  if (shown) {
    prepareTooltip();
  }

  // TODO: Reposition tooltip if it would appear outside of viewport

  return (
    <div
      className={`tooltip-container ${shown && !tutorialActive ? '' : 'nodisplay'} ${containerClass}`}
      style={
        {
          '--container-left': containerLeft,
          '--container-top': containerTop,
          '--container-width': containerWidth,
          '--container-height': containerHeight,
          zIndex: `${ZIndices.Tooltips}`,
        } as React.CSSProperties
      }
    >
      <div className="tooltip-wrapper">
        {tooltip.text !== undefined ? (
          <div className="tooltip-body" dangerouslySetInnerHTML={{ __html: tooltip.text }}></div>
        ) : (
          <div className="tooltip-body">{tooltip.renderer!()}</div>
        )}
      </div>
    </div>
  );
};

export default TooltipView;
