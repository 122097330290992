import { PlayerArsenal } from './Player/PlayerArsenal';
import { PlayerCache } from './Player/PlayerCache';

interface Props {}

const PlayerView = (props: Props): JSX.Element => {
  return (
    <div className="player-area">
      <PlayerArsenal />
      <PlayerCache />
    </div>
  );
};

export default PlayerView;
