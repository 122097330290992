import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ComputerController } from './Components/ComputerController';
import { ConnectionsWindow } from './Components/ConnectionsWindow/ConnectionsWindow';
import EndRoundDialogue from './Components/Dialogues/EndRoundDialogue';
import GameEndDialogue from './Components/Dialogues/GameEndDialogue';
import JoinGameDialogue from './Components/Dialogues/JoinGameDialogue';
import { LandingDialogue } from './Components/Dialogues/LandingDialogue';
import MobileWarningDialogue from './Components/Dialogues/MobileWarningDialogue';
import { ReconnectDialogue } from './Components/Dialogues/ReconnectDialogue';
import RulesDialogue from './Components/Dialogues/RulesDialogue';
import SettingsDialogue from './Components/Dialogues/SettingsDialogue';
import FloatingMenu from './Components/FloatingMenu/FloatingMenu';
import { SiteIntro } from './Components/GameGraphics/SiteIntro';
import { LogWindow } from './Components/LogWindow/LogWindow';
import { StateNavigator } from './Components/StateNavigator';
import WebCoordinator from './Components/WebCoordinator';
import { clearContext } from './State/Slices/appSlice';
import { useAppSelector } from './State/hooks';
import { RootState } from './State/rootReducer';
import './styles/tables.scss';
import { wsConnect, wsDisconnect } from './websockets/actions';

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const tableTremble = useAppSelector((state) => state.app.tableTremble);

  const handleBeforeUnload = () => {
    dispatch(wsDisconnect(playerId));
  };

  useEffect(() => {
    // Will run twice in development (due to strict mode)
    console.log('Connecting to Websocket server');
    dispatch(wsConnect());
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appOnClick = () => {
    dispatch(clearContext());
  };

  return (
    <div className={`App ${tableTremble ? 'tremble' : ''}`} id="App" onClick={appOnClick}>
      <SiteIntro />
      <StateNavigator />
      <WebCoordinator />
      <ComputerController />
      <FloatingMenu />
      <ConnectionsWindow />
      <MobileWarningDialogue />
      <LogWindow />
      <JoinGameDialogue />
      <RulesDialogue />
      <EndRoundDialogue />
      <GameEndDialogue />
      <ReconnectDialogue />
      <LandingDialogue />
      <SettingsDialogue />
      {/* Outlet is where the App pages get loaded (landing, lobby, table, etc) */}
      <Outlet />
    </div>
  );
};
export default App;
