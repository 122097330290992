import { faBan, faCrosshairs, faMask, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash.clonedeep';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  clearTooltip,
  pushAlert,
  removeConnectionByID,
  setPlayerName,
  setTooltipState,
} from '../../State/Slices/appSlice';
import { AssassinsPlayer, removePlayerByID, setInitialDealer } from '../../State/Slices/assassinsSlice';
import { RootState } from '../../State/rootReducer';
import { GetPlayerByID } from '../../State/stateHelpers';
import { PlaySubtleClick } from '../../audio';
import { AlertSeverity, TooltipDirection } from '../../types';
import { CreateTooltip, DefaultMouseLeave, createAlert } from '../../utility';
import { wsKick, wsSyncPlayer } from '../../websockets/actions';
import ComplexIconView, { ComplexIconLayer } from '../ComplexIcon/ComplexIcon';

interface Props {
  player: AssassinsPlayer;
}

export const LobbyPlayerView = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const player = props.player;
  const roomID = useSelector((state: RootState) => state.app.lobbyID) as string;
  const isOwner = useSelector((state: RootState) => state.app.isOwner);
  const localPlayerName = useSelector((state: RootState) => state.app.playerName);
  const localPlayerId = useSelector((state: RootState) => state.app.playerID);
  const localPlayer = useSelector((state: RootState) =>
    GetPlayerByID(state.assassins, state.app.playerID),
  ) as AssassinsPlayer;
  const isDealer = player.ordinal === 0;
  const dealerId = uuid();
  const isLocalPlayer = player.id === localPlayerId;

  const playerNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 30) {
      dispatch(pushAlert(createAlert('Maximum characters reached.', AlertSeverity.warning)));
      return;
    }
    dispatch(setPlayerName(e.target.value));
    let updatedPlayer: AssassinsPlayer = cloneDeep(localPlayer);
    updatedPlayer.name = e.target.value;
    dispatch(wsSyncPlayer(roomID, updatedPlayer));
    localStorage.setItem('player_name', e.target.value);
  };

  const dealerClick = (target: string) => {
    if (isOwner) {
      PlaySubtleClick();
      dispatch(setInitialDealer(target));
    }
  };

  const targetHover = (htmlid: string) => {
    dispatch(
      setTooltipState(CreateTooltip('This player will go first in the first round', htmlid, TooltipDirection.above)),
    );
  };

  const playerIconLayers: ComplexIconLayer[] = [
    { complexicons: [], zIndex: 1 },
    { complexicons: [], zIndex: 2 },
  ];

  if (player.sentient) {
    playerIconLayers[0].complexicons.push({ icon: faMask });
  } else {
    playerIconLayers[0].complexicons.push({ icon: faRobot });
  }

  const removePlayer = () => {
    if (!isOwner) {
      dispatch(pushAlert(createAlert('Only the host may remove players.', AlertSeverity.warning)));
      return;
    }
    if (player.id === localPlayerId) {
      dispatch(pushAlert(createAlert('Cannot remove self.', AlertSeverity.warning)));
      return;
    }
    if (player.sentient) {
      dispatch(removeConnectionByID(player.id));
      dispatch(wsKick(roomID, player.id));
    }
    PlaySubtleClick();
    dispatch(removePlayerByID(player.id));
    dispatch(pushAlert(createAlert('Kicked ' + player.name, AlertSeverity.success)));
    dispatch(clearTooltip());
  };

  const removeHoverMsg = player.sentient ? 'Kick player' : 'Remove computer player';
  const icon_id = useRef(uuid());

  return (
    <div className="lobby-user">
      <div
        id={icon_id.current}
        className="user-pfp"
        onMouseLeave={() => DefaultMouseLeave(dispatch)}
        onMouseEnter={() => {
          dispatch(setTooltipState(CreateTooltip(removeHoverMsg, icon_id.current, TooltipDirection.left)));
        }}
        onClick={removePlayer}
      >
        <ComplexIconView layers={playerIconLayers} />
        <div className="delete-icon">
          <FontAwesomeIcon icon={faBan} />
        </div>
      </div>
      {isLocalPlayer ? (
        <input type="text" className="name" value={localPlayerName} onChange={playerNameOnChange}></input>
      ) : (
        <div className="name">{player.name}</div>
      )}
      <div
        id={`${dealerId}`}
        className={`dealer ${isDealer ? 'active' : ''}`}
        onClick={() => dealerClick(player.id)}
        onMouseEnter={isDealer ? () => targetHover(dealerId) : undefined}
        onMouseLeave={() => DefaultMouseLeave(dispatch)}
      >
        <FontAwesomeIcon icon={faCrosshairs} />
      </div>
    </div>
  );
};
