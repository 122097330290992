import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  clearTooltip,
  pushAlert,
  setActionLockout,
  setSelectedCardCSS,
  setTooltipState,
} from '../../State/Slices/appSlice';
import { AssassinsPlayer, armourCard } from '../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { GetCanAct, GetPlayerByID } from '../../State/stateHelpers';
import { AlertSeverity, Card, SideEffect } from '../../types';
import { CreateTooltip, DefaultMouseLeave, createAlert } from '../../utility';
import CardView from '../Card/CardView';
interface Props {}

const ArmourView = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const protection = useSelector((state: RootState) => state.assassins.protection);
  const mark = useSelector((state: RootState) => state.assassins.mark);
  const selectedCard = useSelector((state: RootState) => state.app.selectedCard);
  const canAct = useSelector((state: RootState) =>
    GetCanAct(state.assassins, state.app, state.app.playerID, state.app.isOwner),
  );
  const sideEffects = useSelector((state: RootState) => state.assassins.currSideEffects);
  const mustReveal = canAct && sideEffects.includes(SideEffect.ExposeUnder);
  const player = useSelector((state: RootState) =>
    GetPlayerByID(state.assassins, state.app.playerID),
  ) as AssassinsPlayer;
  const fortifyActionEnabled = useAppSelector((state) => state.app.fortifyEnabled);

  const canArmour = useSelector((state: RootState) => {
    if (state.app.selectedCard === null || state.assassins.mark === null) {
      return false;
    }
    if (state.app.selectedCard.suit !== state.assassins.mark.suit) {
      return false;
    }
    return player.hand.some((c: Card) => c.id === state.app.selectedCard?.id);
  });

  // Animation State
  const playedCardOutAnimationDuration = useAppSelector((state) => state.app.playedCardOutAnimationDuration);

  const armorLocationClass = canArmour ? 'can-play' : '';

  const armourSelectedCard = () => {
    if (!canAct) {
      dispatch(pushAlert(createAlert(`Cannot act on another player's turn.`, AlertSeverity.error)));
      return;
    }
    if (selectedCard === null) {
      dispatch(pushAlert(createAlert('You must select a card first.', AlertSeverity.info)));
      return;
    }
    if (!canArmour) {
      dispatch(pushAlert(createAlert(`Cannot fortify using that card.`, AlertSeverity.error)));
      return;
    }
    if (mustReveal) {
      dispatch(pushAlert(createAlert('You must reveal an armed card.', AlertSeverity.info)));
      return;
    }
    if (!fortifyActionEnabled) {
      dispatch(pushAlert(createAlert(`You cannot do that right now.`, AlertSeverity.warning)));
      return;
    }
    if (selectedCard.suit !== mark?.suit) {
      dispatch(
        pushAlert(createAlert(`Cannot armour a card that does not share the mark's suit.`, AlertSeverity.error)),
      );
      return;
    }

    dispatch(setSelectedCardCSS('being-played'));
    dispatch(setActionLockout(true));
    setTimeout(() => {
      dispatch(setActionLockout(false));
      dispatch(armourCard({ card: selectedCard as Card, playerId: playerId }));
      dispatch(clearTooltip());
      dispatch(setSelectedCardCSS(''));
    }, playedCardOutAnimationDuration);
  };

  const protectionHover = (htmlid: string) => {
    if (selectedCard !== null) {
      dispatch(setTooltipState(CreateTooltip('<p>Play selected card as <b>armour</b></p>', htmlid)));
    } else {
      dispatch(setTooltipState(CreateTooltip('No card selected', htmlid)));
    }
  };

  const protectionTextHover = (htmlid: string) => {
    dispatch(
      setTooltipState(
        CreateTooltip(
          `
        <p>Play cards that share the mark's suit as <b>armour</b></p>
        <p>to increase the <b>target value.</b></p>
        <div class="tt-separator"></div>
        <p>If you reveal a card that raises the</p>
        <p><b>target value</b> above 29, you win the round.</p>
        `,
          htmlid,
        ),
      ),
    );
  };

  return (
    <div className="protection" id="protection">
      <div
        className="zone-name"
        id="protection-text"
        onMouseEnter={() => protectionTextHover('protection-text')}
        onMouseLeave={() => DefaultMouseLeave(dispatch)}
      >
        Armour
      </div>
      <div className="card-zone">
        {protection.map((card: Card, i) => {
          return (
            <div className={`card-location`} key={i}>
              <div className="card-outline"></div>
              <div className="card-container" key={i} style={{ fontSize: `0.25rem` }}>
                <CardView card={card} />
              </div>
            </div>
          );
        })}
        {(() => {
          const htmlid = uuid();
          return (
            <div
              className={`card-location ${armorLocationClass}`}
              id={htmlid}
              onClick={armourSelectedCard}
              onMouseEnter={() => protectionHover(htmlid)}
              onMouseLeave={() => DefaultMouseLeave(dispatch)}
            >
              <div className="card-outline"></div>
            </div>
          );
        })()}
      </div>
    </div>
  );
};

export default ArmourView;
