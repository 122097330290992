import {
  IconDefinition,
  faBullseye,
  faCrosshairs,
  faGun,
  faKey,
  faMask,
  faSatellite,
  faSkull,
  faSkullCrossbones,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import App from './App';
import AlertBox from './Components/AlertBox';
import OpponentCardContext from './Components/Contexts/OpponentCardContext';
import OwnCardContext from './Components/Contexts/OwnCardContext';
import { FilmGrain } from './Components/FilmGrain/FilmGrain';
import GameEndGraphic from './Components/GameGraphics/GameEnd';
import { GameStartGraphic } from './Components/GameGraphics/GameStart';
import RoundEndGraphic from './Components/GameGraphics/RoundEnd';
import YourTurnGraphic from './Components/GameGraphics/YourTurn';
import { SoundManager } from './Components/SoundManager';
import TooltipView from './Components/TooltipView';
import { TutorialMask } from './Components/Tutorial/TutorialMask';
import ErrorBoundary from './ErrorBoundary';
import { RootState } from './State/rootReducer';
import { Page } from './types';

const AppContainer = (): JSX.Element => {
  const activePage = useSelector((state: RootState) => state.app.activePage);
  const bgIconVarieties = [
    faCrosshairs,
    faSkull,
    faBullseye,
    faGun,
    faUserSecret,
    faKey,
    faMask,
    faSkullCrossbones,
    faSatellite,
  ];

  const bgIcons: IconDefinition[][] = [];

  const nIconRows = 10;

  const iconSize = Math.ceil(window.innerHeight / nIconRows);
  for (let i = 0; i < nIconRows; i++) {
    bgIcons.push([]);
    let last = null;
    for (let j = 0; j < 50; j++) {
      let icon;
      do {
        icon = bgIconVarieties[Math.floor(Math.random() * bgIconVarieties.length)];
      } while (icon === last);
      last = icon;
      bgIcons[i].push(icon);
    }
  }

  const animPaused = activePage === Page.Table;

  return (
    <ErrorBoundary>
      <div className="app-container" id="app-container">
        <SoundManager />
        <TooltipView />
        <AlertBox />
        <OpponentCardContext />
        <OwnCardContext />
        <YourTurnGraphic />
        <RoundEndGraphic />
        <GameEndGraphic />
        <GameStartGraphic />
        <App />
        <FilmGrain />
        <TutorialMask />
        <div className={`app-bg ${animPaused ? 'anim-paused' : ''}`}>
          {bgIcons.map((arr, i) => {
            let addClass = '';
            if (i % 2 === 1) {
              addClass += 'raised';
            }
            let moving = Math.random() > 0.75;
            return (
              <div
                className={`bg-icon-row ${addClass} ${moving ? 'moving' : ''}`}
                key={i}
                style={
                  {
                    '--anim-delay': `${Math.floor(Math.random() * 2000)}ms`,
                    '--anim-duration': `${Math.floor(80 + Math.random() * 10)}s`,
                    '--anim-direction': `${Math.random() > 0.5 ? 'normal' : 'reverse'}`,
                  } as React.CSSProperties
                }
              >
                {arr.map((icon, j) => {
                  return (
                    <div
                      className={`bg-icon`}
                      key={j}
                      style={
                        {
                          '--icon-size': `${iconSize}px`,
                        } as React.CSSProperties
                      }
                    >
                      <FontAwesomeIcon icon={icon} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AppContainer;
