import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCard } from '../State/Slices/appSlice';
import { RootState } from '../State/rootReducer';
import { wsPing, wsSyncState } from '../websockets/actions';

const WebCoordinator = (): JSX.Element => {
  const dispatch = useDispatch();
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const roomID = useSelector((state: RootState) => state.app.lobbyID);
  const syncState = useSelector((state: RootState) => state.assassins);

  const updateFlag = useSelector((state: RootState) => state.assassins.updateFlag);
  const prevFlag = useRef(false);

  // Syncing the gamestate when flagged
  useEffect(() => {
    if (updateFlag !== prevFlag.current) {
      if (updateFlag) {
        dispatch(wsSyncState(roomID as string, syncState, playerId));
        dispatch(setSelectedCard(null));
      }
      prevFlag.current = updateFlag;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFlag]);

  // Sending out pings
  useEffect(() => {
    let pingInterval;
    if (roomID !== null) {
      pingInterval = setInterval(() => {
        dispatch(wsPing(roomID, playerId, Date.now()));
      }, 5000);
    } else {
      clearInterval(pingInterval);
    }
  }, [roomID]);

  return <div className="nodisplay"></div>;
};

export default WebCoordinator;
