import { MiddlewareAPI } from '@reduxjs/toolkit';
import {
  PeerConnection,
  addConnection,
  pushAlert,
  setActiveDialogue,
  setActivePage,
  setArmEnabled,
  setConnectionStatus,
  setExposeEnabled,
  setFortifyEnabled,
  setIsOwner,
  setLobbyID,
  setPurloinEnabled,
  setReloadEnabled,
  setRevealEnabled,
  setSelectEnabled,
  setSelectedCard,
  setYourTurnAnimation,
  updateConnectionLatency,
} from '../State/Slices/appSlice';
import {
  AssassinsPlayer,
  addNewPlayer,
  addNewPlayerTutorial,
  forceStateSync,
  resetAssassinsState,
  setPlayersCanJoin,
  startTutorial,
  updateGameState,
  updatePlayer,
} from '../State/Slices/assassinsSlice';
import { setTutorialActive, setTutorialStep } from '../State/Slices/tutorialSlice';
import { RootState } from '../State/rootReducer';
import { AlertSeverity, ComputerLevel, Dialogue, Page, SideEffect } from '../types';
import { CreateAssassinsPlayer, CreateCPU, RemoveClassSafe, createAlert } from '../utility';
import { wsFetchRoom, wsIsRoomJoinableRespond, wsRegisterPlayer } from './actions';
import {
  DisconnectEvent,
  FetchRoomResultEvent,
  GameCreatedEvent,
  GameJoinedEvent,
  IsRoomJoinableQueryEvent,
  IsRoomJoinableResponseEvent,
  NewPlayerJoinedEvent,
  PingEvent,
  SyncPlayerEvent,
  SyncStateEvent,
} from './events';

export const handleGameCreated = (api: MiddlewareAPI, evt: GameCreatedEvent) => {
  const state: RootState = api.getState();
  api.dispatch(setLobbyID(evt.roomID));
  api.dispatch(setIsOwner(true));
  const localPlayer: AssassinsPlayer = {
    id: state.app.playerID,
    name: state.app.playerName,
    played: [],
    hand: [],
    ordinal: 0,
    alive: true,
    won: false,
    winCount: 0,
    isHost: true,
    sentient: true,
    actions: [],
    canPurloin: true,
  };
  api.dispatch(addNewPlayer(localPlayer));
  api.dispatch(setPlayersCanJoin(true));
  api.dispatch(setActivePage(Page.Lobby));
};

export const handleJoinGameFailed = (api: MiddlewareAPI) => {
  api.dispatch(pushAlert(createAlert("Couldn't find a game with that code.", AlertSeverity.error)));
};

export const handleJoinedGame = (api: MiddlewareAPI, evt: GameJoinedEvent) => {
  console.log('Joined game: ' + evt.roomID);
  api.dispatch(setLobbyID(evt.roomID));
  api.dispatch(setActiveDialogue(Dialogue.none));
  api.dispatch(setIsOwner(false));
};

export const handleSyncState = (api: MiddlewareAPI, evt: SyncStateEvent) => {
  const state: RootState = api.getState();
  console.log('Receiving State Sync');
  // Set the page if necessary (TODO: handle this better in StateNav instead)
  if (evt.state.gameActive) {
    // From Lobby -> Table
    if (state.app.activePage !== Page.Table) {
      api.dispatch(setActivePage(Page.Table));
      // Save the game ID to allow re-connection
      localStorage.setItem('previous_room_id', `${state.app.lobbyID}`);
    }
  } else if (!state.assassins.gameActive) {
    // Landing -> Lobby
    if (state.app.activePage !== Page.Lobby) {
      api.dispatch(setActivePage(Page.Lobby));
    }
  } else {
    // Table => Lobby
    if (evt.state.gameEndFlag) {
      api.dispatch(setActivePage(Page.Lobby));
      api.dispatch(setActiveDialogue(Dialogue.none));
    }
  }

  // Notify player if it is their turn
  if (
    evt.state.activePlayer !== null &&
    evt.state.activePlayer.id === state.app.playerID &&
    !evt.state.roundEndFlag &&
    state.assassins.activePlayer?.id !== state.app.playerID
  ) {
    let timeout = evt.state.turn === 0 ? 2000 : 0;
    setTimeout(() => {
      api.dispatch(setYourTurnAnimation(true));
    }, timeout);
  }

  // Notify HOST if it's their turn
  if (
    state.app.isOwner &&
    evt.state.activePlayer !== null &&
    evt.state.activePlayer.id === state.app.playerID &&
    !state.tutorial.tutorialActive
  ) {
    let timeout = evt.state.turn === 0 ? 2000 : 0;
    setTimeout(() => {
      api.dispatch(setYourTurnAnimation(true));
    }, timeout);
  }
  if (
    evt.state.activePlayer !== null &&
    evt.state.activePlayer.id === state.app.playerID &&
    evt.state.currSideEffects.includes(SideEffect.ExposeUnder) &&
    !state.tutorial.tutorialActive
  ) {
    api.dispatch(
      pushAlert(createAlert('<p><b>Expose Ricocheted:</b> You must reveal an armed card.</p>', AlertSeverity.info)),
    );
  }

  // Unset selected card
  api.dispatch(setSelectedCard(null));

  // Update the game state
  api.dispatch(updateGameState(evt.state));

  // TODO: Create "initialSync" handler

  // Update connections
  for (const player of evt.state.players) {
    if (!state.app.connections.some((conn) => conn.id === player.id)) {
      let newConn: PeerConnection = {
        id: player.id,
        connected: true,
        latency: null,
      };
      api.dispatch(addConnection(newConn));
    }
  }
};

export const handleNewPlayerJoined = (api: MiddlewareAPI, evt: NewPlayerJoinedEvent) => {
  const state: RootState = api.getState();
  console.log(state.assassins.players);

  // Check if joining playerId already exists in the game
  if (state.assassins.players.some((player) => player.id === evt.playerId)) {
    console.log('Player Reconnected');
    if (state.app.isOwner) {
      api.dispatch(forceStateSync());
    }
    api.dispatch(setConnectionStatus({ id: evt.playerId, status: true }));
    return;
  } else {
    console.log('New Connection: ' + evt.playerId);
    const newConn: PeerConnection = {
      id: evt.playerId,
      connected: true,
      latency: null,
    };
    api.dispatch(addConnection(newConn));
  }

  if (state.assassins.playersCanJoin && state.assassins.players.length < state.assassins.maxPlayers) {
    console.log('New player: ' + evt.playerName);
    const newPlayer: AssassinsPlayer = {
      id: evt.playerId,
      name: evt.playerName,
      played: [],
      hand: [],
      ordinal: state.assassins.players.length,
      alive: true,
      won: false,
      winCount: 0,
      isHost: false,
      sentient: true,
      actions: [],
      canPurloin: true,
    };
    api.dispatch(addNewPlayer(newPlayer));
  } else {
    // TODO: Spectator Join
    return;
  }
};

export const handleSyncPlayer = (api: MiddlewareAPI, evt: SyncPlayerEvent) => {
  api.dispatch(updatePlayer(evt.player));
};

export const handlePlayerDisconnect = (api: MiddlewareAPI, evt: DisconnectEvent) => {
  const state: RootState = api.getState();
  for (const player of state.assassins.players) {
    if (player.id === evt.playerId) {
      api.dispatch(pushAlert(createAlert(`${player.name} has disconnected`, AlertSeverity.error)));
      api.dispatch(setConnectionStatus({ id: evt.playerId, status: false }));
    }
  }
};

export const handleWSConnected = (api: MiddlewareAPI) => {
  const state: RootState = api.getState();
  const prevRoomId = state.app.previousRoomId;
  console.log('Connected to server');
  if (prevRoomId != null) api.dispatch(wsFetchRoom(prevRoomId));
  api.dispatch(wsRegisterPlayer(state.app.playerID));
};

export const handleFetchRoomResult = (api: MiddlewareAPI, evt: FetchRoomResultEvent) => {
  if (evt.result) {
    api.dispatch(setActiveDialogue(Dialogue.Reconnect));
  }
};

export const handlePing = (api: MiddlewareAPI, evt: PingEvent) => {
  api.dispatch(updateConnectionLatency(evt));
};
export const handleKicked = (api: MiddlewareAPI) => {
  api.dispatch(setActivePage(Page.Home));
  api.dispatch(pushAlert(createAlert('You were removed by the host.', AlertSeverity.error)));
  api.dispatch(resetAssassinsState());
};

export const handleIsRoomJoinableQuery = (api: MiddlewareAPI, evt: IsRoomJoinableQueryEvent) => {
  const state: RootState = api.getState();
  if (!state.app.isOwner) return; // only room owner responds to queries

  console.log('Someone trying to join:' + evt.joinerPlayerName);

  let message = '';
  let joinable = true;
  if (!state.assassins.playersCanJoin) {
    joinable = false;
    message = 'Cannot join a game in progress.';
  } else if (state.assassins.players.length >= state.assassins.maxPlayers) {
    joinable = false;
    message = 'Cannot join. The maximum number of players has already been reached.';
  }

  const response: IsRoomJoinableResponseEvent = {
    queryEvt: evt,
    response: joinable,
    message: message,
  };
  api.dispatch(wsIsRoomJoinableRespond(response));
};

export const handleTutorialCreated = (api: MiddlewareAPI, evt: GameCreatedEvent) => {
  console.log('TUTORIAL CREATED');
  const state: RootState = api.getState();
  const newPlayer = CreateAssassinsPlayer(true, 0, true, 'You', state.app.playerID);
  const CPU1 = CreateCPU(ComputerLevel.Tutorial, 1, 'Intriguing Saboteur', 'tut-cpu-1');
  const CPU2 = CreateCPU(ComputerLevel.Tutorial, 2, 'Silent Rogue', 'tut-cpu-2');
  const CPU3 = CreateCPU(ComputerLevel.Tutorial, 3, 'Reckless Ambusher', 'tut-cpu-3');

  api.dispatch(addNewPlayerTutorial(newPlayer));
  api.dispatch(addNewPlayerTutorial(CPU1));
  api.dispatch(addNewPlayerTutorial(CPU2));
  api.dispatch(addNewPlayerTutorial(CPU3));
  api.dispatch(setLobbyID(evt.roomID));
  api.dispatch(setIsOwner(true));
  api.dispatch(setPlayersCanJoin(false));
  api.dispatch(setSelectEnabled(false));
  api.dispatch(setArmEnabled(false));
  api.dispatch(setFortifyEnabled(false));
  api.dispatch(setReloadEnabled(false));
  api.dispatch(setRevealEnabled(false));
  api.dispatch(setExposeEnabled(false));
  api.dispatch(setPurloinEnabled(false));

  // This is hacky, TODO: Use thunks for stuff like this
  setTimeout(() => {
    api.dispatch(startTutorial());
    api.dispatch(setTutorialStep(0));
    setTimeout(() => {
      api.dispatch(setActivePage(Page.Table));
      api.dispatch(setTutorialActive(true));
      RemoveClassSafe(document.getElementById('start-tutorial-button'), 'wait');
    }, 1000);
  }, 1000);
};
