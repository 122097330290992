import { faGithub, faPatreon } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialogue, setMusicVolume, setSFXVolume } from '../../State/Slices/appSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, ZIndices } from '../../types';

interface Props {}

const SettingsDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const musicVolume = useAppSelector((state) => state.app.musicVolume);
  const sfxVolume = useAppSelector((state) => state.app.sfxVolume);
  const activeDialogue = useSelector((state: RootState) => state.app.activeDialogue);
  const volumeMin = 0;
  const volumeMax = 0.9;

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (activeDialogue === Dialogue.Settings) {
      setShown(true);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
      }, fadeOutDuration);
    }
  }, [activeDialogue]);

  const closeOnClick = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
  };

  return (
    <div
      id="settings-dialogue"
      className={`dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${shown ? '' : 'nodisplay'}`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Dialogues}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="dialogue-section">
            <div className="title">Settings</div>
          </div>
          <div className="dialogue-section">
            <div className="settings-group">
              <div className="setting">
                <label htmlFor="music-volume-input">Music Volume</label>
                <input
                  name="music-volume-input"
                  type="range"
                  min={volumeMin}
                  max={volumeMax}
                  step={0.05}
                  value={musicVolume}
                  onChange={(e) => dispatch(setMusicVolume(parseFloat(e.target.value)))}
                ></input>
                <div className="tracker-bg" style={{ width: `${(musicVolume / volumeMax) * 100}%` }}></div>
              </div>
              <div className="setting">
                <label htmlFor="sfx-volume-input">SFX Volume</label>
                <input
                  name="sfx-volume-input"
                  type="range"
                  min={volumeMin}
                  max={volumeMax}
                  step={0.05}
                  value={sfxVolume}
                  onChange={(e) => dispatch(setSFXVolume(parseFloat(e.target.value)))}
                ></input>
                <div className="tracker-bg" style={{ width: `${(sfxVolume / volumeMax) * 100}%` }}></div>
              </div>
            </div>
          </div>
          <div className="dialogue-section">
            <div className="title">Credits</div>
          </div>
          <div className="dialogue-section">
            <div className="credits">
              <div>
                Author: Era{' '}
                <a href="https://github.com/era-epoch" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faGithub as IconDefinition} />
                </a>{' '}
                <a href="https://www.patreon.com/eracodes/about" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faPatreon as IconDefinition} />
                </a>
              </div>
              <div>
                SFX:{' '}
                <a target="_blank" href="https://opengameart.org" rel="noreferrer">
                  OpenGameArt
                </a>
              </div>
              <div>
                Music:{' '}
                <a href="https://www.fesliyanstudios.com/" rel="noreferrer" target="_blank">
                  Fesliyan Studios
                </a>
              </div>
              <div>
                Icons:{' '}
                <a href="https://fontawesome.com/" rel="noreferrer" target="_blank">
                  FontAwesome
                </a>
              </div>
              <div>Special Thanks To: Alice & Jasper</div>
            </div>
          </div>
          <div className="dialogue-section">
            <div className="dialogue-controls">
              <button className="popout-button rounded-square" onClick={closeOnClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsDialogue;
