import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../State/rootReducer';
import { AnimationStates, ZIndices } from '../../types';
import './HeroAnimation.scss';

const RoundEndGraphic = (): JSX.Element => {
  const dispatch = useDispatch();
  const display = useSelector((state: RootState) => state.assassins.roundEndFlag);
  const gameOver = useSelector((state: RootState) => state.assassins.gameEndFlag);
  const winnerName = useSelector((state: RootState) => {
    for (const player of state.assassins.players) {
      if (player.won) {
        return player.name;
      }
    }
    return '';
  });
  const persistWinnerName = useRef('');
  if (winnerName !== '') persistWinnerName.current = winnerName;
  const awaiting = useRef(true);
  const [animState, setAnimState] = useState(AnimationStates.Asleep);
  const wakeDelay = 1000;
  const wakeDuration = 650;
  const idleDuration = 1000;
  const snoozeDuration = 650;

  useEffect(() => {
    if (!display) {
      awaiting.current = true;
    }
  }, [display]);

  const playAnim = () => {
    // These have to be wrapped in timeouts because React won't properly update the state during a render call
    setTimeout(() => {
      setAnimState(AnimationStates.Waking);
    }, 0);
    setTimeout(() => {
      setAnimState(AnimationStates.Idle);
    }, wakeDuration + wakeDelay);
    setTimeout(
      () => {
        setAnimState(AnimationStates.Snoozing);
      },
      wakeDuration + wakeDelay + idleDuration,
    );
    setTimeout(
      () => {
        setAnimState(AnimationStates.Asleep);
      },
      wakeDuration + wakeDelay + idleDuration + snoozeDuration,
    );
  };

  if (display && awaiting.current && !gameOver) {
    playAnim();
    awaiting.current = false;
  }

  return (
    <div
      className={`${display ? '' : 'nodisplay'} nomouse hero-anim-container ${animState}`}
      style={
        {
          zIndex: `${ZIndices.GameGraphics}`,
          '--wake-delay': `${wakeDelay}ms`,
          '--wake-duration': `${wakeDuration}ms`,
          '--idle-duration': `${idleDuration}ms`,
          '--snooze-duration': `${snoozeDuration}ms`,
        } as React.CSSProperties
      }
    >
      <div className="relative">
        <div className={`hero-anim ${animState}`}>
          <div className="anim-layer" style={{ zIndex: 1 }}>
            <div className={`box ${animState}`}></div>
          </div>
          <div className="anim-layer" style={{ zIndex: 2 }}>
            <div className={`text ${animState}`}>{persistWinnerName.current} wins the round.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundEndGraphic;
