import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../State/rootReducer';
import { AnimationStates, ZIndices } from '../../types';
import './HeroAnimation.scss';

export const GameStartGraphic = (): JSX.Element => {
  const dispatch = useDispatch();
  const display = useSelector((state: RootState) => {
    return state.assassins.round === 0 && state.assassins.gameActive;
  });
  const awaiting = useRef(true);
  const [animState, setAnimState] = useState(AnimationStates.Asleep);
  // TODO: use these to set the CSS animation times automatically
  const wakeDelay = 0;
  const wakeDuration = 650;
  const idleDuration = 1000;
  const snoozeDuration = 650;

  useEffect(() => {
    if (!display) {
      awaiting.current = true;
    }
  }, [display]);

  const playAnim = () => {
    // These have to be wrapped in timeouts because React won't properly update the state during a render call
    setTimeout(() => {
      setAnimState(AnimationStates.Waking);
    }, 0);
    setTimeout(() => {
      setAnimState(AnimationStates.Idle);
    }, wakeDuration);
    setTimeout(() => {
      setAnimState(AnimationStates.Snoozing);
    }, wakeDuration + idleDuration);
    setTimeout(
      () => {
        setAnimState(AnimationStates.Asleep);
      },
      wakeDuration + idleDuration + snoozeDuration,
    );
  };

  if (display && awaiting.current) {
    playAnim();
    awaiting.current = false;
  }

  return (
    <div
      className={`${display ? '' : 'nodisplay'} nomouse hero-anim-container ${animState}`}
      style={
        {
          zIndex: `${ZIndices.GameGraphics}`,
          '--wake-delay': `${wakeDelay}ms`,
          '--wake-duration': `${wakeDuration}ms`,
          '--idle-duration': `${idleDuration}ms`,
          '--snooze-duration': `${snoozeDuration}ms`,
        } as React.CSSProperties
      }
    >
      <div className="relative">
        <div className={`hero-anim ${animState}`}>
          <div className="anim-layer" style={{ zIndex: 1 }}>
            <div className={`screen ${animState}`}></div>
          </div>
          <div className="anim-layer" style={{ zIndex: 2, fontSize: '200%' }}>
            <div className={`text ${animState}`}>Assassins</div>
          </div>
        </div>
      </div>
    </div>
  );
};
