import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearContext, pushAlert } from '../../State/Slices/appSlice';
import { revealCard } from '../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { GetCanAct } from '../../State/stateHelpers';
import { AlertSeverity, Card, ContextType, ZIndices } from '../../types';
import { createAlert } from '../../utility';

const OwnCardContext = (): JSX.Element => {
  const dispatch = useDispatch();
  const activeContext = useSelector((state: RootState) => state.app.activeContext);
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const selectedCard = useSelector((state: RootState) => state.app.selectedCard);
  const revealActionEnabled = useAppSelector((state) => state.app.revealEnabled);
  const canAct = useSelector((state: RootState) =>
    GetCanAct(state.assassins, state.app, state.app.playerID, state.app.isOwner),
  );

  // Transition state
  const [shown, setShown] = useState(false);
  const transDuration = 100;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (activeContext.type === ContextType.OwnCard) {
      setShown(true);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setShown(false);
      fadingIn.current = false;
    }
  }, [activeContext]);

  return (
    <div
      className={`context-container ${fadingIn ? 'context-fade-in' : ''} ${shown ? '' : 'nodisplay'}`}
      style={
        {
          '--fade-duration': `${transDuration}ms`,
          zIndex: `${ZIndices.Contexts}`,
          top: `${activeContext.location.y}px`,
          left: `${activeContext.location.x}px`,
        } as React.CSSProperties
      }
    >
      <div
        className="context-option"
        onClick={() => {
          if (canAct) {
            if (!revealActionEnabled) {
              dispatch(pushAlert(createAlert(`You cannot do that right now.`, AlertSeverity.warning)));
            } else {
              dispatch(revealCard({ card: selectedCard as Card, playerId: playerId }));
            }
            dispatch(clearContext());
          } else {
            dispatch(pushAlert(createAlert(`Cannot act on another player's turn.`, AlertSeverity.error)));
            dispatch(clearContext());
          }
        }}
      >
        <p>Reveal</p>
      </div>
    </div>
  );
};

export default OwnCardContext;
