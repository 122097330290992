import { setActiveDialogue } from '../../State/Slices/appSlice';
import { useAppDispatch } from '../../State/hooks';
import { PlaySubtleClick } from '../../audio';
import { Dialogue } from '../../types';
import { AddClassSafe } from '../../utility';
import { wsCreateGame, wsCreateTutorial } from '../../websockets/actions';
import './Landing.scss';

const Landing = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const newGameOnClick = () => {
    PlaySubtleClick();
    dispatch(wsCreateGame());
  };

  const joinGameOnClick = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.JoinGame));
  };

  const playTutorial = async () => {
    PlaySubtleClick();
    AddClassSafe(document.getElementById('start-tutorial-button'), 'wait');
    dispatch(wsCreateTutorial());
  };

  return (
    <div className="Landing">
      <div className="game-title">Assassins</div>
      <div className="menu-op" onClick={playTutorial} id="start-tutorial-button">
        <div className="op-text">Tutorial</div>
        <div className="op-bg-wrapper">
          <div className="op-suit">&spades;</div>
          <div className="op-line"></div>
          <div className="op-suit">&spades;</div>
        </div>
      </div>
      <div className="menu-op" onClick={newGameOnClick}>
        <div className="op-text">Create Game</div>
        <div className="op-bg-wrapper">
          <div className="op-suit">&spades;</div>
          <div className="op-line"></div>
          <div className="op-suit">&spades;</div>
        </div>
      </div>
      <div className="menu-op" onClick={joinGameOnClick}>
        <div className="op-text">Join Game</div>
        <div className="op-bg-wrapper">
          <div className="op-suit">&clubs;</div>
          <div className="op-line"></div>
          <div className="op-suit">&clubs;</div>
        </div>
      </div>
      <div className="nodisplay favicon-gen">
        <div className="rel">
          <div className="spade">&spades;</div>
          <div className="A">A</div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
