import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { v4 as uuid } from 'uuid';
import { clearTooltip } from './State/Slices/appSlice';
import { AssassinsPlayer } from './State/Slices/assassinsSlice';
import {
  Alert,
  AlertSeverity,
  Card,
  CardType,
  Colour,
  ComputerLevel,
  ComputerPlayer,
  RANKS,
  Rank,
  SUITS,
  Suit,
  TooltipDirection,
  TooltipState,
} from './types';

export const AddClassSafe = (el: HTMLElement | null, className: string) => {
  if (el === null) {
    console.error('AddClassSafe: Element not found');
    return;
  }
  if (!el.classList.contains(className)) {
    el.classList.add(className);
  }
};

export const RemoveClassSafe = (el: HTMLElement | null, className: string) => {
  if (el === null) {
    console.error('RemoveClassSafe: Element not found');
    return;
  }
  if (el.classList.contains(className)) {
    el.classList.remove(className);
  }
};

export const createAlert = (content: string, severity?: AlertSeverity): Alert => {
  const alert: Alert = {
    id: uuid(),
    content: content,
    severity: severity ? severity : AlertSeverity.info,
    alive: true,
  };
  return alert;
};

export const CreateStandardDeck = (): Card[] => {
  const cards: Card[] = [];
  for (const suit of SUITS) {
    for (const rank of RANKS) {
      const card = {
        suit: suit,
        rank: rank,
        flipped: false,
        id: uuid(),
      };
      cards.push(card);
    }
  }
  return Shuffle(cards);
};

export const SplitDeckByCardType = (deck: Card[]): { faceCards: Card[]; numberCards: Card[] } => {
  const faces: Card[] = [];
  const nums: Card[] = [];
  for (const card of deck) {
    if (GetCardType(card) === CardType.Face) {
      faces.push(card);
    } else {
      nums.push(card);
    }
  }
  return {
    faceCards: faces,
    numberCards: nums,
  };
};

export const DrawRandomCardFromDeck = (deck: Card[]): Card => {
  const index = Math.floor(Math.random() * (deck.length - 1));
  const card = deck[index];
  deck.splice(index, 1);
  return card;
};

export const DrawTopCardFromDeck = (deck: Card[]): Card => {
  const index = 0;
  const card = deck[index];
  deck.splice(index, 1);
  return card;
};

export const GetCardColour = (card: Card): Colour => {
  if (card.suit === Suit.Diamonds || card.suit === Suit.Hearts) {
    return Colour.red;
  } else {
    return Colour.black;
  }
};

export const GetCardType = (card: Card): CardType => {
  if (card.rank === Rank.Jack || card.rank === Rank.Queen || card.rank === Rank.King) {
    return CardType.Face;
  } else {
    return CardType.Numeral;
  }
};

export const GetCardSuit = (card: Card): string => {
  switch (card.suit) {
    case Suit.Clubs:
      return '&clubs;';
    case Suit.Diamonds:
      return '&diams;';
    case Suit.Hearts:
      return '&hearts;';
    case Suit.Spades:
      return '&spades;';
    default:
      return '?';
  }
};

export const GetCardCharacter = (card: Card): string => {
  switch (card.rank) {
    case Rank.Ace:
      return 'A';
    case Rank.Two:
      return '2';
    case Rank.Three:
      return '3';
    case Rank.Four:
      return '4';
    case Rank.Five:
      return '5';
    case Rank.Six:
      return '6';
    case Rank.Seven:
      return '7';
    case Rank.Eight:
      return '8';
    case Rank.Nine:
      return '9';
    case Rank.Ten:
      return '10';
    case Rank.Jack:
      return 'J';
    case Rank.Queen:
      return 'Q';
    case Rank.King:
      return 'K';
    default:
      return '?';
  }
};

export const GetCardString = (card: Card): string => {
  return `${GetCardCharacter(card)}${GetCardSuit(card)}`;
};

export const CreateTooltip = (text: string, target: string, direction?: TooltipDirection): TooltipState => {
  const tooltip: TooltipState = {
    active: true,
    text: text,
    targetID: target,
    direction: direction === undefined ? TooltipDirection.above : direction,
  };
  return tooltip;
};

export const CreateJSXTooltip = (
  renderer: () => JSX.Element,
  target: string,
  direction?: TooltipDirection,
): TooltipState => {
  const tooltip: TooltipState = {
    active: true,
    renderer: renderer,
    targetID: target,
    direction: direction === undefined ? TooltipDirection.above : direction,
  };
  return tooltip;
};

export const DefaultMouseLeave = (dispatch: Dispatch<AnyAction>) => {
  dispatch(clearTooltip());
};

export const NAME_NOUNS = [
  'Killer',
  'Sniper',
  'Shadow',
  'Mercenary',
  'Ambusher',
  'Saboteur',
  'Agent',
  'Operative',
  'Rogue',
  'Spy',
  'Poisoner',
  'Executioner',
];

export const NAME_ADJS = [
  'Stealthy',
  'Venomous',
  'Covert',
  'Silent',
  'Deceptive',
  'Clandestine',
  'Undercover',
  'Classified',
  'Intriguing',
  'Hidden',
  'Elusive',
  'Unseen',
  'Shadowy',
  'Reckless',
  'Cautious',
];

export const GetName = (): string => {
  const i = Math.floor(Math.random() * NAME_NOUNS.length);
  const j = Math.floor(Math.random() * NAME_ADJS.length);
  return `${NAME_ADJS[j]} ${NAME_NOUNS[i]}`;
};

export const CreateCPU = (level: ComputerLevel, ordinal: number, name?: string, id?: string): ComputerPlayer => {
  const player: Partial<ComputerPlayer> = CreateAssassinsPlayer(false, ordinal, undefined, name, id);
  player.level = level;
  return player as ComputerPlayer;
};

export const CreateAssassinsPlayer = (
  sentient: boolean,
  ordinal: number,
  isHost?: boolean,
  name?: string,
  id?: string,
): AssassinsPlayer => {
  return {
    id: id !== undefined ? id : sentient ? uuid() : 'zzzzz' + uuid(), // Hacky solution to always have computers appear after humans when sorted by ID,
    name: name !== undefined ? name : GetName(),
    played: [],
    hand: [],
    ordinal: ordinal,
    alive: true,
    won: false,
    winCount: 0,
    isHost: isHost !== undefined ? isHost : false,
    sentient: sentient,
    actions: [],
    canPurloin: true,
  };
};

export const Shuffle = (array: any[]) => {
  return array.sort(() => Math.random() - 0.5);
};
