import cloneDeep from 'lodash.clonedeep';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  clearTooltip,
  createNewContext,
  pushAlert,
  setActionLockout,
  setSelectedCard,
  setSelectedCardCSS,
  setTooltipState,
} from '../../../State/Slices/appSlice';
import { AssassinsPlayer, playCard } from '../../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../../State/hooks';
import { RootState } from '../../../State/rootReducer';
import { GetCanAct, GetPlayerByID } from '../../../State/stateHelpers';
import { AlertSeverity, Card, CardFreshness, CardSpaceRenderData, ContextType, SideEffect } from '../../../types';
import { CreateTooltip, DefaultMouseLeave, GetCardCharacter, GetCardSuit, createAlert } from '../../../utility';
import CardView from '../../Card/CardView';

export const PlayerArsenal = (): JSX.Element => {
  const dispatch = useDispatch();

  // Game State
  const playerId = useSelector((state: RootState) => state.app.playerID);
  const selectedCard = useSelector((state: RootState) => state.app.selectedCard);
  const player = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, state.app.playerID);
  }) as AssassinsPlayer;
  const winMessage = useSelector((state: RootState) => state.assassins.winMessage);
  const canAct = useSelector((state: RootState) =>
    GetCanAct(state.assassins, state.app, state.app.playerID, state.app.isOwner),
  );
  const sideEffects = useSelector((state: RootState) => state.assassins.currSideEffects);
  const mustReveal = canAct && sideEffects.includes(SideEffect.ExposeUnder);
  const armActionEnabled = useAppSelector((state) => state.app.armEnabled);
  const selectActionEnabled = useAppSelector((state) => state.app.revealEnabled);

  // Animation State
  const playedCardOutAnimationDuration = useAppSelector((state) => state.app.playedCardOutAnimationDuration);
  const playedCardInAnimationDuration = useAppSelector((state) => state.app.playedCardInAnimationDuration);

  // Rendering Data
  const staleCardIds = useRef<string[]>([]);

  let orderedCards = cloneDeep(player.played).sort((a, b) => a.rank - b.rank);

  // Create card "spaces" for each card with alternating blank spaces for potentially playing new cards
  const cardSpaces: CardSpaceRenderData[] = [];
  cardSpaces.push({ content: null, freshness: CardFreshness.None });
  for (const card of orderedCards) {
    let freshness = CardFreshness.Stale;
    if (!staleCardIds.current.includes(card.id)) {
      freshness = CardFreshness.Fresh;
      setTimeout(() => {
        staleCardIds.current.push(card.id);
      }, playedCardInAnimationDuration);
    }
    cardSpaces.push({
      content: card,
      // Check which cards are "fresh" to do intro animations on
      freshness: freshness,
    });
    cardSpaces.push({ content: null, freshness: CardFreshness.None });
  }

  let playabilities: boolean[] = [];
  if (selectedCard !== null && player.hand.includes(selectedCard)) {
    let last_rank = 0;
    if (orderedCards.length === 0) {
      playabilities.push(true);
    } else {
      for (let i = 0; i < orderedCards.length; i++) {
        let curr_rank = orderedCards[i]!.rank;
        if (selectedCard.rank >= last_rank && selectedCard.rank <= curr_rank) {
          playabilities.push(true);
        } else {
          playabilities.push(false);
        }
        last_rank = curr_rank;
      }
      if (selectedCard.rank >= last_rank) {
        playabilities.push(true);
      } else {
        playabilities.push(false);
      }
    }
  }
  let playability_index = 0;

  const playedSpaceHover = (htmlid: string) => {
    if (selectedCard !== null) {
      dispatch(setTooltipState(CreateTooltip('Arm selected card', htmlid)));
    } else {
      dispatch(setTooltipState(CreateTooltip('No card selected', htmlid)));
    }
  };

  const playedCardHover = (htmlid: string, card: Card) => {
    dispatch(setTooltipState(CreateTooltip(`${GetCardCharacter(card)}(${GetCardSuit(card)})`, htmlid)));
  };

  const playSelectedCard = (index: number) => {
    if (!armActionEnabled) {
      dispatch(pushAlert(createAlert(`You cannot do that right now.`, AlertSeverity.warning)));
      return;
    }
    if (!canAct) {
      dispatch(pushAlert(createAlert(`Cannot act on another player's turn.`, AlertSeverity.error)));
      return;
    }
    if (selectedCard === null) {
      dispatch(pushAlert(createAlert('You must select a card first.', AlertSeverity.info)));
      return;
    }
    if (mustReveal) {
      dispatch(pushAlert(createAlert('You must reveal an armed card.', AlertSeverity.info)));
      return;
    }
    dispatch(setSelectedCardCSS('being-played'));
    dispatch(setActionLockout(true));
    setTimeout(() => {
      dispatch(setActionLockout(false));
      dispatch(playCard({ card: selectedCard, playerId: playerId, index: index }));
      dispatch(clearTooltip());
      dispatch(setSelectedCardCSS(''));
    }, playedCardOutAnimationDuration);
  };

  const playedCardClick = (card: Card, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!selectActionEnabled) {
      dispatch(pushAlert(createAlert('You cannot do that right now.', AlertSeverity.warning)));
    } else if (card.flipped) {
      dispatch(createNewContext({ type: ContextType.OwnCard, location: { x: e.clientX, y: e.clientY } }));
      dispatch(setSelectedCard(card));
    } else {
      dispatch(pushAlert(createAlert('That card has already been revealed.', AlertSeverity.warning)));
    }
    dispatch(clearTooltip());
  };

  return (
    <div className="active-cards">
      <div className="card-zone" id="arsenal">
        <div className={`death-overlay ${player.alive ? 'nodisplay' : ''}`}>
          <div className="death-message">Dead</div>
        </div>
        <div className={`win-overlay ${!player.won ? 'nodisplay' : ''}`}>
          <div className="win-message">{winMessage}</div>
        </div>
        {cardSpaces.map((cardSpace: CardSpaceRenderData, i) => {
          const htmlid = uuid();
          if (cardSpace.content === null) {
            let locationClass = '';
            let playable = playabilities[playability_index];
            if (playable) {
              locationClass = 'can-play';
            } else if (cardSpaces.length > 1) {
              locationClass = 'nodisplay';
            }
            playability_index++;
            return (
              <div
                className={`card-location ${locationClass}`}
                id={htmlid}
                key={i}
                onClick={
                  playable
                    ? () => playSelectedCard(i)
                    : () =>
                        dispatch(
                          pushAlert(
                            createAlert(
                              'Cards must be played in order of increasing rank from left to right.',
                              AlertSeverity.error,
                            ),
                          ),
                        )
                }
                onMouseEnter={() => playedSpaceHover(htmlid)}
                onMouseLeave={() => DefaultMouseLeave(dispatch)}
              >
                <div className="card-outline"></div>
              </div>
            );
          } else {
            const card = cardSpace.content as Card;
            return (
              <div
                className={`card-location ${cardSpace.freshness}`}
                id={htmlid}
                key={card.id}
                onClick={(e) => playedCardClick(card, e)}
                onMouseEnter={() => playedCardHover(htmlid, card)}
                onMouseLeave={() => DefaultMouseLeave(dispatch)}
              >
                <div className="card-outline"></div>
                <div className="card-container" style={{ fontSize: `0.25rem` }}>
                  <CardView card={card} flipped={card.flipped} />
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
