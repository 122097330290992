import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialogue, setActivePage } from '../../State/Slices/appSlice';
import { goBackToLobby, playAgain } from '../../State/Slices/assassinsSlice';
import { RootState } from '../../State/rootReducer';
import { GetGameWinner } from '../../State/stateHelpers';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, Page, ZIndices } from '../../types';

interface Props {}

const GameEndDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const activeDialogue = useSelector((state: RootState) => state.app.activeDialogue);
  const isOwner = useSelector((state: RootState) => state.app.isOwner);
  const gameWinner = useSelector((state: RootState) => GetGameWinner(state.assassins));

  const winnerNameRef = useRef('');
  if (gameWinner !== null) {
    winnerNameRef.current = gameWinner.name;
  }

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;
  const inDelay = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  useEffect(() => {
    if (activeDialogue === Dialogue.GameEnd) {
      setTimeout(() => {
        setShown(true);
      }, inDelay);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
      }, fadeOutDuration);
    }
  }, [activeDialogue]);

  const playAgainClicked = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
    dispatch(playAgain());
  };

  const backToLobbyClicked = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
    dispatch(goBackToLobby());
    dispatch(setActivePage(Page.Lobby));
  };

  return (
    <div
      id="end-round-dialogue"
      className={`dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${shown ? '' : 'nodisplay'}`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Dialogues}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="dialogue-section">Game Over</div>
          <div className="dialogue-section">{winnerNameRef.current} won the game.</div>
          <div className="dialogue-section">
            <div className="dialogue-controls">
              {isOwner ? (
                <button className="popout-button rounded-square" onClick={playAgainClicked}>
                  Play Again
                </button>
              ) : (
                <div>Waiting for the host</div>
              )}
              {isOwner ? (
                <button className="popout-button rounded-square" onClick={backToLobbyClicked}>
                  Back to Lobby
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameEndDialogue;
