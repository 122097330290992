import { useDispatch, useSelector } from 'react-redux';
import { clearTooltip, pushAlert, setSelectedCard } from '../../../State/Slices/appSlice';
import { AssassinsPlayer } from '../../../State/Slices/assassinsSlice';
import { useAppSelector } from '../../../State/hooks';
import { RootState } from '../../../State/rootReducer';
import { GetPlayerByID } from '../../../State/stateHelpers';
import { AlertSeverity, Card } from '../../../types';
import { DefaultMouseLeave, createAlert } from '../../../utility';
import CardView from '../../Card/CardView';

export const PlayerCache = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectedCard = useSelector((state: RootState) => state.app.selectedCard);
  const player = useSelector((state: RootState) => {
    return GetPlayerByID(state.assassins, state.app.playerID);
  }) as AssassinsPlayer;
  const selectActionEnabled = useAppSelector((state) => state.app.selectEnabled);

  const cardWidth = 200;
  const cardCount = player.hand.length;

  // Animation State
  const selectedCardCSS = useAppSelector((state) => state.app.selectedCardCSS);

  const cardClicked = (card: Card) => {
    if (selectedCard !== null && selectedCard.id === card.id) {
      dispatch(setSelectedCard(null));
    } else {
      if (!selectActionEnabled) {
        dispatch(pushAlert(createAlert('You cannot do that right now.', AlertSeverity.warning)));
      } else {
        dispatch(setSelectedCard(card));
      }
    }
    dispatch(clearTooltip());
  };
  return (
    <div className="hand">
      {player.hand.map((card: Card, i) => {
        // Calculate card position
        let padding = 20;
        let halfWidth = Math.floor(cardWidth / 2);
        let xOff = -halfWidth;
        let half = Math.floor(cardCount / 2);
        if (cardCount % 2 === 0) {
          if (i < half) {
            xOff -= halfWidth + padding / 2;
            let diff = half - 1 - i;
            xOff -= (cardWidth + padding) * diff;
          } else {
            xOff += halfWidth + padding / 2;
            let diff = i - half;
            xOff += (cardWidth + padding) * diff;
          }
        } else {
          if (i < Math.floor(cardCount / 2)) {
            let diff = Math.floor(cardCount / 2) - i;
            xOff -= (cardWidth + padding) * diff;
          } else if (i >= Math.ceil(cardCount / 2)) {
            let diff = i - Math.floor(cardCount / 2);
            xOff += (cardWidth + padding) * diff;
          }
        }
        let cardCSS = '';
        if (selectedCard !== null && selectedCard.id === card.id) {
          cardCSS += 'selected-card ' + selectedCardCSS;
        }
        const htmlid = 'player-cache-' + i;
        return (
          <div
            className={`card-container ${cardCSS}`}
            id={htmlid}
            key={card.id}
            style={{ fontSize: `0.65rem`, left: `calc(50% + ${xOff}px)` }}
            onMouseLeave={() => DefaultMouseLeave(dispatch)}
          >
            <CardView card={card} clickCallback={() => cardClicked(card)} />
          </div>
        );
      })}
    </div>
  );
};
