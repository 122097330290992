import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { Socket, io } from 'socket.io-client';
import { resetUpdateFlag } from '../State/Slices/assassinsSlice';
import { IS_PROD } from '../client_env';
import {
  DisconnectEvent,
  FetchRoomEvent,
  FetchRoomResultEvent,
  GameCreatedEvent,
  GameJoinedEvent,
  IsRoomJoinableQueryEvent,
  IsRoomJoinableResponseEvent,
  JoinGameEvent,
  KickEvent,
  NewPlayerJoinedEvent,
  PingEvent,
  PlayerDisconnectedEvent,
  RegisterPlayerEvent,
  SIO,
  SyncPlayerEvent,
  SyncStateEvent,
} from './events';
import {
  handleFetchRoomResult,
  handleGameCreated,
  handleIsRoomJoinableQuery,
  handleJoinGameFailed,
  handleJoinedGame,
  handleKicked,
  handleNewPlayerJoined,
  handlePing,
  handlePlayerDisconnect,
  handleSyncPlayer,
  handleSyncState,
  handleTutorialCreated,
  handleWSConnected,
} from './handlers';

const socketMiddleware: Middleware = (api: MiddlewareAPI) => {
  let socket: Socket | null = null;

  const connect = (action: any) => {
    if (socket !== null) {
      socket.close();
    }
    // Set up socket
    if (IS_PROD) {
      socket = io();
    } else {
      socket = io('localhost:5000');
    }
    socket.connect();

    // Handle Events
    socket.on(SIO.Connected, () => handleWSConnected(api));
    socket.on(SIO.GameCreated, (evt: GameCreatedEvent) => handleGameCreated(api, evt));
    socket.on(SIO.GameJoinFailed, () => handleJoinGameFailed(api));
    socket.on(SIO.GameJoined, (evt: GameJoinedEvent) => handleJoinedGame(api, evt));
    socket.on(SIO.NewPlayerJoined, (evt: NewPlayerJoinedEvent) => handleNewPlayerJoined(api, evt));
    socket.on(SIO.SyncState, (evt: SyncStateEvent) => handleSyncState(api, evt));
    socket.on(SIO.SyncPlayer, (evt: SyncPlayerEvent) => handleSyncPlayer(api, evt));
    socket.on(SIO.PlayerDisconnected, (evt: PlayerDisconnectedEvent) => handlePlayerDisconnect(api, evt));
    socket.on(SIO.FetchRoomResult, (evt: FetchRoomResultEvent) => handleFetchRoomResult(api, evt));
    socket.on(SIO.Ping, (evt: PingEvent) => handlePing(api, evt));
    socket.on(SIO.Kicked, () => handleKicked(api));
    socket.on(SIO.IsRoomJoinableQuery, (evt: IsRoomJoinableQueryEvent) => handleIsRoomJoinableQuery(api, evt));
    socket.on(SIO.TutorialCreated, (evt: GameCreatedEvent) => handleTutorialCreated(api, evt));
  };

  return (next: Dispatch<AnyAction>) => (action: any) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket === null) connect(action);
        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.emit(SIO.Disconnect, action as DisconnectEvent);
          socket.close();
        }
        socket = null;
        break;
      case 'WS_CREATE_GAME':
        if (socket !== null) socket.emit(SIO.CreateGame);
        break;
      case 'WS_JOIN_GAME':
        if (socket !== null) socket.emit(SIO.JoinGame, action as JoinGameEvent);
        break;
      case 'WS_SYNC_STATE':
        console.log('Syncing State');
        if (socket !== null) socket.emit(SIO.SyncState, action as SyncStateEvent);
        api.dispatch(resetUpdateFlag());
        break;
      case 'WS_SYNC_PLAYER':
        if (socket !== null) socket.emit(SIO.SyncPlayer, action as SyncPlayerEvent);
        break;
      case 'WS_FETCH_ROOM':
        if (socket !== null) socket.emit(SIO.FetchRoom, action as FetchRoomEvent);
        break;
      case 'WS_PING':
        if (socket !== null) socket.emit(SIO.Ping, action as PingEvent);
        break;
      case 'WS_KICK':
        if (socket !== null) socket.emit(SIO.Kick, action as KickEvent);
        break;
      case 'WS_REGISTER_PLAYER':
        if (socket !== null) socket.emit(SIO.RegisterPlayer, action as RegisterPlayerEvent);
        break;
      case 'WS_IS_ROOM_JOINABLE_RESPOND':
        if (socket !== null) socket.emit(SIO.IsRoomJoinableResponse, action.evt as IsRoomJoinableResponseEvent);
        break;
      case 'WS_CREATE_TUTORIAL':
        if (socket !== null) socket.emit(SIO.CreateTutorial);
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware;
