import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDialogue, setActivePage } from '../../State/Slices/appSlice';
import { resetAssassinsState, startNewRound } from '../../State/Slices/assassinsSlice';
import { setTutorialActive } from '../../State/Slices/tutorialSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { PlaySubtleClick } from '../../audio';
import { Dialogue, Page, ZIndices } from '../../types';

interface Props {}

const EndRoundDialogue = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const activeDialogue = useSelector((state: RootState) => state.app.activeDialogue);
  const isOwner = useSelector((state: RootState) => state.app.isOwner);
  const endRoundFlag = useSelector((state: RootState) => state.assassins.roundEndFlag);
  const gameEndFlag = useSelector((state: RootState) => state.assassins.gameEndFlag);
  const tutorialActive = useAppSelector((state) => state.tutorial.tutorialActive);
  const winnerName = useSelector((state: RootState) => {
    for (const player of state.assassins.players) {
      if (player.won) {
        return player.name;
      }
    }
    return '';
  });
  const persistWinnerName = useRef('');
  if (winnerName !== '') persistWinnerName.current = winnerName;

  // Transition state
  const [shown, setShown] = useState(false);
  const [hiding, setHiding] = useState(false);
  const fadeOutDuration = 1000;
  const inDelay = 1000;

  const fadingIn = useRef(false);
  const hasShown = useRef(false);
  if (shown && !fadingIn.current) {
    fadingIn.current = true;
  }

  // GAME DIALOGUE CONTROL
  // TODO: Central Dialogue controller
  useEffect(() => {
    if (endRoundFlag && !gameEndFlag) {
      dispatch(setActiveDialogue(Dialogue.EndRound));
    } else if (gameEndFlag) {
      dispatch(setActiveDialogue(Dialogue.GameEnd));
    } else {
      dispatch(setActiveDialogue(Dialogue.none));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endRoundFlag, gameEndFlag]);

  useEffect(() => {
    if (activeDialogue === Dialogue.EndRound) {
      setTimeout(() => {
        setShown(true);
      }, inDelay);
      hasShown.current = true;
    } else if (!hasShown.current) {
      return;
    } else {
      setHiding(true);
      setTimeout(() => {
        setShown(false);
        setHiding(false);
        fadingIn.current = false;
      }, fadeOutDuration);
    }
  }, [activeDialogue]);

  const playAgainClicked = () => {
    PlaySubtleClick();
    dispatch(startNewRound());
    dispatch(setActiveDialogue(Dialogue.none));
  };

  const backToLobbyClicked = () => {
    PlaySubtleClick();
    dispatch(setActiveDialogue(Dialogue.none));
    dispatch(setActivePage(Page.Home));
    dispatch(setTutorialActive(false));
    setTimeout(() => {
      dispatch(resetAssassinsState());
    }, 2000);
  };

  return (
    <div
      id="end-round-dialogue"
      className={`dialogue ${fadingIn ? 'fade-in' : ''} ${hiding ? 'fade-out' : ''} ${shown ? '' : 'nodisplay'}`}
      style={{ '--fade-duration': `${fadeOutDuration}ms`, zIndex: `${ZIndices.Dialogues}` } as React.CSSProperties}
    >
      <div className="dialogue-internal">
        <div className="dialogue-content">
          <div className="dialogue-section">Round Over</div>
          <div className="dialogue-section">{persistWinnerName.current} won the round.</div>
          <div className="dialogue-section">
            <div className="dialogue-controls">
              {isOwner ? (
                tutorialActive ? (
                  <button className="popout-button rounded-square" onClick={backToLobbyClicked}>
                    Return to Menu
                  </button>
                ) : (
                  <button className="popout-button rounded-square" onClick={playAgainClicked}>
                    Next Round
                  </button>
                )
              ) : (
                <div>Waiting for the host to start the next round</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndRoundDialogue;
