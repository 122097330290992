import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMostRecentWindow, setShowConnectionsWindow } from '../../State/Slices/appSlice';
import { AssassinsPlayer } from '../../State/Slices/assassinsSlice';
import { RootState } from '../../State/rootReducer';
import { GetHost, GetUserData } from '../../State/stateHelpers';
import { AppWindow, ZIndices } from '../../types';
import './ConnectionsWindow.scss';

export const ConnectionsWindow = (): JSX.Element => {
  const dispatch = useDispatch();
  const shown = useSelector((state: RootState) => state.app.showConnectionsWindow);
  const mostRecentWindow = useSelector((state: RootState) => state.app.mostRecentWindow);
  const lobbyId = useSelector((state: RootState) => state.app.lobbyID);
  const userData = useSelector((state: RootState) => GetUserData(state));
  const host = useSelector((state: RootState) => GetHost(state.assassins)) as AssassinsPlayer;
  const [pressed, setPressed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onMouseMove = (e: React.MouseEvent) => {
    if (pressed) {
      setPosition({
        x: position.x + e.movementX,
        y: position.y + e.movementY,
      });
    }
  };

  const closeOnClick = () => {
    dispatch(setShowConnectionsWindow(false));
  };

  const zIndex = mostRecentWindow === AppWindow.ConnectionStatus ? ZIndices.Windows + 1 : ZIndices.Windows;

  return (
    <div
      className={`draggable-container connections-window-container ${shown ? '' : 'nodisplay'}`}
      onMouseMove={onMouseMove}
      onMouseDown={() => {
        setPressed(true);
        dispatch(setMostRecentWindow(AppWindow.ConnectionStatus));
      }}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
      style={{ translate: `${position.x}px ${position.y}px`, zIndex: `${zIndex}` }}
    >
      <div className="relative-parent">
        <div className="draggable-window connections-window">
          <div className="body">
            <div className="entry">
              <div className="field">Lobby Code:</div>
              <div className="values">
                {lobbyId === null ? (
                  <div>
                    Not connected to a game lobby. Click <b>Create Game</b> or <b>Join Game</b> to start.
                  </div>
                ) : (
                  <div>{lobbyId}</div>
                )}
              </div>
            </div>
            {lobbyId != null ? (
              <div className="entry">
                <div className="field">Connections:</div>
                <div className="values">
                  {userData.map((user, i) => {
                    return (
                      <div className="connection" key={i}>
                        <div className={`status-indicator ${user.connection?.connected ? 'success' : 'error'}`}>
                          <FontAwesomeIcon icon={faCircleUser} />
                        </div>
                        <div className="name">
                          {user.player?.name}
                          {user.connection?.id === host.id ? ' (Host)' : ''}
                        </div>
                        <div className="latency">
                          {user.connection?.latency !== null ? ` ${user.connection?.latency}ms` : ` ??? ms`}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
