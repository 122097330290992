import { faArrowLeft, faCheck, faClock, faCopy, faLock, faPlay, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash.clonedeep';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { pushAlert, resetAppState, setActivePage, setTooltipState } from '../../State/Slices/appSlice';
import { addNewPlayer, resetAssassinsState, startGame } from '../../State/Slices/assassinsSlice';
import { RootState } from '../../State/rootReducer';
import { GetHost } from '../../State/stateHelpers';
import { PlaySubtleClick } from '../../audio';
import { ComputerLevel, ComputerPlayer, Page, TooltipDirection } from '../../types';
import {
  CreateJSXTooltip,
  CreateTooltip,
  DefaultMouseLeave,
  GetName,
  RemoveClassSafe,
  createAlert,
} from '../../utility';
import './Lobby.scss';
import { LobbyPlayerView } from './LobbyPlayerView';

const Lobby = (): JSX.Element => {
  const dispatch = useDispatch();
  const roomID = useSelector((state: RootState) => state.app.lobbyID) as string;
  const isOwner = useSelector((state: RootState) => state.app.isOwner);
  const players = useSelector((state: RootState) => {
    return cloneDeep(state.assassins.players).sort((a, b) => (a.id < b.id ? -1 : 1));
  });
  const host = useSelector((state: RootState) => GetHost(state.assassins));
  const maxPlayers = useSelector((state: RootState) => state.assassins.maxPlayers);

  // Shake the players container on new player
  let playerLengthRef = useRef(1);
  let [quakePlayers, setQuakePlayers] = useState(false);
  if (players.length > playerLengthRef.current) {
    playerLengthRef.current = players.length;
    setTimeout(() => {
      setQuakePlayers(true);
    }, 0);
    setTimeout(() => {
      let el = document.getElementById('lobby-players');
      if (el !== null) {
        RemoveClassSafe(el, 'quake');
      }
      setQuakePlayers(false);
    }, 500);
  }

  const startGameClicked = () => {
    PlaySubtleClick();
    dispatch(startGame());
    dispatch(setActivePage(Page.Table));
  };

  const returnOnClick = () => {
    PlaySubtleClick();
    dispatch(setActivePage(Page.Home));
    dispatch(resetAppState());
    dispatch(resetAssassinsState());
  };

  const copyCode = () => {
    PlaySubtleClick();
    navigator.clipboard.writeText(roomID);
    dispatch(
      setTooltipState(
        CreateJSXTooltip(
          () => {
            return (
              <div className="icon-tooltip">
                <div className="text">Copied to Clipboard</div>
                <div className="icon green">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </div>
            );
          },
          'copy-join-button',
          TooltipDirection.right,
        ),
      ),
    );
  };

  const addComputerPlayer = () => {
    PlaySubtleClick();
    if (players.length >= maxPlayers) {
      dispatch(pushAlert(createAlert('Maximum players reached.')));
      return;
    }
    const newPC: ComputerPlayer = {
      id: 'zzzzz' + uuid(), // Hacky solution to always have computers appear after humans when sorted by ID
      name: GetName(),
      played: [],
      hand: [],
      ordinal: players.length,
      alive: true,
      won: false,
      winCount: 0,
      isHost: false,
      sentient: false,
      actions: [],
      level: ComputerLevel.Standard,
      canPurloin: true,
    };
    dispatch(addNewPlayer(newPC));
  };

  return (
    <div className="lobby">
      <div className="lobby-title fancy">Lobby</div>
      <div className="lobby-code">
        <div>Join Code:</div>
        <div>{roomID}</div>
        <div
          id="copy-join-button"
          onMouseLeave={() => DefaultMouseLeave(dispatch)}
          onMouseEnter={() =>
            dispatch(setTooltipState(CreateTooltip(`Copy to Clipboard`, `copy-join-button`, TooltipDirection.right)))
          }
          onClick={copyCode}
        >
          <FontAwesomeIcon icon={faCopy} />
        </div>
      </div>
      <div id="lobby-players" className={`lobby-players ${quakePlayers ? 'quake' : ''}`}>
        {players.map((player, i) => {
          return <LobbyPlayerView player={player} key={i} />;
        })}
      </div>
      {isOwner ? (
        <div className="controls">
          <button className="popout-button rounded-square" onClick={returnOnClick}>
            <FontAwesomeIcon icon={faArrowLeft} />
            Return to Title
          </button>
          <button className="popout-button rounded-square" onClick={addComputerPlayer}>
            <FontAwesomeIcon icon={faRobot} />
            Add a Computer Player
          </button>
          {players.length > 1 ? (
            <button className="popout-button rounded-square" onClick={startGameClicked}>
              <FontAwesomeIcon icon={faPlay} />
              Play
            </button>
          ) : (
            <button className="popout-button rounded-square">
              <FontAwesomeIcon icon={faLock} />
              Need at least 2 players to start
            </button>
          )}
        </div>
      ) : (
        <div className="controls">
          <button className="popout-button rounded-square">
            <FontAwesomeIcon icon={faClock} />
            Waiting for {host?.name} to start the game
          </button>
        </div>
      )}
    </div>
  );
};

export default Lobby;
