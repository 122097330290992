import { faBullseye, faCrosshairs, faCrown, faHand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { setTooltipState } from '../../State/Slices/appSlice';
import { useAppSelector } from '../../State/hooks';
import { RootState } from '../../State/rootReducer';
import { GetCanAct, GetOpponents, GetPlayerByID, GetTargetVal, GetThreatVal } from '../../State/stateHelpers';
import { TooltipDirection } from '../../types';
import { CreateTooltip, DefaultMouseLeave } from '../../utility';
import ReferenceCard from '../ReferenceCard/ReferenceCard';
import ArmourView from './ArmourView';
import './AssassinsTable.scss';
import DeckView from './DeckView';
import HitPointView from './HitPointView';
import MarkView from './MarkView';
import OpponentView from './OpponentView';
import PlayerView from './PlayerView';

const AssassinsTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const turn = useSelector((state: RootState) => state.assassins.turn);
  const round = useSelector((state: RootState) => state.assassins.round);
  const roundsToWin = useSelector((state: RootState) => state.assassins.roundsToWin);
  const player = useSelector((state: RootState) => GetPlayerByID(state.assassins, state.app.playerID));
  const opponents = useSelector((state: RootState) => GetOpponents(state.assassins, state.app.playerID));
  const threat = useSelector((state: RootState) => GetThreatVal(state.assassins));
  const target = useSelector((state: RootState) => GetTargetVal(state.assassins));
  const activePlayer = useSelector((state: RootState) => state.assassins.activePlayer);
  const canAct = useSelector((state: RootState) =>
    GetCanAct(state.assassins, state.app, state.app.playerID, state.app.isOwner),
  );
  const tutorialActive = useAppSelector((state) => state.tutorial.tutorialActive);

  // Animation State
  const playedCardOutAnimationDuration = useAppSelector((state) => state.app.playedCardOutAnimationDuration);
  const playedCardInAnimationDuration = useAppSelector((state) => state.app.playedCardInAnimationDuration);
  const actionTextDuration = useAppSelector((state) => state.app.actionTextDuration);

  const targetTextHover = (htmlid: string) => {
    dispatch(
      setTooltipState(
        CreateTooltip(
          `
          <p>The <b>target value</b> equals the mark's <b>hit points</b> plus any <b>armour</b>.</p>
          `,
          htmlid,
          TooltipDirection.left,
        ),
      ),
    );
  };

  const threatTextHover = (htmlid: string) => {
    dispatch(
      setTooltipState(
        CreateTooltip(
          `
          <p>Reveal armed cards to increase the <b>threat value</b>.</p>
          <div class="tt-separator"></div>
          <p>If you reveal a card that causes the <b>threat value</b> to.</p>
          <p>equal the <b>target value</b>, you win.</p>
          <div class="tt-separator"></div>
          <p>If you reveal a card that causes the <b>threat value</b> to</p>
          <p>exceed the <b>target value</b>, you are eliminated from this round.</p>
          `,
          htmlid,
          TooltipDirection.left,
        ),
      ),
    );
  };

  const winCountTextHover = (htmlid: string) => {
    if (player == null) return;
    dispatch(
      setTooltipState(
        CreateTooltip(
          `You have won ${player.winCount} rounds. Win ${roundsToWin} rounds to win the game.`,
          htmlid,
          TooltipDirection.left,
        ),
      ),
    );
  };

  const purloinHover = (htmlid: string, canPurloin: boolean) => {
    dispatch(
      setTooltipState(
        CreateTooltip(
          canPurloin ? 'You have not yet purloined this round.' : 'You have already purloined this round.',
          htmlid,
          TooltipDirection.left,
        ),
      ),
    );
  };

  return (
    <div
      className="table assassins-table"
      style={
        {
          '--played-card-out-duration': `${playedCardOutAnimationDuration}ms`,
          '--played-card-in-duration': `${playedCardInAnimationDuration}ms`,
          '--action-text-duration': `${actionTextDuration}ms`,
        } as React.CSSProperties
      }
    >
      <ReferenceCard />
      <div className={`turn-counter ${tutorialActive ? 'nodisplay' : ''}`}>
        <div className="turn-title">{`Round ${round + 1},`}</div>
        <div className="turn-title">{`Turn ${turn + 1}`}</div>
        <div className="turn-name">{`${activePlayer?.name}${canAct ? ' (You)' : ''}`}</div>
      </div>
      <div className="opponents-area">
        {opponents.map((opp, i) => {
          return <OpponentView key={i} opponent={opp} index={i} />;
        })}
      </div>
      <div className="common-area">
        <DeckView />
        <MarkView />
        <HitPointView />
        <ArmourView />
        <div className="game-values" id="game-values">
          <div
            className="game-val target-val"
            id="target-val-text"
            onMouseEnter={() => targetTextHover('target-val-text')}
            onMouseLeave={() => DefaultMouseLeave(dispatch)}
          >
            <FontAwesomeIcon icon={faBullseye} />
            {target}
          </div>
          <div
            className="game-val threat-val"
            id="threat-val-text"
            onMouseEnter={() => threatTextHover('threat-val-text')}
            onMouseLeave={() => DefaultMouseLeave(dispatch)}
          >
            <FontAwesomeIcon icon={faCrosshairs} />
            {threat}
          </div>
          <div
            className={`game-val win-count-val ${tutorialActive ? 'nodisplay' : ''}`}
            id="win-count-val-text"
            onMouseEnter={() => winCountTextHover('win-count-val-text')}
            onMouseLeave={() => DefaultMouseLeave(dispatch)}
          >
            <FontAwesomeIcon icon={faCrown} />
            {player != null ? player.winCount : ''}
          </div>
          <div
            className={`game-val purloin-val ${tutorialActive ? 'nodisplay' : ''} purloin-${
              player != null ? player.canPurloin : ''
            }`}
            id="purloin-val-text"
            onMouseEnter={() => {
              if (player == null) return;
              purloinHover('purloin-val-text', player.canPurloin);
            }}
            onMouseLeave={() => DefaultMouseLeave(dispatch)}
          >
            <FontAwesomeIcon icon={faHand} />
          </div>
        </div>
      </div>
      <PlayerView />
    </div>
  );
};

export default AssassinsTable;
